/* CAJA CENTRAL BLANCA */
.lightCentralBox {
  padding: 24px;
  @include dFlexCenter;
}

/* CAJA CENTRAL GRIS */
.centralBox {
  @include dFlexCenter;
}

.d-flex-center {
  @include dFlexCenter;
}

.centralBoxColunm,
.centralBoxColunmAsignedArtist {
  @include dFlexColumnEvenlyCenter;
}

.widthAsignedArtist {
  width: 65px !important;
}

.f-wrap {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 413px) {
  .greenMediumButton.btnStartSesion {
    width: $width120px !important;
    font-size: 14px;
    margin-top: 0% !important;
  }

  .divStartSesion {
    justify-content: $spaceEvenly;
    display: flex;
    flex-direction: row;
  }

  .instructionsText,
  .formDataPassword {
    width: 77%;
  }
}

@media screen and (min-width: 414px) and (max-width: 575px) {
  .divStartSesion {
    justify-content: $spaceEvenly;
  }

  .greenMediumButton.btnStartSesion {
    width: $width125px !important;
    font-size: 14px;
    margin-top: 0% !important;
  }

  .instructionsText,
  .formDataPassword {
    width: 77%;
  }
}

@media screen and (min-width: 576px) {
  .greenMediumButton.btnStartSesion {
    width: $width125px !important;
    font-size: 14px;
    margin-top: 0% !important;
  }

  .divStartSesion {
    justify-content: $spaceEvenly;
  }

  .instructionsText,
  .formDataPassword {
    width: 65%;
  }
}

@media screen and (min-width: 768px) {
  .greenMediumButton.btnStartSesion {
    width: 145px !important;
    font-size: 16px;
    margin-top: 0% !important;
  }

  .divStartSesion {
    justify-content: $spaceEvenly;
  }

  .instructionsText {
    width: 60%;
  }

  .formDataPassword {
    width: 59%;
  }
}

@media screen and (min-width: 992px) {
  .instructionsText {
    width: 56%;
  }

  .formDataPassword {
    width: 52.5%;
  }
}

@media screen and (min-width: 1200px) {
  .instructionsText {
    width: 45%;
  }

  .formDataPassword {
    width: 49.4%;
  }
}

@media screen and (min-width: 1400px) {
  .formDataPassword {
    width: 47%;
  }
}

@media screen and (min-width: 1600px) {
  .formDataPassword {
    width: 45%;
  }
}

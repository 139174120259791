.dashboardBody {
  border-radius: 12px;
}

.dashboardBodyDashboard {
  padding: 24px 0 0 0;
  border-radius: 12px;
  @include dFlexJustiFyCenter;
}

.pb-88 {
  padding-bottom: 88px !important;
  @include dFlexJustiFyCenter;
}

.dashboardBody2 {
  width: $width100;
  @include dFlexColumn;
}

.cardDashboard {
  width: $width100;
  min-height: 25vh !important;
  border-radius: 15px;
  box-shadow: -2px 11px 16px 5px rgba(0, 0, 0, 0.12);
  padding-top: 8px;
  p {
    margin-bottom: 0% !important;
  }
  .iconRRSS,
  .iconBudget {
    width: 95px;
  }
  .iconMailBox {
    width: 85px !important;
  }
}

.dashboard-header {
  h2 {
    padding-left: 1%;
  }
}

.linkDashboard {
  color: $globalUnset;
  text-decoration: $globalNone;
}

.dash-tutorial {
  background-color: $colorSecondaryBase !important;
  padding: 23px !important;
  color: $colorBasicWhite !important;
  display: inline-flex !important;
  @include dFlexAlingnCenter;
  border-radius: 5px !important;
  border-color: $globalUnset !important;
  width: $width100;
  img {
    margin-right: 16px;
  }
  p {
    margin: $globalUnset;
  }
}

.dFlexChangeLanguages {
  display: flex;
  justify-content: flex-end;
  padding-right: 1.5em !important;
}

.dFlexLanguages {
  display: inline-block;
  justify-content: flex-end !important;
}

.changeLanguageES,
.changeLanguageEN {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background-image: url("../../../assets/images/ES.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center !important;
}

.changeLanguageEN {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background-image: url("../../../assets/images/EN.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center !important;
}

.textBtnLanguage {
  background-color: transparent !important;
  border: transparent !important;
  color: black !important;
}

.textBtnLanguage:focus {
  box-shadow: none !important;
  background-size: 100% 2px;
}

.modalDashboard {
  .modal-content {
    background-color: transparent;
    border: none;
  }
}

.tutorial-modal .modal-dialog {
  @include dFlexCenter;
  .modal-content {
    height: 95%;
    width: 95%;
    .modal-header {
      border-bottom: $globalUnset !important;
      button {
        background-color: $colorPrimaryLightModeBase1;
      }
    }
    .carousel-container {
      @include dFlexCenter;
      height: $height100;
      .carousel-inner {
        width: 65%;
        .carousel-item {
          width: $height100;
          img {
            margin: auto;
          }
        }
        .carousel-caption {
          color: $colorBasicBlack;
          position: $globalUnset !important;
          h3 {
            font-size: $bodyBold;
            font-family: $robotoBold;
          }
          p {
            font-size: $subcontentRegular;
            font-family: $robotoRegular;
            color: $colorNeutroGreyBase6;
          }
        }
      }
      .carousel-control-next-icon,
      .carousel-control-prev-icon {
        height: $globalUnset !important;
      }
      .carousel-indicators button {
        background-color: $colorSecondaryBase;
        width: 17px;
        height: 17px;
        border-radius: $borderRadius50;
      }
    }
    .input-show-tutorial {
      @include dFlexCenter;
      padding: 2%;
      label {
        margin-left: 1%;
      }
    }
  }
}

.video-tutorial {
  width: $width100;
  margin: auto;
}

.calendar-size {
  width: $width100;
}

.asd {
  width: auto !important;
}

.buttonPCInformation {
  margin-top: 1em;
}

.rowFixedHeaderPc {
  width: 100%;
  position: fixed;
  top: 0px !important;
  background-color: var(--bg-mode);
  padding-top: 20px;
  z-index: 9;
  hr {
    margin: 0;
  }
}

.rowFixedHeaderPcLink {
  width: 100%;
  position: fixed;
  top: 73px;
  background-color: var(--bg-mode);
  z-index: 9;
}

@media screen and (min-width: 0px) and (max-width: 413px) {
  .dashboardBody {
    margin-bottom: 6em;
    border-radius: 12px;
  }
}

.dashboardBody {
  border-radius: 12px;
}

.rowFixedHeaderPcLink {
  top: 109px !important;
}

@media screen and (min-width: 0px) and (max-width: 455px) {
  .dFlexChangeLanguages {
    justify-content: center;
    padding: 0% !important;
    .divButtonsLanguage {
      .changeLanguageSession {
        margin: 8px !important;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .cardDashboard {
    width: $width100;
    min-height: 25vh !important;
  }
  // .button-whatsapp-dashboard {
  //   background-color: $colorAcentoBase !important;
  //   width: $width100 !important;
  //   height: 155px;
  //   position: $globalUnset !important;
  //   border: 0 !important;
  //   padding: 10px !important;
  //   border-radius: 12px !important;
  //   margin-top: 1.6em;
  //   p.tex-show-w {
  //     display: $globalUnset !important;
  //     margin-left: 16px;
  //     text-align: $flexStart;
  //   }
  // }

  img.icon-whatsapp {
    width: 30px !important;
    height: 30px !important;
  }

  .calendar-size {
    width: $width100;
  }

  .video-tutorial {
    width: 53%;
  }
}

// @media screen and (min-width: 576px) {
//   .tex-show-w {
//     display: $globalNone;
//   }
// }

@media screen and (max-width: 767px) {
  .rowFixedHeaderPc {
    top: 56px !important;
    .positionButton {
      bottom: 21px;
      position: fixed;
      z-index: 1040;
      width: 225px;
    }
  }
  .rowFixedHeaderPcLink {
    top: 114px;
  }
}

@media screen and (min-width: 768px) {
  .rowFixedHeaderPc {
    top: 20px;
    .header-pc {
      padding-right: 30% !important;
      padding-left: 0% !important;
    }
  }

  .rowFixedHeaderPcLink {
    top: 73px !important;
  }

  .calendar-size {
    width: $width100;
  }

  .video-tutorial {
    width: 53%;
  }

  .cardDashboard {
    max-width: 480px !important;
  }

  .dashboardBodyDashboard {
    padding: 24px 0 0 0;
    border-radius: 12px;
  }
}

@media screen and (min-width: 1195px) {
  .video-tutorial {
    width: 62%;
  }

  .rowFixedHeaderPc {
    .header-pc {
      .positionButton {
        margin-right: 13%;
      }
    }
  }
}

@media screen and (min-width: 1400px) {
  .rowFixedHeaderPc {
    .header-pc {
      .positionButton {
        margin-right: 10%;
      }
    }
  }
}

@media screen and (min-width: 1600px) {
  .rowFixedHeaderPc {
    .header-pc {
      .positionButton {
        margin-right: 10%;
      }
    }
  }
}

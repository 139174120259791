/////////////////TIPOS DE LETRAS GENERALES///////////////

@font-face {
  font-family: $robotoRegular;
  src: url(../../fonts/Roboto-Regular.ttf);
}

@font-face {
  font-family: $robotoBold;
  src: url(../../fonts/Roboto-Bold.ttf);
}

@font-face {
  font-family: $robotoLight;
  src: url(../../fonts/Roboto-Light.ttf);
}

@font-face {
  font-family: $robotoMedium;
  src: url(../../fonts/Roboto-Medium.ttf);
}

//FONT FAMILY TODA LA APP///
.fontFamilyApp {
  font-family: $robotoRegular;
}

.h-100vh {
  height: $height100vh !important;
}

.marginb-15 {
  margin-bottom: 15px;
}

.padding-box-p {
  padding: 20px 32px 0px 32px;
}

.padding-box-dsh {
  padding: 0px 16px 0px 16px;
}

.textAccepted {
  color: $colorSemanticGreenBase;
  font-weight: 700;
}

.textAssign {
  color: $colorSecondaryBase1;
  font-weight: 700;
}

.textReject {
  color: $colorSemanticRedBase;
  font-weight: 700;
}

.textInfo {
  color: $colorSecondaryBase;
  font-size: 12px;
  font-weight: 400;
  @include dFlexCenter;
  margin-right: 12px;
}

.textDisabled {
  color: $colorNeutroGreyBase2;
}

.employed_deleted {
  color: $colorBasicRed;
}

.borderLeft0 {
  border-left: $globalNone;
}

.borderRight0 {
  border-right: $globalNone !important;
}

.fWrapNone {
  flex-wrap: $globalUnset !important;
}

.fWrap {
  flex-wrap: wrap !important;
}

.minInput {
  min-width: 120px !important;
}

.body {
  height: $height100vh;
}

.text-error {
  color: #dc3545;
  font-size: 14px;
  font-weight: 600;
  margin-left: 5px;
}

/* VIEWPORT */
.totalHeight {
  height: $height100vh;
}

.totalWidth {
  width: $width100vh;
}

/* - FONDO LIGHTMODE */
.lightBackground {
  background-color: $colorPrimaryLightModeBase-1;
}

.minHeightBudget {
  min-height: 100vh;
}

.greyBackground {
  background-color: $colorPrimaryLightModeBase;
}

// - FONDO AZUL CLARO
.lightBlueBackground {
  // background-color: #DBE5FF;
  background-color: #5268af;
}
.bg-badge {
  background-color: #5268af !important;
}

/* TÍTULOS */
.greyText {
  color: $colorNeutroGreyBase6 !important;
}

.pText {
  color: $colorSecondaryBase2;
}

.headingH1 {
  font-size: $headingH1;
  font-family: $robotoBold;
}
.skin-tone-image img {
  width: 180px;
  margin-left: 10px; /* Puedes ajustar el valor según tus necesidades */
}

.headingH2 {
  font-size: $headingH2;
  font-family: $robotoBold;
  text-align: left;
}

.headingH3 {
  font-size: $headingH3;
  font-family: $robotoBold;
}

.headingH4 {
  font-size: $headingH4;
  font-family: $robotoBold;
}

.headingH5 {
  font-size: $headingH5;
  font-family: $robotoBold;
}

.headingH6 {
  font-size: $headingH6;
  font-family: $robotoBold;
}

/* BODY FONT  */
.bodyDetailsRegular {
  font-size: $detailsRegular;
  font-family: $robotoRegular;
}

.bodyDetailsBold {
  font-size: $detailsBold;
  font-family: $robotoBold;
}

.bodyeasycontentLight {
  font-size: $subcontentLight;
  font-family: $robotoLight;
}

.bodySubcontentLight {
  font-size: $subcontentLight;
  font-family: $robotoLight;
}

.bodySubcontentRegular {
  font-size: $subcontentRegular;
  font-family: $robotoRegular;
}

.bodySubcontentBold {
  font-size: $subcontentBold;
  font-family: $robotoBold;
}

.bodyLight {
  font-size: $bodyLight;
  font-family: $robotoLight;
}

.bodyRegular {
  font-size: $bodyRegular !important;
  font-family: $robotoRegular;
}

.bodyMedium {
  font-size: $bodyMedium;
  font-family: $robotoMedium;
}

.bodyBold {
  font-size: $bodyBold;
  font-family: $robotoBold;
}
.bodyBold14 {
  font-size: $bodyBold14;
  font-family: $robotoBold;
}

.share {
  font-size: $subcontentBold;
  font-family: $robotoBold;
  cursor: pointer;
}

.share:hover {
  color: $colorSecondaryBase2;
}

.topContent,
.topContentLogin {
  @include dFlexBetweenCenter;
  padding-top: 3em;
}

.topContentLogin {
  margin-bottom: 3em;
}

.formData {
  width: 100%;
  @include dFlexColumnCenter;
}

/* /////COLOR SPINNER TODA LA APP// */
.colorSpinner {
  color: white !important;
}

/* /////POSICION Y COLOR SPINNER CONFIGURA ASISTENTE// */
.color-position-spinner {
  position: relative !important;
  top: 19px !important;
  left: 19px !important;
  color: #5268af !important;
}

/*  RESPONSIVE  */

@media screen and (max-width: 413px) {
  .confirmation-container,
  .error-confirmation-container {
    width: 85% !important;
    left: 7.2% !important;
  }

  .topContent,
  .topContentLogin {
    flex-direction: column;
    padding-left: 0.8em;
    padding-right: 0.8em;
  }

  .buttonMarginLoginRegister {
    margin-top: 2em;
  }

  .login-input {
    display: flex;
    width: 109%;
  }
}




.asignedCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
/* ////ASIGNAR ARTISTA BUDGETBUTTON Y BUDGETACTIONS/////*/

@media screen and (max-width: 575px) {
  .displayButtonAsignedArtist {
    @include dFlexColumnAlingnCenter;
  }

  .asignedArtistTextCenter {
    text-align: $alignCenter;
  }
}

@media screen and (min-width: 576px) and (max-width: 652px) {
  .centralBoxColunmAsignedArtist {
    padding-left: 19%;
  }

  .asignedArtistTextCenter {
    text-align: $alignCenter;
  }
}

/* ///////////////////////////////// */

@media screen and (min-width: 414px) {
  .topContent,
  .topContentLogin {
    justify-content: $spaceAround;
    padding-left: 0.45em;
    padding-right: 0.6em;
  }

  .confirmation-container,
  .error-confirmation-container {
    width: 86% !important;
    left: 7% !important;
  }
}

@media screen and (max-width: 576px) {
  .centralBox {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 56px;
    padding-bottom: 56px;
  }
}

@media screen and (min-width: 576px) {
  .confirmation-container {
    width: 75% !important;
    left: 12.5% !important;
  }

  .centralBox {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 56px;
    padding-bottom: 56px;
  }

  .topContent {
    justify-content: $spaceAround;
    padding-left: 0em;
    padding-right: 0em;
  }

  .topContentLogin {
    margin-bottom: 11em;
  }

  .login-input {
    width: $width100;
  }
}

@media screen and (min-width: 768px) {
  .centralBox {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .scroll-container {
    position: $positionAbsolute;
    height: $height100;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .scroll-position {
    position: $positionRelative;
    overflow-x: hidden !important;
  }

  .confirmation-container {
    left: 14% !important;
  }

  .error-confirmation-container {
    width: $width75 !important;
    left: 11.1%;
  }

  .blockLogin {
    width: $width75;
  }

  .login-input {
    width: 107.3%;
  }
}

@media screen and (min-width: 992px) {
  .centralBox {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .confirmation-container {
    width: 62% !important;
    left: 21% !important;
  }

  .error-confirmation-container {
    width: 65% !important;
    left: 16.5%;
  }

  .login-input {
    width: 106%;
  }
}

@media screen and (min-width: 1200px) {
  .centralBox {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .centralBoxColunmAsignedArtist {
    align-items: $alignItemEnd;
  }

  .confirmation-container {
    width: 52% !important;
    left: 25% !important;
  }

  .error-confirmation-container {
    width: 55% !important;
    left: 21.5%;
  }

  .login-input {
    width: 104%;
  }
}

@media screen and (min-width: 1400px) {
  .confirmation-container {
    width: 45% !important;
    left: 26%;
  }

  .confirmation-container {
    width: 44% !important;
    left: 28% !important;
  }

  .error-confirmation-container {
    width: 45% !important;
    left: 28.5%;
  }

  .login-input {
    width: 104%;
  }
}

@media screen and (min-width: 1600px) {
  .confirmation-container {
    width: 39% !important;
    left: 28.5% !important;
  }

  .error-confirmation-container {
    width: 40% !important;
    left: 32.5%;
  }

  .login-input {
    width: 102.5%;
  }
}

@media screen and (min-width: 1800px) {
  .confirmation-container {
    width: 32% !important;
    left: 32.5% !important;
  }

  .error-confirmation-container {
    width: 35% !important;
    left: 32.5%;
  }

  .login-input {
    width: 102.5%;
  }
}

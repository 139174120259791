/* -BOTÓN MORADO CORTO */
.purpleSmallButton {
  background-color: $colorSecondaryBase !important;
  color: $colorPrimaryLightModeBase-2 !important;
  border-color: $colorSecondaryBase !important;
  width: $width125px;
}

.purpleSmallMediumButton {
  background-color: $colorSecondaryBase !important;
  color: $colorPrimaryLightModeBase-2 !important;
  border-color: $colorSecondaryBase !important;
  width: 198px;
  height: 38px;
  margin-bottom: 30px;
}

.redSmallButton {
  background-color: $colorSemanticRedBase !important;
  color: $colorPrimaryLightModeBase-2 !important;
  border-color: $colorSemanticRedBase !important;
  width: $width125px;
}

.purpleSmallButton a {
  text-decoration: $globalNone;
}

.purpleSmallButton :hover {
  background-color: $colorSecondaryBase2 !important;
  border-color: $colorSecondaryBase2 !important;
}

.purpleSmallButton :focus {
  background-color: $colorSecondaryBase3 !important;
  border-color: $colorSecondaryBase3 !important;
}

.purpleSmallButton :disabled {
  background-color: $colorSecondaryBase-2 !important;
  border-color: $colorSecondaryBase-2 !important;
}

/* BOTÓN MORADO TRANSPARENTE CORTO */
.purpleTransparentSmallButton {
  background-color: $globalTransparent !important;
  color: $colorSecondaryBase !important;
  border-color: $colorSecondaryBase !important;
  width: $width125px;
}

.purpleSmallButton a {
  text-decoration: $globalNone;
}

.purpleTransparentSmallButton :hover {
  background-color: $globalTransparent !important;
  border-color: $colorSecondaryBase2 !important;
}

.purpleTransparentSmallButton :focus {
  background-color: $globalTransparent !important;
  border-color: $colorSecondaryBase3 !important;
}

.purpleTransparentSmallButton :disabled {
  background-color: $globalTransparent !important;
  border-color: $colorSecondaryBase-2 !important;
}

/* -BOTÓN MORADO MEDIANO */
.purpleMediumButton {
  background-color: $colorSecondaryBase !important;
  color: $colorPrimaryLightModeBase-2 !important;
  border-color: $colorSecondaryBase !important;
  width: 50%;
}

.purpleMediumButton :hover {
  background-color: $colorSecondaryBase2 !important;
  border-color: $colorSecondaryBase2 !important;
}

.purpleMediumButton :focus {
  background-color: $colorSecondaryBase3 !important;
  border-color: $colorSecondaryBase3 !important;
}

.purpleMediumButton :disabled {
  background-color: $colorSecondaryBase-2 !important;
  border-color: $colorSecondaryBase-2 !important;
}

/* BOTÓN MORADO TRANSPARENTE MEDIANO */
.purpleTransparentMediumButton {
  background-color: $globalTransparent !important;
  color: $colorSecondaryBase !important;
  border-color: $colorSecondaryBase !important;
  width: 50%;
}

.purpleTransparentMediumButton :hover {
  background-color: $globalTransparent !important;
  border-color: $colorSecondaryBase2 !important;
}

.purpleTransparentMediumButton :focus {
  background-color: $globalTransparent !important;
  border-color: $colorSecondaryBase3 !important;
}

.purpleTransparentMediumButton :disabled {
  background-color: $globalTransparent !important;
  border-color: $colorSecondaryBase-2 !important;
}

/* -BOTÓN MORADO LARGO */
.purpleLargeButton {
  background-color: $colorSecondaryBase !important;
  color: $colorPrimaryLightModeBase-2 !important;
  border-color: $colorSecondaryBase !important;
  width: $width100;
}

.purpleLargeButton :hover {
  background-color: $colorSecondaryBase2 !important;
  border-color: $colorSecondaryBase2 !important;
}

.purpleLargeButton :focus {
  background-color: $colorSecondaryBase3 !important;
  border-color: $colorSecondaryBase3 !important;
}

.purpleLargeButton :disabled {
  background-color: $colorSecondaryBase-2 !important;
  border-color: $colorSecondaryBase-2 !important;
}

/* BOTÓN MORADO TRANSPARENTE LARGO */
.purpleTransparentLargeButton {
  background-color: $globalTransparent !important;
  color: $colorSecondaryBase !important;
  border-color: $colorSecondaryBase !important;
  width: $width100;
}

.purpleTransparentLargeButton :hover {
  background-color: $globalTransparent !important;
  border-color: $colorSecondaryBase2 !important;
}

.purpleTransparentLargeButton :focus {
  background-color: $globalTransparent !important;
  border-color: $colorSecondaryBase3 !important;
}

.purpleTransparentLargeButton :disabled {
  background-color: $globalTransparent !important;
  border-color: $colorSecondaryBase-2 !important;
}

/* -BOTÓN VERDE CORTO */
.greenSmallButton {
  background-color: $colorPrimaryLightModeBase-2 !important;
  color: $colorAcentoBase !important;
  border-color: $colorAcentoBase !important;
  width: $width125px;
}

.greenSmallButton :hover {
  background-color: $colorAcentoBase1 !important;
  border-color: $colorAcentoBase1 !important;
}

.greenSmallButton :focus {
  background-color: $colorAcentoBase2 !important;
  border-color: $colorAcentoBase2 !important;
}

.greenSmallButton :disabled {
  background-color: $colorAcentoBase-2 !important;
  border-color: $colorAcentoBase-2 !important;
}

/* -BOTÓN VERDE MEDIANO */
.greenMediumButton {
  background-color: $colorPrimaryLightModeBase-2 !important;
  color: $colorAcentoBase !important;
  border-color: $colorAcentoBase !important;
  width: 155px;
}

.greenMediumButton :hover {
  background-color: $colorAcentoBase1 !important;
  border-color: $colorAcentoBase1 !important;
}

.greenMediumButton :focus {
  background-color: $colorAcentoBase2 !important;
  border-color: $colorAcentoBase2 !important;
}

.greenMediumButton :disabled {
  background-color: $colorAcentoBase-2 !important;
  border-color: $colorAcentoBase-2 !important;
}

/* -BOTÓN VERDE LARGO */
.greenLargeButton {
  background-color: $colorPrimaryLightModeBase-2 !important;
  color: $colorAcentoBase !important;
  border-color: $colorAcentoBase !important;
  width: $width100;
}

.greenLargeButton :hover {
  background-color: $colorAcentoBase1 !important;
  border-color: $colorAcentoBase1 !important;
}

.greenLargeButton :focus {
  background-color: $colorAcentoBase2 !important;
  border-color: $colorAcentoBase2 !important;
}

.greenLargeButton :disabled {
  background-color: $colorAcentoBase-2 !important;
  border-color: $colorAcentoBase-2 !important;
}

/* BOTÓN PEQUEÑO BORDE/LETRAS-AZUL BG-TRANSPARENTE */
.blueSmallButton {
  background-color: $globalTransparent !important;
  color: $colorSecondaryBase !important;
  border-color: $colorSecondaryBase !important;
  width: $width125px;
}

/* -BOTÓN VERDE COPIAR URL */
.greenLargeCopyButton {
  background-color: $colorAcentoBase !important;
  color: $colorBasicWhite !important;
  border-radius: 22px !important;
  border-color: $colorAcentoBase !important;
}

.greenLargeCopyButton:focus {
  box-shadow: none !important;
}

.greenLargeCopyButtonlink {
  width: 150px;
  background-color: $colorAcentoBase !important;
  color: $colorBasicWhite !important;
  border-radius: 22px !important;
  border-color: $colorAcentoBase !important;
  // padding-left: 2em !important;
  // padding-right: 2em !important;
}

// .greenLargeCopyButtonlink:focus {
//   box-shadow: none !important;
// }

//Sin estilos
.noStyle {
  background: none;
  border: 0;
  color: inherit;
  /* cursor: default; */
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  // -webkit-user-select: none; /* for button */
  // -webkit-appearance: button; /* for input */
  // -moz-user-select: none;
  // -ms-user-select: none;
}

.sizeDelete {
  position: absolute;
  top: -46px;
  left: 5px !important;
  img {
    width: 12px !important;
    height: 12px !important;
  }
}

/* -BOTÓN ACEPT WHATSAPP BUDGET */
.bottonWhatsApp {
  background-color: #25d366 !important;
  color: white !important;
  margin-bottom: 16px;
  border: none !important;
  padding-left: 1px !important;
  padding-right: 1px !important;
}

// -BOTÓN PROFILE
.bottonProfile {
  width: 145px;
  height: 40px;
  border-radius: 50px !important;
  border: none !important;
}

//-BOTON SINGUP 
.bottonSingup {
  width: 145px;
  height: 40px;
  background-color: #3E3E42 !important;
  border-radius: 50px !important;
  border: none !important;
}

// -BOTONES CONFIGURA TU ASISTENTE
.bottonConfigAssistant {
  width: 145px;
  height: 32px;
  border-radius: 50px !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.bottonDeleteConfigAssistant {
  width: 145px;
  height: 32px;
  border-radius: 50px !important;
  border: 1px solid #adadad !important;
  color: #adadad !important;
  box-shadow: none !important;
  padding: 0 !important;
  background-color: transparent !important;
}

// -BOTÓN STRIP
.bottonStripe {
  width: 244px;
  height: 40px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  // background-color: black !important;
  border: none !important;
  border-radius: 50px !important;
  padding: 0 !important;

  .textButtonLeft {
    font-size: 14px;
    margin-bottom: 0;
  }
  .textButtonRight {
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 600;
  }
}

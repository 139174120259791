.budget-container {
  padding: 0 !important;

  .row-budget-container {
    // width: 100%;
    margin: 0%;

    .col-title-budget {
      width: 100% !important;
      padding: 0% !important;

      .div-title-budget {
        margin-bottom: 0%;

        .budget-tittle {
          padding: 20px 0px 8px 0px;
        }
      }
    }
    hr {
      margin-bottom: 24px;
    }
  }

  .div-budget-container {
    display: flex;
    justify-content: center;
    padding-left: 4px;
    padding-right: 4px;

    .div-generic-budget {
      width: 845px;
      max-width: 845px;
      display: flex;
      justify-content: center;
      .row-generic-budget {
        width: 100%;
      }
    }
    .col-messages-count {
      display: flex;
      justify-content: center;
      padding: 0% !important;
      .budget-message-count {
        background-color: $colorSecondaryBase !important;
        color: $colorPrimaryLightModeBase-2 !important;
        border-color: $colorSecondaryBase !important;
        width: $width100;
        border-radius: 4px;
        padding: 8px;
        margin-bottom: 8px;
        p {
          margin: 0;
          .linkWhite {
            color: white !important;
          }
        }
      }
    }
    .budget-message-count-limit {
      background-color: #c46464 !important;
      color: $colorPrimaryLightModeBase-2 !important;
      border-color: $colorSecondaryBase !important;
      width: $width100;
      border-radius: 4px;
      padding: 8px;
      margin-bottom: 24px;
      p {
        margin: 0;
        .linkWhite {
          color: white !important;
        }
      }
    }
    .colBudgetAccordion {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-bottom: 24px !important;
      padding: 0% !important;

      .div-buttons-refresh-delete {
        width: 100%;
        max-width: 845px;
        display: flex;
        justify-content: space-between;
        .custom-button-download,
        .button-refresh,
        .button-delete {
          border: none;
          display: flex;
          align-items: center;
          border-radius: 50px;
          padding: 6px;
          .text-boton-download,
          .text-boton-refresh,
          .text-boton-delete {
            margin: 0;
            padding-left: 6px;
          }
        }
        .custom-button-download {
          background-color: #5268af;
        }
        .custom-button-download img {
          height: 32px;
          width: 32px;
          margin-left: 3px;
        }
        .button-refresh {
          background-color: #52af9a;
        }
        .button-delete {
          background-color: #d22828;
          margin-left: 8px;
          padding: 8px;
        }
      }
    }
  }
}

.filterButton {
  background-color: #e6e6e6 !important;
  border-color: #e6e6e6 !important;
}
.div-check-delete-budget {
  .form-check-input-checked {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    vertical-align: top;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid #33d713 !important;
    appearance: none;
    background-color: #33d713 !important;
    // border-radius: 0.25em;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e");
  }
}
.filterButton:focus {
  box-shadow: $globalUnset !important;
}

.div-check-delete-budget {
  .form-check-input {
    margin-top: 0% !important;
    width: 24px;
    height: 24px;
    border: 2px solid;
    background-color: transparent;
    border-radius: 4px;
    cursor: pointer;
  }
}

.form-check-input:checked {
  background-color: $colorChecked !important;
  border-color: $colorChecked !important;
}

.accordion-budgets {
  padding: 0% !important;
  .style-acordion-buttons {
    margin-bottom: 24px;
    border: none;
    border-radius: 6px !important;
    background-color: transparent;
  }

  .accordionEnabled {
    border-radius: 6px;
  }
}

.col-input-search-select {
  margin-bottom: 24px;
  padding: 0% !important;
  // padding-right: 20px !important;
  .div-input-search-select {
    width: 100%;
    display: flex;
    .div-input-search {
      width: 70%;
      height: 44px;
      img {
        position: absolute;
        transform: translateY(50%) translateX(60%);
      }
      input {
        width: 100%;
        height: 100%;
        padding-left: 45px;
        border: none;
        border-radius: 5px;
        // background-color: #e4e4e4;
      }
    }
    .div-input-select {
      width: 30%;
      height: 44px;
      padding-right: 8px;
      select {
        width: 100%;
        height: 100%;
        margin-left: 8px;
        padding-left: 8px;
        border: none;
        border-radius: 5px;
        appearance: none;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='gray' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 11px center;

        background-size: 1em;
      }
    }
  }
}

.colorButtonPendingBudget {
  background-color: #e8db6b !important;
  color: black !important;
}

.colorButtonAcceptedBudget {
  background-color: #6be377 !important;
  color: black !important;
}

.colorButtonClientBudget {
  background-color: #64cee5 !important;
  color: black !important;
}

.colorButtonDiscardedBudget {
  background-color: #cccccc !important;
  color: black !important;
}

.stylesButtonBudget {
  // width: 115px;
  width: 100px;
  height: 25px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border: none !important;
  border-radius: 0.3em !important;
  // height: 33px !important;
  font-size: 14px !important;
  padding: 6px 4px !important;
}

.stylesButtonBudget:after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.badgeGreen {
  @include buttonsBudget;
}

.badgeOrange {
  @include buttonsBudget($backgroundColor: $colorSemanticYellowBase1);
}

.badgeRed {
  @include buttonsBudget($backgroundColor: $colorSemanticRedBase);
}

.badgePurple {
  @include buttonsBudget($backgroundColor: $colorSecondaryBase);
}

.requestStatusDelete {
  @include dFlexAlingnCenter;
  width: 32%;
}

.iconTrash {
  padding-right: 8px;
}

.trashButton {
  display: flex;
  align-items: flex-start;
  background-color: $globalTransparent !important;
  color: $colorPrimaryDarkModeBase-4 !important;
  border-color: $globalTransparent !important;
  box-shadow: $globalUnset !important;
}

.div-button-styles-budgets {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  margin-bottom: 24px;
  .div-imagen-budget {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 20%;
    .imageDataBudget {
      width: 96px;
      height: 96px;
      display: flex;
      align-items: center;
      margin: auto;
      margin-right: 16px;
      margin-left: 26px;
      border-radius: 6px;
      .imagen-default {
        width: 96px;
        height: 96px;
        object-fit: cover;
        border-radius: 6px;
        border: 1px solid;
      }
      .imageCarouselBudget {
        width: 96px;
        height: 96px;
        object-fit: cover;
        border-radius: 6px;
      }
    }
  }
  .div-info-budget {
    width: 80%;

    p {
      margin: 0%;
      word-break: break-all;
    }
    .textBold {
      font-weight: bold;
    }
    .div-info-drop {
      display: flex;
      justify-content: space-between;
    }
    .div-description-service {
      display: flex;
      align-items: center;
      .description-service {
        width: 330px;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
        overflow: hidden !important;
        font-size: 14px;
      }
    }
  }
  // .div-button-dropdown {
  //   width: 115px;
  //   height: 90px;
  //   display: flex;
  //   justify-content: flex-end;
  //   align-items: flex-start;
  //   padding-top: 12px;
  // }
}

///// MAQUETACION BUDGETINFORMATION   ///////
.accordion-budgets {
  .div-accordion-body-budgets {
    // display: flex;
    padding: 16px 24px;
    border-radius: 6px;
    .div-info-budgets {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .div-image-info-budget {
        width: 290px;
        height: 290px;
        .imagen-default,
        .image-info-budget {
          width: 100%;
          height: 100%;
          border: 1px solid grey;
          border-radius: 6px;
          object-fit: cover;
          object-position: center;
          cursor: zoom-in !important;
        }

        .image-info-budget {
          width: 290px;
          height: 290px;
          border: none;
        }
      }
    }
  }

  .div-summary-budget {
    margin-top: 16px;
    display: flex;
    .div-icons-text-info-budgets {
      display: flex;
      margin-bottom: 8px;
      .margin-icon-info {
        margin-left: 2px;
      }
      .margin-icon-info5 {
        margin-left: 5px;
      }
      .margin-left-text-bodypart {
        margin-left: 13px;
      }
      .margin-left-text-city {
        margin-left: 3px;
      }
      p,
      span {
        margin: 0%;
        padding-left: 8px;
      }
    }

    .div-size-icon-text,
    .div-body-part-icon-text,
    .div-color-icon-text {
      display: flex;
      margin-bottom: 10px;
    }
    .div-text-example-mock {
      width: 100%;
      max-width: 290px;
    }
  }
}
.icon-with-margin {
  margin-right: 10px;
}
////////////// MAQUETACION BUDGET ACTIONS ///////////////
.div-actions-budget {
  width: 100%;
  .div-actions-pending {
    padding: 0px 0px 0px 16px;
    .div-mail-phone {
      width: 100%;
      border-bottom: 1px solid;
      p {
        margin: 0%;
        word-break: break-all;
      }
    }
    .div-description-budget {
      margin-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid;
    }
    .div-send-budget {
      margin-top: 16px;
      .text-break {
        word-break: break-all;
      }
    }
    .div-inpusts-price-budget {
      display: flex;
      justify-content: space-between;
      .div-input-from,
      .div-input-to {
        width: 50%;
        .form-control {
          border: none;
        }
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
      .div-input-to {
        margin-left: 4px;
      }
    }
    .div-textarea-budget {
      margin-top: 16px;
      .form-control {
        border: none;
        // background: #e4e4e4 !important;
      }
    }
  }
}

.btnDenegedSmall,
.btnAcceptSmall {
  width: 100%;
}
////////////MODAL IMG BUDGET//////////
.ModaImgBudgetlHeight {
  .divImgModalBudget {
    .modalImgBudgetPosition {
      position: absolute;
      right: 0%;
      border: none;
      z-index: 999;
      button {
        background-color: white;
        border-radius: 50px;
        border: 3px solid black !important;
      }
    }
    img {
      width: 100% !important;
      object-fit: contain !important;
      max-height: 90vh;
    }
  }
}

.modal-zoom-images {
  .modal-content {
    background-color: #faefef14 !important;
    .imageCarouselBudgetModal {
      max-height: 90vh;
      object-fit: contain;
      object-position: center;
      width: 100%;
    }
    .modal-header {
      button {
        background-color: white;
        opacity: unset !important;
        margin-right: 0px !important;
      }
    }
  }
}

.carousel-control-prev,
.carousel-control-next {
  opacity: unset !important;
  img {
    width: 32px;
  }
}

.dropdown-toggle::after {
  display: $globalNone !important;
}

.accordion-button {
  border-radius: 6px !important;
}

.accordion-button:not(.collapsed) {
  color: $colorBasicBlack !important;
  background-color: #f5f5f5 !important;
}

.accordion-button:not(.collapsed)::after {
  display: $globalNone;
}

.accordion-button::after {
  display: $globalNone;
}

.accordion-button:focus {
  background-color: #f5f5f5 !important;
  box-shadow: $globalUnset !important;
  border: 1px solid #8ccabc !important;
}

.requestAnswerd {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.height100 {
  height: 100%;
}

.accordionDataDiv {
  border-radius: 10px;
  padding: 16px;
  background-color: white;
  // .requestArtistAndAmount h4 {
  //   padding-bottom: 8px;
  // }
}

.requestArtist {
  margin-bottom: 16px;
}

.requestArtistButton {
  background-color: $globalTransparent !important;
  border-color: $globalTransparent !important;
  color: $colorPrimaryDarkModeBase1 !important;
  display: flex;
}

.requestArtistButton:focus {
  box-shadow: $globalUnset !important;
}

.requestArtistButton p:focus {
  color: $colorBasicBlack !important;
}

.requestArtistButton:focus {
  color: $colorSecondaryBase !important;
  font-weight: bold;
}

.requestAmount h4 {
  padding-bottom: 8px;
}

.requestAmount .textName2BudgetMock span {
  color: $colorPrimaryDarkModeBase;
  font-weight: bold;
}

.form-control:focus {
  box-shadow: 0 0 0 0.25rem #00000040 !important;
  border-color: $globalTransparent !important;
}

.purpleTransparentSmallButton:focus {
  box-shadow: $globalUnset !important;
}

.purpleSmallButton:focus {
  background-color: $colorSecondaryBase !important;
  box-shadow: $globalUnset !important;
}

.requestPagination {
  @include dFlexCenter;
  padding-top: 24px;
  color: #292929 !important;
}

.requestPagination:focus {
  box-shadow: $globalUnset !important;
}

.page-link {
  color: black !important;
}

.requestEmpty {
  @include dFlexColumnCenter;
  width: $width40;
}

.requestArtistButton {
  width: 80%;
  img {
    width: 65px;
    height: 65px;
    object-fit: cover;
    border-radius: $borderRadius50 !important;
  }
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: $colorSecondaryBase !important;
  border-color: $colorSecondaryBase !important;
}

.card-delete-modal {
  width: 35rem;
}
.accordionDisabled {
  width: 100% !important;
  opacity: 0.12;
}
.input2 {
  box-sizing: border-box;
  color: #191919;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}
.input-icon {
  color: #191919;
  position: absolute;
  width: 100%;
  height: 60px;
}
.grey-budget {
  background-color: #e7e7e7 !important ;
  .greyText {
    color: white !important;
  }
}

@media screen and (max-width: 413px) {
  .row-budget-container {
    .div-title-budget {
      margin: 0% !important;
      .budget-tittle {
        padding-top: 65px !important;
        padding-right: 0px !important;
        padding-left: 0px !important;
        margin: 0% !important;
        text-align: center;
        padding-bottom: 8px !important;
      }
    }
  }

  .button-refresh,
  .button-delete,
  .custom-button-download {
    width: 35px !important;
    height: 35px !important;
    justify-content: center;
    border-radius: 50% !important;

    .text-boton-refresh,
    .text-boton-download,
    .text-boton-delete {
      display: none;
    }
  }

  .div-input-search-select {
    flex-direction: column;
    .div-input-search {
      width: 100% !important;
      margin-bottom: 8px;
    }

    .div-input-select {
      width: 100% !important;
      padding-right: 0% !important;
      select {
        margin-left: 0% !important;
      }
    }
  }

  .div-button-styles-budgets {
    .div-imagen-budget {
      width: 15% !important;
      .imageDataBudget {
        display: none;
        .imagen-default {
          width: 48px !important;
          height: 48px !important;
        }
      }
      .imageCarouselBudget {
        width: 48px !important;
        height: 48px !important;
      }
    }
    .div-info-budget {
      width: 85% !important;
    }
  }

  .div-accordion-body-budgets {
    padding-left: 16px !important;
    padding-right: 16px !important;
    .div-info-budgets {
      .div-image-info-budget {
        width: 100% !important;
        .carousel {
          width: 100%;
          .image-info-budget {
            width: 100% !important;
          }
        }
        .imagen-default {
          width: 100% !important;
        }
      }
    }
  }

  .div-description-service {
    p {
      display: none;
    }
  }
}

@media screen and (min-width: 414px) and (max-width: 575px) {
  .row-budget-container {
    width: 100%;
    .div-title-budget {
      margin: 0% !important;
      .budget-tittle {
        font-size: 24px;
        padding-top: 65px !important;
        padding-right: 0px !important;
        padding-left: 0px !important;
        margin: 0% !important;
        margin: 0% !important;
        text-align: center !important;
        padding-bottom: 8px !important;
      }
    }
  }

  .button-refresh,
  .button-delete,
  .custom-button-download {
    width: 35px !important;
    height: 35px !important;
    justify-content: center;
    border-radius: 50% !important;

    .text-boton-download,
    .text-boton-refresh,
    .text-boton-delete {
      display: none;
    }
  }

  .div-input-search-select {
    flex-direction: column;
    .div-input-search {
      width: 100% !important;
      margin-bottom: 8px;
    }

    .div-input-select {
      width: 100% !important;
      padding-right: 0% !important;
      select {
        margin-left: 0% !important;
      }
    }
  }

  .div-description-service {
    p {
      display: none;
    }
  }

  .div-imagen-budget {
    width: 40% !important;
    .imageDataBudget {
      .imagen-default {
        width: 72px !important;
        height: 72px !important;
      }
    }
    .imageCarouselBudget {
      width: 72px !important;
      height: 72px !important;
    }
  }

  .div-info-budget {
    width: 65% !important;
  }

  .imageCarouselBudget {
    width: 72px !important;
    height: 72px !important;
  }
  .div-accordion-body-budgets {
    flex-direction: column;
    .div-info-budgets {
      display: flex;
      align-items: center;
      .div-image-info-budget {
        width: 342px !important;
        height: 342px !important;
        .imagen-default,
        .image-info-budget {
          width: 342px !important;
          height: 342px !important;
        }
      }
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .row-budget-container {
    .div-title-budget {
      margin: 0% !important;
      .budget-tittle {
        padding-top: 65px !important;
        padding-bottom: 8px !important;
        margin: 0% !important;
        display: flex;
        justify-content: center;
      }
    }
  }
  .div-info-budget {
    width: 65% !important;
  }
}

@media screen and (max-width: 991px) {
  .div-generic-budget {
    width: 100% !important;
    max-width: 480px !important;
    .row-generic-budget {
      width: 100%;
    }
    .div-button-styles-budgets {
      padding: 10px;
      .div-imagen-budget {
        width: 35%;
      }

      .div-info-budget {
        width: 65%;
        .div-description-service {
          flex-direction: column;
          align-items: flex-start;
          .description-service {
            width: 95%;
          }
        }
      }
    }
  }
  .div-accordion-body-budgets {
    flex-direction: column !important;
    .div-image-info-budget {
      width: 100% !important;
      display: flex;
      justify-content: center;
      height: 250px !important;
      .imagen-default,
      .image-info-budget {
        width: 250px !important;
        height: 250px !important;
        border: 1px solid grey;
        border-radius: 6px;
        object-fit: cover;
        object-position: center;
      }
      .image-info-budget {
        width: 250px !important;
        height: 250px !important;
        border: none;
      }
    }
    .div-summary-budget {
      padding-bottom: 8px;
      border-bottom: 1px solid grey;
    }
    .disabled-information {
      display: none;
    }
    .div-actions-pending {
      margin-top: 16px;
    }
  }
  .div-actions-pending {
    padding-left: 0% !important;
  }
}
.linkShared{
  cursor: pointer;
}
.linkShared:active{
  color:red !important;
}
.bounce {
  animation: 3s linear 0s infinite normal none running animationInkup;
}

@keyframes animationInkup {
  0% {
    transform: translateX(3px) rotate(1deg);
  }
  2.5% {
    transform: translateX(-3px) rotate(-1deg);
  }
  5% {
    transform: translateX(3px) rotate(1deg);
  }
  7.5% {
    transform: translateX(-3px) rotate(-1deg);
  }
  10% {
    transform: translateX(2px) rotate(1deg);
  }
  12.5% {
    transform: translateX(-2px) rotate(-1deg);
  }
  15% {
    transform: translateX(2px) rotate(1deg);
  }
  17.5% {
    transform: translateX(-2px) rotate(-1deg);
  }
  20% {
    transform: translateX(1px) rotate(1deg);
  }
  22.5% {
    transform: translateX(-1px) rotate(-1deg);
  }
  25% {
    transform: translateX(0px) rotate(0deg);
  }
}

.header-be-link {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 5em;
  .divTextLink {
    .colorLink {
      text-decoration: underline;
      color: #0d6efd;
      @include ellipsis;
      text-align: center;
    }
  }

  button {
    margin-left: 16px;
    height: 36px;
  }
}
.greyTextbe {
  color: #7c7c7c !important;
}

.header-be-link-mock {
  flex-direction: column !important;
  margin-right: 0% !important;
}

.header-be-link {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 5em;
  .divTextLink {
    .colorLink {
      text-decoration: underline;
      color: #0d6efd;
      @include ellipsis;
      text-align: center;
    }
  }

  button {
    margin-left: 16px;
    height: 36px;
  }
}
.greyTextbe {
  color: #7c7c7c !important;
}
.budgetEmpty {
  border: none;
  h2 {
    margin-top: 8px;
    font-size: 16px;
  }
  .header-be-link {
    padding-right: 5em;
    padding: 16px 8px;
    display: flex;

    button {
      margin-left: 2%;
    }
  }
}
@media screen and (max-width: 413px) {
  .header-be-link {
    .divTextLink {
      width: 100%;
      .colorLink {
        width: 100%;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1049px) {
  .header-be-link {
    margin-right: 5em;
  }
}

@media screen and (max-width: 769px) {
  .modal-zoom-images {
    .modal-content .modal-body {
      .custom-carousel {
        a {
          display: none !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1049px) {
  .header-be-link {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .divTextLink {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 16px;
    }
  }
}

@media screen and (min-width: 1050px) {
  .header-be-link {
    margin-right: 5em;
  }
  .header-be-link-mock {
    flex-direction: column !important;
    .divTextLink-mock {
      margin-bottom: 8px !important;
    }
  }
}

.budget-elements-container-large {
  display: unset;
}
.budget-elements-container-small {
  display: none;
}

@media (max-width: 990px) {
  .budget-elements-container-large {
    display: none;
  }

  .budget-elements-container-small {
    display: unset;
  }
}

.divPageLoading {
  background-color: black;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logotypePageLoading {
  width: 208px;
}

/* /////////////MAQUETACION SPINNER CARGA DE IMAGENES DE REFERENCIA FORMCHAT/////// */
.divSpinnerLoadingImagesFormChat {
width: 128px;
height: 128px;
display: flex;
justify-content: center;
align-items: center;
}


.cookies-inkup .CookieConsent {
  z-index: 99999 !important;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px
}

@media screen and (max-width: 575px) {
  .cookies-inkup .CookieConsent {
    display: $globalUnset !important;
    z-index: 99999 !important;
    display: flex !important;
    flex-direction: column-reverse;
    height: 120px;
    .divButtonCookies {
      position: fixed;
      left: 0px;
      width: 100%;
      bottom:52px;
    }
    .divTextCookies {
      flex:none !important;
    }
  }
  .modal-dialog {
    margin: 0rem !important;
  }
}

/* ALERT CONFIRMACION FORMULARIO CONTACTO */
.confirmation-container {
  width: 24%;
  height: auto;
  color: $colorPrimaryDarkModeBase1 !important;
  background-color: $colorGreenAlertConfirmation !important;
  border: 2px solid $colorSemanticGreenBase !important;
  border-radius: 6px !important;
  position: $positionFixed !important;
  top: 10%;
  left: 35%;
  z-index: 999;
  .divAlertConfirmation {
    display: flex;
    align-items: center;
    padding: 1em;
    padding-bottom: 0%;
    padding-left: 0%;
    .closedAlert {
      background-color: unset;
      border: none;
      position: absolute;
      top: -3px;
      right: -4px;
    }
  }
}

.sharedBody {
  width: 100%;
}

.alert-confirmation-budget {
  width: 100%;
  height: 100%;
  color: #5268af !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  background-color: #fcfcfc !important;
  border: 2px solid #5268af !important;
  border-radius: 12px !important;
  top: 10%;
  right: 25%;
  z-index: 99999;
  box-shadow: 0px 10px 15px -3px rgba(154, 159, 176, 0.2),
    0px 4px 6px -2px rgba(154, 159, 176, 0.1);

  .header-alert-budget {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0px 0px 16px;
    border-bottom: 1px solid #5268af;
    font-weight: 400;
    font-size: 20px;
    line-height: 19px;
    .closedAlert {
      background-color: unset;
      border: none;
      padding-right: 0;
      filter: invert(40%) sepia(65%) saturate(438%) hue-rotate(188deg)
        brightness(89%) contrast(91%);
    }
    p {
      margin: 0;
    }
  }

  .header-alert-confirmation {
    padding-bottom: 4px !important;
  }

  .divAlertConfirmation {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    align-self: stretch;
    line-height: 19px;
    color: #0e0f11;
  }
}

/* ////////////////////////////////// */

/* ALERT ERROR CONFIRMACION FORMULARIO CONTACTO */
.error-confirmation-container {
  width: 35% !important;
  color: $colorPrimaryDarkModeBase1 !important;
  background-color: $colorRedAlertError !important;
  border: 2px solid $colorSemanticRedBase !important;
  border-radius: 6px !important;
  position: $positionFixed !important;
  top: 10%;
  left: 35%;
  z-index: 999;
}

.error-confirmation-container img {
  width: 6%;
}

.error-confirmation-container p {
  width: 70%;
}

#divToastConfirmation {
  position: fixed !important;
  top: 8% !important;
  z-index: 9;
}
.toast-confirmation {
  flex-direction: column;
  align-items: flex-start;
  padding: 6px;
  background-color: #fcfcfc !important;
  border: 2px solid #5268af !important;
  border-radius: 6px !important;
  color: #5268af !important;
  .toast-header {
    width: 100%;
    img {
      margin-right: 8px;
    }
  }
}

@media screen and (min-width: 0px) and (max-width: 575px) {
  #divToastConfirmation {
    position: fixed !important;
    top: 8% !important;
    left: 50% !important;
  }

  .toast-confirmation {
    width: 285px !important;
  }
}

/* ////////////////////////////////// */

/* ALERT BORRAR PRESUPUESTO  */

.alert-delete-budget {
  width: 100%;
  height: 100%;
  color: #5268af !important;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: #fcfcfc !important;
  border: 2px solid #5268af !important;
  border-radius: 12px !important;
  top: 10%;
  right: 25%;
  z-index: 999;
  box-shadow: 0px 10px 15px -3px rgba(154, 159, 176, 0.2),
    0px 4px 6px -2px rgba(154, 159, 176, 0.1);

  .header-alert-budget {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0px 0px 16px;
    border-bottom: 1px solid #5268af;
    font-weight: 400;
    font-size: 20px;
    line-height: 19px;
    .closedAlert {
      background-color: unset;
      border: none;
      padding-right: 0;
      filter: invert(40%) sepia(65%) saturate(438%) hue-rotate(188deg)
        brightness(89%) contrast(91%);
    }
    p {
      margin: 0;
    }
  }

  .header-alert-confirmation {
    padding-bottom: 4px !important;
  }

  .divAlertConfirmation {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    align-self: stretch;
    line-height: 19px;
    color: #0e0f11;
  }
}

.modalDeleteBudget {
  display: flex;
  justify-content: flex-end !important;
  align-items: flex-end !important;
  flex-direction: row !important;
}

.modalDeleteSize div.modal-content {
  border-radius: 30px !important;
  border: none;
}
.modal-backdrop.show {
  opacity: 0.1 !important;
}
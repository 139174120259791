.sidebar {
  position: sticky;
  top: 0;
  width: 80px;
  height: $height100vh;
  background-color: $colorPrimaryLightModeBase-1;
  transition-duration: 0.3s;
  .iconSidebar {
    padding-left: 10px !important;
  }
  @include dFlexColumnBetweenCenter;
  h5 {
    display: $globalNone;
  }
  .logoTypographic {
    display: $globalNone;
  }
  .sidebarArrow {
    display: $globalNone;
  }
}

.sidebarOpen {
  position: sticky;
  top: 0px;
  width: 220px;
  height: $height100vh;
  background-color: $colorPrimaryLightModeBase-1;
  transition-duration: 0.3s;
  font-family: $robotoRegular;
  .iconSidebar {
    padding-left: 16px !important;
  }
  @include dFlexColumnBetween;
  .userButton {
    background-color: $colorPrimaryLightModeBase !important;
    border-color: $colorPrimaryLightModeBase !important;
  }
  .sidebarTop {
    @include dFlexBaselineEvenly;
    .sidebarArrow {
      margin-left: 2em !important;
      cursor: pointer;
    }
  }
}

.NavbarMain {
  background-color: #5268af;
}

.offCanvasMain {
  width: 100% !important;
  height: 486px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.userButton.btn-primary {
  color: $colorBasicWhite;
  background-color: $globalTransparent !important;
  border-color: $globalTransparent !important;
}

.userButtonNavbar {
  background-color: $globalTransparent !important;
  border: $globalNone !important;
}

.logoIcon {
  margin-top: 20px;
  text-align: $alignCenter;
}

.sidebarArrowRight {
  margin-left: 1em;
  // margin-top: 1.5em;
  cursor: pointer;
}

.linksTop {
  @include dFlexColumn;
  margin-top: 30px;
  a {
    text-decoration: $globalNone;
  }
}

.linksTop2 {
  @include dFlexColumn;
  margin-top: 0px;
  a {
    text-decoration: $globalNone;
  }
}

.linksDown {
  a {
    text-decoration: $globalNone;
  }
}

button.userButton.userButtonNavbar {
  background-color: $globalTransparent !important;
  border-color: $globalTransparent !important;
}

.userButton:focus {
  box-shadow: $globalUnset !important;
}

.userImage {
  width: 45px;
  height: 45px;
  object-fit: cover;
}

.userOpen {
  display: flex;
  padding-top: 5px;
}

.userData {
  @include dFlexAlingnCenter;
  .linkNotStyle {
    text-decoration: none;
  }
}

.LinkUser {
  color: #0e0f11 !important;
  text-decoration: none;
  h5 {
    font-size: 16px;
  }
}

.linksTopAzul {
  @include dFlexColumn;
  margin-top: 30px;
  background-color: #f1f2f7;
  a {
    text-decoration: $globalNone;
    p {
      color: #5268af;
    }
    img {
      filter: brightness(0) saturate(100%) invert(38%) sepia(5%) saturate(5356%)
        hue-rotate(188deg) brightness(102%) contrast(87%);
    }
  }
}

.linksTopAzul1 {
  @include dFlexColumn;
  margin-top: 0;
  background-color: #f1f2f7;
  a {
    text-decoration: $globalNone;
    p {
      color: #5268af;
    }
    img {
      filter: brightness(0) saturate(100%) invert(38%) sepia(5%) saturate(5356%)
        hue-rotate(188deg) brightness(102%) contrast(87%);
    }
  }
}

.linksDownAzul {
  background-color: #f1f2f7;
  a {
    text-decoration: $globalNone;
    p {
      color: #5268af;
    }
    img {
      filter: invert(36%) sepia(63%) saturate(504%) hue-rotate(188deg)
        brightness(97%) contrast(84%);
    }
  }
}

.linksprofileAzul {
  background-color: #f1f2f7;
  a {
    text-decoration: $globalNone;

    p {
      color: #5268af;
    }
  }
}

.linksProfileAzul1 {
  a {
    h5 {
      color: #5268af !important;
    }
  }
}

.iconSidebarAzul {
  filter: brightness(0) saturate(100%) invert(38%) sepia(5%) saturate(5356%)
    hue-rotate(188deg) brightness(102%) contrast(87%) !important;
}

.divSidebarArrow {
  margin-bottom: 16px;
}

.upload-container {
  background-color: $colorSecondaryBase !important;
  color: $colorPrimaryLightModeBase-2 !important;
  border-color: $colorSecondaryBase !important;
  width: $width125px;
  cursor: pointer;
  border-radius: 50rem !important;
  font-size: $bodyBold;
  font-family: $robotoBold;
  @include dFlexCenter;
  margin-bottom: 30px;
  padding: 0.375rem 0.75rem;
}

////////////////////COLORES//////////////
$colorPrimaryLightModeBase-1: #fcfcfc;
$colorPrimaryLightModeBase-2: #fafafa;
$colorPrimaryLightModeBase-3: #f8f8f8;
$colorPrimaryLightModeBase: #f4f4f4;
$colorPrimaryLightModeBase1: #dcdcdc;
$colorPrimaryLightMode2Base: #c3c3c3;
$colorPrimaryLightMode3Base: #ababab;
 
$colorPrimaryDarkModeBase-4: #89898a;
$colorPrimaryDarkModeBase-3: #616263;
$colorPrimaryDarkModeBase-2: #3a3a3c;
$colorPrimaryDarkModeBase-1: #272a2f;
$colorPrimaryDarkModeBase: #121315;
$colorPrimaryDarkModeBase1: #0e0f11;
$colorPrimaryDarkModeBase2: #0b0b0d;

$colorSecondaryBase-3: #a9b4d7;
$colorSecondaryBase-2: #8c9aca;
$colorSecondaryBase-1: #6f81bc;
$colorSecondaryBase: #5268af;
$colorSecondaryBase1: #4a5e9e;
$colorSecondaryBase2: #42538c;
$colorSecondaryBase3: #39497b;

$colorAcentoBase-3: #a9d7cd;
$colorAcentoBase-2: #8ccabc;
$colorAcentoBase-1: #6fbcab;
$colorAcentoBase: #52af9a;
$colorAcentoBase1: #4a9e8b;
$colorAcentoBase2: #428c7b;
$colorAcentoBase3: #397b6c;

$colorSemanticGreenBase-1: #a4eb82;
$colorSemanticGreenBase: #49bf31;
$colorSemanticGreenBase1: #2fa423;

$colorSemanticYellowBase-1: #ffde67;
$colorSemanticYellowBase: #ffd141;
$colorSemanticYellowBase1: #ffbb02;

$colorSemanticRedBase-1: #e36f60;
$colorSemanticRedBase: #d13530;
$colorSemanticRedBase1: #b3232a;

$colorSemanticBlueBase-1: #3ba5e6;
$colorSemanticBlueBase: #027ad6;
$colorSemanticBlueBase1: #015eb8;

$colorNeutroGreyBase1: #efefef;
$colorNeutroGreyBase2: #dedede;
$colorNeutroGreyBase3: #d6d6d6;
$colorNeutroGreyBase4: #cecece;
$colorNeutroGreyBase5: #a5a5a5;
$colorNeutroGreyBase6: #7c7c7c;
$colorNeutroGreyBase7: #525252;

$colorBasicWhite: #ffffff;
$colorBasicBlack: #000000;
$colorBasicGrey: #808080;
$colorBasicRed: #ff0000;

$colorBackgroundSidebarStyle: #f1f2f7;

$colorChecked: #33d713;

// COLOR ARLERTS

$colorGreenAlertConfirmation: #cff2be;
$colorRedAlertError: #e9b4ac;

///  FUENTE: ROBOTO  ///
$robotoBold: "Roboto-Bold";
$robotoRegular: "Roboto-Regular";
$robotoMedium: "Roboto-Medium";
$robotoLight: "Roboto-Light";

///   TAMAÑOS FUENTE  ///

// ROBOTO: Headings
$headingH1: 32px;
$headingH2: 24px;
$headingH3: 20px;
$headingH4: 16px;
$headingH5: 14px;
$headingH6: 12px;

// ROBOTO: Body
$detailsRegular: 12px;
$detailsBold: 12px;
$subcontentLight: 14px;
$subcontentRegular: 14px;
$subcontentBold: 14px;
$bodyLight: 16px;
$bodyRegular: 16px;
$bodyMedium: 16px;
$bodyBold: 16px;
$bodyBold14: 14px;

// Width %
$width100: 100%;
$width75: 75%;
$width60: 60%;
$width50: 50%;
$width40: 40%;
$width30: 30%;

// Width px
$width60px: 60px;
$width100px: 100px;
$width120px: 120px;
$width125px: 125px;
$width200px: 200px;
$width350px: 350px;

// Width  vh
$width100vh: 100vh;

// Height
$height100: 100%;

// Height px
$height50px: 50px;
$height60px: 60px;
$height100px: 100px;

// Heigth vh
$height100vh: 100vh;

// Border radius
$borderRadius50: 50%;

// Display
$globalUnset: unset;
$globalNone: none;
$inlineBlock: inline-block !important;
$contents: contents;

// Justify-content
$spaceCenter: center;
$spaceBetween: space-between;
$spaceEvenly: space-evenly;
$spaceAround: space-around;
$flexStart: flex-start;
$flexEnd: flex-end;

// Text-align
$alignCenter: center;
$alignStart: start;
$alignEnd: end;

// Align-items
$alignItemCenter: center;
$alignItemStart: flex-start;
$alignItemEnd: flex-end;

// Position
$positionAbsolute: absolute;
$positionFixed: fixed;
$positionRelative: relative;

// Transparent
$globalTransparent: transparent;

// Padding %
$globalPadding0: 0% !important;

// Padding px
$globalPadding8px: 8px;
$globalPadding24px: 24px;
$globalPadding56px: 56px;
$globalPadding64px: 64px;
$globalPadding72px: 72px;

//VARIABLES GLOBALES PARA DARK MODE
:root {
  --bg-container: #FCFCFC;
  --bg-container-secondary: #5268AF;
  --bg-button: #3E3E42;
  --bg-input: #E4E4E4;
  --bg-mode: #F4F4F4;
  --color-text: #0E0F11;
  --color-text-white: #FCFCFC;
  --color-text-secondary: #808080;
  --color-text-button: #F4F4F4;
  --color-switch: #3E3E42;
  --color-input: #808080;
  --color-shadow: 0px 5.42277px 21.6911px rgba(0, 0, 0, 0.2);
  --color-hr: #DEDEDE;
  --color-border: #DEDEDE;
  --color-border-black: #000000;
}

.text-color {
  color: var(--color-text) !important;
}

.color-text-white {
  color: var( --color-text-white) !important;
}

.color-text-secondary {
  color: var(--color-text-secondary) !important;
}

.bg-input {
  background-color: var(--bg-input) !important;
}

.bg-button {
  background-color: var(--bg-button) !important;
  
}
.bg-buttonEmail {
  background-color: var(--bg-button) !important;
  border-color: var(--bg-button)  !important;

}

.color-text-button {
  color: var(--color-text-button) !important;
}

.bg-mode {
  background-color: var(--bg-mode) !important;
}

.bg-container {
  background-color: var(--bg-container) !important;
  box-shadow: var(--color-shadow) !important;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35) !important;
}

.color-hr {
  background-color: var(--color-hr) !important;
  opacity: unset !important;
}

.color-border {
 border-color: var(--color-border) !important;
}

.color-border-black {
  border-color: var(--color-border-black) !important;
 }

// .color-shadow {
//    box-shadow: var(--color-shadow) !important;
// }



// .text-color2 {
//   color: var(--color2) !important;
// }

// .border-mode {
//   border: 1px solid #808080 !important;
// }

// .hr-color {
//   background-color: var(--hr-color) !important;
//   opacity: unset !important;
// }



.availabilityCheckDarkMode {
    display: flex;
    align-items: center;
//    .check-dark-mode {
//     width: 48px !important;
//     height: 32px !important;
//     box-shadow: none !important;
//    }
   .form-check-input {
    width: 48px !important;
    height: 32px !important;
    box-shadow: none !important;
   }
   span {
margin-left: 8px;
   }
//    .check-dark-mode:checked {

//    }
}
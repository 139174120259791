.normal {
  width: $width60px;
  height: $height50px;
  @include dFlexCenter;
  transition-duration: 0.3s;

  div {
    @include dFlexCenter;
  }

  a :hover {
    color: $colorSecondaryBase;
  }

  [aria-current] {
    position: $positionRelative;
    width: $width60px;
    height: $height50px;
    color: $colorSecondaryBase;
    background-color: $colorBackgroundSidebarStyle;
    @include dFlexAlingnCenter;
    transition-duration: 0.3s;
    path {
      stroke: $colorSecondaryBase;
    }
  }
}

.linkOpen {
  height: $height50px;
  @include dFlexAlingnCenter;
  padding-left: 25px;
  gap: 15px;
  transition-duration: 0.3s;
  text-decoration: none;

  p {
    color: $colorNeutroGreyBase6;
    font-weight: bold;
    font-size: $subcontentBold;

    display: $contents;
  }

  div {
    @include dFlexCenter;
  }

  [aria-current] {
    position: $positionRelative;
    height: $height50px;
    padding-left: 25px;
    @include dFlexAlingnCenter;
    gap: 15px;
    transition-duration: 0.3s;
    background-color: $colorBackgroundSidebarStyle;
    path {
      stroke: $colorSecondaryBase;
    }

    div {
      @include dFlexCenter;
    }

    p {
      color: $colorSecondaryBase;
      font-weight: bold;
      font-size: $subcontentBold;
      padding-bottom: 2px;
    }
  }
}

.budget-info {
  background: $colorAcentoBase !important;
  color: $colorBasicWhite !important;
  border-radius: $borderRadius50;
  width: 25px;
  height: 25px;
  @include dFlexCenter;
  margin: 0;
  font-size: $detailsRegular;
}

@charset "UTF-8";
:root {
  --bg-container: #FCFCFC;
  --bg-container-secondary: #5268AF;
  --bg-button: #3E3E42;
  --bg-input: #E4E4E4;
  --bg-mode: #F4F4F4;
  --color-text: #0E0F11;
  --color-text-white: #FCFCFC;
  --color-text-secondary: #808080;
  --color-text-button: #F4F4F4;
  --color-switch: #3E3E42;
  --color-input: #808080;
  --color-shadow: 0px 5.42277px 21.6911px rgba(0, 0, 0, 0.2);
  --color-hr: #DEDEDE;
  --color-border: #DEDEDE;
  --color-border-black: #000000;
}

.text-color {
  color: var(--color-text) !important;
}

.color-text-white {
  color: var(--color-text-white) !important;
}

.color-text-secondary {
  color: var(--color-text-secondary) !important;
}

.bg-input {
  background-color: var(--bg-input) !important;
}

.bg-button {
  background-color: var(--bg-button) !important;
}

.bg-buttonEmail {
  background-color: var(--bg-button) !important;
  border-color: var(--bg-button) !important;
}

.color-text-button {
  color: var(--color-text-button) !important;
}

.bg-mode {
  background-color: var(--bg-mode) !important;
}

.bg-container {
  background-color: var(--bg-container) !important;
  box-shadow: var(--color-shadow) !important;
}

.color-hr {
  background-color: var(--color-hr) !important;
  opacity: unset !important;
}

.color-border {
  border-color: var(--color-border) !important;
}

.color-border-black {
  border-color: var(--color-border-black) !important;
}

@font-face {
  font-family: "Roboto-Regular";
  src: url(../../fonts/Roboto-Regular.ttf);
}
@font-face {
  font-family: "Roboto-Bold";
  src: url(../../fonts/Roboto-Bold.ttf);
}
@font-face {
  font-family: "Roboto-Light";
  src: url(../../fonts/Roboto-Light.ttf);
}
@font-face {
  font-family: "Roboto-Medium";
  src: url(../../fonts/Roboto-Medium.ttf);
}
.fontFamilyApp {
  font-family: "Roboto-Regular";
}

.h-100vh {
  height: 100vh !important;
}

.marginb-15 {
  margin-bottom: 15px;
}

.padding-box-p {
  padding: 20px 32px 0px 32px;
}

.padding-box-dsh {
  padding: 0px 16px 0px 16px;
}

.textAccepted {
  color: #49bf31;
  font-weight: 700;
}

.textAssign {
  color: #4a5e9e;
  font-weight: 700;
}

.textReject {
  color: #d13530;
  font-weight: 700;
}

.textInfo {
  color: #5268af;
  font-size: 12px;
  font-weight: 400;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
}

.textDisabled {
  color: #dedede;
}

.employed_deleted {
  color: #ff0000;
}

.borderLeft0 {
  border-left: none;
}

.borderRight0 {
  border-right: none !important;
}

.fWrapNone {
  flex-wrap: unset !important;
}

.fWrap {
  flex-wrap: wrap !important;
}

.minInput {
  min-width: 120px !important;
}

.body {
  height: 100vh;
}

.text-error {
  color: #dc3545;
  font-size: 14px;
  font-weight: 600;
  margin-left: 5px;
}

/* VIEWPORT */
.totalHeight {
  height: 100vh;
}

.totalWidth {
  width: 100vh;
}

/* - FONDO LIGHTMODE */
.lightBackground {
  background-color: #fcfcfc;
}

.minHeightBudget {
  min-height: 100vh;
}

.greyBackground {
  background-color: #f4f4f4;
}

.lightBlueBackground {
  background-color: #5268af;
}

.bg-badge {
  background-color: #5268af !important;
}

/* TÍTULOS */
.greyText {
  color: #7c7c7c !important;
}

.pText {
  color: #42538c;
}

.headingH1 {
  font-size: 32px;
  font-family: "Roboto-Bold";
}

.skin-tone-image img {
  width: 180px;
  margin-left: 10px; /* Puedes ajustar el valor según tus necesidades */
}

.headingH2 {
  font-size: 24px;
  font-family: "Roboto-Bold";
  text-align: left;
}

.headingH3 {
  font-size: 20px;
  font-family: "Roboto-Bold";
}

.headingH4 {
  font-size: 16px;
  font-family: "Roboto-Bold";
}

.headingH5 {
  font-size: 14px;
  font-family: "Roboto-Bold";
}

.headingH6 {
  font-size: 12px;
  font-family: "Roboto-Bold";
}

/* BODY FONT  */
.bodyDetailsRegular {
  font-size: 12px;
  font-family: "Roboto-Regular";
}

.bodyDetailsBold {
  font-size: 12px;
  font-family: "Roboto-Bold";
}

.bodyeasycontentLight {
  font-size: 14px;
  font-family: "Roboto-Light";
}

.bodySubcontentLight {
  font-size: 14px;
  font-family: "Roboto-Light";
}

.bodySubcontentRegular {
  font-size: 14px;
  font-family: "Roboto-Regular";
}

.bodySubcontentBold {
  font-size: 14px;
  font-family: "Roboto-Bold";
}

.bodyLight {
  font-size: 16px;
  font-family: "Roboto-Light";
}

.bodyRegular {
  font-size: 16px !important;
  font-family: "Roboto-Regular";
}

.bodyMedium {
  font-size: 16px;
  font-family: "Roboto-Medium";
}

.bodyBold {
  font-size: 16px;
  font-family: "Roboto-Bold";
}

.bodyBold14 {
  font-size: 14px;
  font-family: "Roboto-Bold";
}

.share {
  font-size: 14px;
  font-family: "Roboto-Bold";
  cursor: pointer;
}

.share:hover {
  color: #42538c;
}

.topContent,
.topContentLogin {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
  padding-top: 3em;
}

.topContentLogin {
  margin-bottom: 3em;
}

.formData {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* /////COLOR SPINNER TODA LA APP// */
.colorSpinner {
  color: white !important;
}

/* /////POSICION Y COLOR SPINNER CONFIGURA ASISTENTE// */
.color-position-spinner {
  position: relative !important;
  top: 19px !important;
  left: 19px !important;
  color: #5268af !important;
}

/*  RESPONSIVE  */
@media screen and (max-width: 413px) {
  .confirmation-container,
  .error-confirmation-container {
    width: 85% !important;
    left: 7.2% !important;
  }
  .topContent,
  .topContentLogin {
    flex-direction: column;
    padding-left: 0.8em;
    padding-right: 0.8em;
  }
  .buttonMarginLoginRegister {
    margin-top: 2em;
  }
  .login-input {
    display: flex;
    width: 109%;
  }
}
.asignedCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* ////ASIGNAR ARTISTA BUDGETBUTTON Y BUDGETACTIONS/////*/
@media screen and (max-width: 575px) {
  .displayButtonAsignedArtist {
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }
  .asignedArtistTextCenter {
    text-align: center;
  }
}
@media screen and (min-width: 576px) and (max-width: 652px) {
  .centralBoxColunmAsignedArtist {
    padding-left: 19%;
  }
  .asignedArtistTextCenter {
    text-align: center;
  }
}
/* ///////////////////////////////// */
@media screen and (min-width: 414px) {
  .topContent,
  .topContentLogin {
    justify-content: space-around;
    padding-left: 0.45em;
    padding-right: 0.6em;
  }
  .confirmation-container,
  .error-confirmation-container {
    width: 86% !important;
    left: 7% !important;
  }
}
@media screen and (max-width: 576px) {
  .centralBox {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 56px;
    padding-bottom: 56px;
  }
}
@media screen and (min-width: 576px) {
  .confirmation-container {
    width: 75% !important;
    left: 12.5% !important;
  }
  .centralBox {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .topContent {
    justify-content: space-around;
    padding-left: 0em;
    padding-right: 0em;
  }
  .topContentLogin {
    margin-bottom: 11em;
  }
  .login-input {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .centralBox {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .scroll-container {
    position: absolute;
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .scroll-position {
    position: relative;
    overflow-x: hidden !important;
  }
  .confirmation-container {
    left: 14% !important;
  }
  .error-confirmation-container {
    width: 75% !important;
    left: 11.1%;
  }
  .blockLogin {
    width: 75%;
  }
  .login-input {
    width: 107.3%;
  }
}
@media screen and (min-width: 992px) {
  .centralBox {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .confirmation-container {
    width: 62% !important;
    left: 21% !important;
  }
  .error-confirmation-container {
    width: 65% !important;
    left: 16.5%;
  }
  .login-input {
    width: 106%;
  }
}
@media screen and (min-width: 1200px) {
  .centralBox {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .centralBoxColunmAsignedArtist {
    align-items: flex-end;
  }
  .confirmation-container {
    width: 52% !important;
    left: 25% !important;
  }
  .error-confirmation-container {
    width: 55% !important;
    left: 21.5%;
  }
  .login-input {
    width: 104%;
  }
}
@media screen and (min-width: 1400px) {
  .confirmation-container {
    width: 45% !important;
    left: 26%;
  }
  .confirmation-container {
    width: 44% !important;
    left: 28% !important;
  }
  .error-confirmation-container {
    width: 45% !important;
    left: 28.5%;
  }
  .login-input {
    width: 104%;
  }
}
@media screen and (min-width: 1600px) {
  .confirmation-container {
    width: 39% !important;
    left: 28.5% !important;
  }
  .error-confirmation-container {
    width: 40% !important;
    left: 32.5%;
  }
  .login-input {
    width: 102.5%;
  }
}
@media screen and (min-width: 1800px) {
  .confirmation-container {
    width: 32% !important;
    left: 32.5% !important;
  }
  .error-confirmation-container {
    width: 35% !important;
    left: 32.5%;
  }
  .login-input {
    width: 102.5%;
  }
}
/* ALERT CONFIRMACION FORMULARIO CONTACTO */
.confirmation-container {
  width: 24%;
  height: auto;
  color: #0e0f11 !important;
  background-color: #cff2be !important;
  border: 2px solid #49bf31 !important;
  border-radius: 6px !important;
  position: fixed !important;
  top: 10%;
  left: 35%;
  z-index: 999;
}
.confirmation-container .divAlertConfirmation {
  display: flex;
  align-items: center;
  padding: 1em;
  padding-bottom: 0%;
  padding-left: 0%;
}
.confirmation-container .divAlertConfirmation .closedAlert {
  background-color: unset;
  border: none;
  position: absolute;
  top: -3px;
  right: -4px;
}

.sharedBody {
  width: 100%;
}

.alert-confirmation-budget {
  width: 100%;
  height: 100%;
  color: #5268af !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  background-color: #fcfcfc !important;
  border: 2px solid #5268af !important;
  border-radius: 12px !important;
  top: 10%;
  right: 25%;
  z-index: 99999;
  box-shadow: 0px 10px 15px -3px rgba(154, 159, 176, 0.2), 0px 4px 6px -2px rgba(154, 159, 176, 0.1);
}
.alert-confirmation-budget .header-alert-budget {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 0px 16px;
  border-bottom: 1px solid #5268af;
  font-weight: 400;
  font-size: 20px;
  line-height: 19px;
}
.alert-confirmation-budget .header-alert-budget .closedAlert {
  background-color: unset;
  border: none;
  padding-right: 0;
  filter: invert(40%) sepia(65%) saturate(438%) hue-rotate(188deg) brightness(89%) contrast(91%);
}
.alert-confirmation-budget .header-alert-budget p {
  margin: 0;
}
.alert-confirmation-budget .header-alert-confirmation {
  padding-bottom: 4px !important;
}
.alert-confirmation-budget .divAlertConfirmation {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  align-self: stretch;
  line-height: 19px;
  color: #0e0f11;
}

/* ////////////////////////////////// */
/* ALERT ERROR CONFIRMACION FORMULARIO CONTACTO */
.error-confirmation-container {
  width: 35% !important;
  color: #0e0f11 !important;
  background-color: #e9b4ac !important;
  border: 2px solid #d13530 !important;
  border-radius: 6px !important;
  position: fixed !important;
  top: 10%;
  left: 35%;
  z-index: 999;
}

.error-confirmation-container img {
  width: 6%;
}

.error-confirmation-container p {
  width: 70%;
}

#divToastConfirmation {
  position: fixed !important;
  top: 8% !important;
  z-index: 9;
}

.toast-confirmation {
  flex-direction: column;
  align-items: flex-start;
  padding: 6px;
  background-color: #fcfcfc !important;
  border: 2px solid #5268af !important;
  border-radius: 6px !important;
  color: #5268af !important;
}
.toast-confirmation .toast-header {
  width: 100%;
}
.toast-confirmation .toast-header img {
  margin-right: 8px;
}

@media screen and (min-width: 0px) and (max-width: 575px) {
  #divToastConfirmation {
    position: fixed !important;
    top: 8% !important;
    left: 50% !important;
  }
  .toast-confirmation {
    width: 285px !important;
  }
}
/* ////////////////////////////////// */
/* ALERT BORRAR PRESUPUESTO  */
.alert-delete-budget {
  width: 100%;
  height: 100%;
  color: #5268af !important;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: #fcfcfc !important;
  border: 2px solid #5268af !important;
  border-radius: 12px !important;
  top: 10%;
  right: 25%;
  z-index: 999;
  box-shadow: 0px 10px 15px -3px rgba(154, 159, 176, 0.2), 0px 4px 6px -2px rgba(154, 159, 176, 0.1);
}
.alert-delete-budget .header-alert-budget {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 0px 16px;
  border-bottom: 1px solid #5268af;
  font-weight: 400;
  font-size: 20px;
  line-height: 19px;
}
.alert-delete-budget .header-alert-budget .closedAlert {
  background-color: unset;
  border: none;
  padding-right: 0;
  filter: invert(40%) sepia(65%) saturate(438%) hue-rotate(188deg) brightness(89%) contrast(91%);
}
.alert-delete-budget .header-alert-budget p {
  margin: 0;
}
.alert-delete-budget .header-alert-confirmation {
  padding-bottom: 4px !important;
}
.alert-delete-budget .divAlertConfirmation {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  align-self: stretch;
  line-height: 19px;
  color: #0e0f11;
}

.modalDeleteBudget {
  display: flex;
  justify-content: flex-end !important;
  align-items: flex-end !important;
  flex-direction: row !important;
}

.modalDeleteSize div.modal-content {
  border-radius: 30px !important;
  border: none;
}

.modal-backdrop.show {
  opacity: 0.1 !important;
}

.budget-container {
  padding: 0 !important;
}
.budget-container .row-budget-container {
  margin: 0%;
}
.budget-container .row-budget-container .col-title-budget {
  width: 100% !important;
  padding: 0% !important;
}
.budget-container .row-budget-container .col-title-budget .div-title-budget {
  margin-bottom: 0%;
}
.budget-container .row-budget-container .col-title-budget .div-title-budget .budget-tittle {
  padding: 20px 0px 8px 0px;
}
.budget-container .row-budget-container hr {
  margin-bottom: 24px;
}
.budget-container .div-budget-container {
  display: flex;
  justify-content: center;
  padding-left: 4px;
  padding-right: 4px;
}
.budget-container .div-budget-container .div-generic-budget {
  width: 845px;
  max-width: 845px;
  display: flex;
  justify-content: center;
}
.budget-container .div-budget-container .div-generic-budget .row-generic-budget {
  width: 100%;
}
.budget-container .div-budget-container .col-messages-count {
  display: flex;
  justify-content: center;
  padding: 0% !important;
}
.budget-container .div-budget-container .col-messages-count .budget-message-count {
  background-color: #5268af !important;
  color: #fafafa !important;
  border-color: #5268af !important;
  width: 100%;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 8px;
}
.budget-container .div-budget-container .col-messages-count .budget-message-count p {
  margin: 0;
}
.budget-container .div-budget-container .col-messages-count .budget-message-count p .linkWhite {
  color: white !important;
}
.budget-container .div-budget-container .budget-message-count-limit {
  background-color: #c46464 !important;
  color: #fafafa !important;
  border-color: #5268af !important;
  width: 100%;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 24px;
}
.budget-container .div-budget-container .budget-message-count-limit p {
  margin: 0;
}
.budget-container .div-budget-container .budget-message-count-limit p .linkWhite {
  color: white !important;
}
.budget-container .div-budget-container .colBudgetAccordion {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 24px !important;
  padding: 0% !important;
}
.budget-container .div-budget-container .colBudgetAccordion .div-buttons-refresh-delete {
  width: 100%;
  max-width: 845px;
  display: flex;
  justify-content: space-between;
}
.budget-container .div-budget-container .colBudgetAccordion .div-buttons-refresh-delete .custom-button-download,
.budget-container .div-budget-container .colBudgetAccordion .div-buttons-refresh-delete .button-refresh,
.budget-container .div-budget-container .colBudgetAccordion .div-buttons-refresh-delete .button-delete {
  border: none;
  display: flex;
  align-items: center;
  border-radius: 50px;
  padding: 6px;
}
.budget-container .div-budget-container .colBudgetAccordion .div-buttons-refresh-delete .custom-button-download .text-boton-download,
.budget-container .div-budget-container .colBudgetAccordion .div-buttons-refresh-delete .custom-button-download .text-boton-refresh,
.budget-container .div-budget-container .colBudgetAccordion .div-buttons-refresh-delete .custom-button-download .text-boton-delete,
.budget-container .div-budget-container .colBudgetAccordion .div-buttons-refresh-delete .button-refresh .text-boton-download,
.budget-container .div-budget-container .colBudgetAccordion .div-buttons-refresh-delete .button-refresh .text-boton-refresh,
.budget-container .div-budget-container .colBudgetAccordion .div-buttons-refresh-delete .button-refresh .text-boton-delete,
.budget-container .div-budget-container .colBudgetAccordion .div-buttons-refresh-delete .button-delete .text-boton-download,
.budget-container .div-budget-container .colBudgetAccordion .div-buttons-refresh-delete .button-delete .text-boton-refresh,
.budget-container .div-budget-container .colBudgetAccordion .div-buttons-refresh-delete .button-delete .text-boton-delete {
  margin: 0;
  padding-left: 6px;
}
.budget-container .div-budget-container .colBudgetAccordion .div-buttons-refresh-delete .custom-button-download {
  background-color: #5268af;
}
.budget-container .div-budget-container .colBudgetAccordion .div-buttons-refresh-delete .custom-button-download img {
  height: 32px;
  width: 32px;
  margin-left: 3px;
}
.budget-container .div-budget-container .colBudgetAccordion .div-buttons-refresh-delete .button-refresh {
  background-color: #52af9a;
}
.budget-container .div-budget-container .colBudgetAccordion .div-buttons-refresh-delete .button-delete {
  background-color: #d22828;
  margin-left: 8px;
  padding: 8px;
}

.filterButton {
  background-color: #e6e6e6 !important;
  border-color: #e6e6e6 !important;
}

.div-check-delete-budget .form-check-input-checked {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  vertical-align: top;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #33d713 !important;
  appearance: none;
  background-color: #33d713 !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e");
}

.filterButton:focus {
  box-shadow: unset !important;
}

.div-check-delete-budget .form-check-input {
  margin-top: 0% !important;
  width: 24px;
  height: 24px;
  border: 2px solid;
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
}

.form-check-input:checked {
  background-color: #33d713 !important;
  border-color: #33d713 !important;
}

.accordion-budgets {
  padding: 0% !important;
}
.accordion-budgets .style-acordion-buttons {
  margin-bottom: 24px;
  border: none;
  border-radius: 6px !important;
  background-color: transparent;
}
.accordion-budgets .accordionEnabled {
  border-radius: 6px;
}

.col-input-search-select {
  margin-bottom: 24px;
  padding: 0% !important;
}
.col-input-search-select .div-input-search-select {
  width: 100%;
  display: flex;
}
.col-input-search-select .div-input-search-select .div-input-search {
  width: 70%;
  height: 44px;
}
.col-input-search-select .div-input-search-select .div-input-search img {
  position: absolute;
  transform: translateY(50%) translateX(60%);
}
.col-input-search-select .div-input-search-select .div-input-search input {
  width: 100%;
  height: 100%;
  padding-left: 45px;
  border: none;
  border-radius: 5px;
}
.col-input-search-select .div-input-search-select .div-input-select {
  width: 30%;
  height: 44px;
  padding-right: 8px;
}
.col-input-search-select .div-input-search-select .div-input-select select {
  width: 100%;
  height: 100%;
  margin-left: 8px;
  padding-left: 8px;
  border: none;
  border-radius: 5px;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='gray' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 11px center;
  background-size: 1em;
}

.colorButtonPendingBudget {
  background-color: #e8db6b !important;
  color: black !important;
}

.colorButtonAcceptedBudget {
  background-color: #6be377 !important;
  color: black !important;
}

.colorButtonClientBudget {
  background-color: #64cee5 !important;
  color: black !important;
}

.colorButtonDiscardedBudget {
  background-color: #cccccc !important;
  color: black !important;
}

.stylesButtonBudget {
  width: 100px;
  height: 25px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border: none !important;
  border-radius: 0.3em !important;
  font-size: 14px !important;
  padding: 6px 4px !important;
}

.stylesButtonBudget:after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.badgeGreen {
  padding: 8px;
  background-color: #2fa423;
  color: #ffffff;
}

.badgeOrange {
  padding: 8px;
  background-color: #ffbb02;
  color: #ffffff;
}

.badgeRed {
  padding: 8px;
  background-color: #d13530;
  color: #ffffff;
}

.badgePurple {
  padding: 8px;
  background-color: #5268af;
  color: #ffffff;
}

.requestStatusDelete {
  display: flex;
  align-items: center;
  width: 32%;
}

.iconTrash {
  padding-right: 8px;
}

.trashButton {
  display: flex;
  align-items: flex-start;
  background-color: transparent !important;
  color: #89898a !important;
  border-color: transparent !important;
  box-shadow: unset !important;
}

.div-button-styles-budgets {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  margin-bottom: 24px;
}
.div-button-styles-budgets .div-imagen-budget {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 20%;
}
.div-button-styles-budgets .div-imagen-budget .imageDataBudget {
  width: 96px;
  height: 96px;
  display: flex;
  align-items: center;
  margin: auto;
  margin-right: 16px;
  margin-left: 26px;
  border-radius: 6px;
}
.div-button-styles-budgets .div-imagen-budget .imageDataBudget .imagen-default {
  width: 96px;
  height: 96px;
  object-fit: cover;
  border-radius: 6px;
  border: 1px solid;
}
.div-button-styles-budgets .div-imagen-budget .imageDataBudget .imageCarouselBudget {
  width: 96px;
  height: 96px;
  object-fit: cover;
  border-radius: 6px;
}
.div-button-styles-budgets .div-info-budget {
  width: 80%;
}
.div-button-styles-budgets .div-info-budget p {
  margin: 0%;
  word-break: break-all;
}
.div-button-styles-budgets .div-info-budget .textBold {
  font-weight: bold;
}
.div-button-styles-budgets .div-info-budget .div-info-drop {
  display: flex;
  justify-content: space-between;
}
.div-button-styles-budgets .div-info-budget .div-description-service {
  display: flex;
  align-items: center;
}
.div-button-styles-budgets .div-info-budget .div-description-service .description-service {
  width: 330px;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  font-size: 14px;
}

.accordion-budgets .div-accordion-body-budgets {
  padding: 16px 24px;
  border-radius: 6px;
}
.accordion-budgets .div-accordion-body-budgets .div-info-budgets {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.accordion-budgets .div-accordion-body-budgets .div-info-budgets .div-image-info-budget {
  width: 290px;
  height: 290px;
}
.accordion-budgets .div-accordion-body-budgets .div-info-budgets .div-image-info-budget .imagen-default,
.accordion-budgets .div-accordion-body-budgets .div-info-budgets .div-image-info-budget .image-info-budget {
  width: 100%;
  height: 100%;
  border: 1px solid grey;
  border-radius: 6px;
  object-fit: cover;
  object-position: center;
  cursor: zoom-in !important;
}
.accordion-budgets .div-accordion-body-budgets .div-info-budgets .div-image-info-budget .image-info-budget {
  width: 290px;
  height: 290px;
  border: none;
}
.accordion-budgets .div-summary-budget {
  margin-top: 16px;
  display: flex;
}
.accordion-budgets .div-summary-budget .div-icons-text-info-budgets {
  display: flex;
  margin-bottom: 8px;
}
.accordion-budgets .div-summary-budget .div-icons-text-info-budgets .margin-icon-info {
  margin-left: 2px;
}
.accordion-budgets .div-summary-budget .div-icons-text-info-budgets .margin-icon-info5 {
  margin-left: 5px;
}
.accordion-budgets .div-summary-budget .div-icons-text-info-budgets .margin-left-text-bodypart {
  margin-left: 13px;
}
.accordion-budgets .div-summary-budget .div-icons-text-info-budgets .margin-left-text-city {
  margin-left: 3px;
}
.accordion-budgets .div-summary-budget .div-icons-text-info-budgets p,
.accordion-budgets .div-summary-budget .div-icons-text-info-budgets span {
  margin: 0%;
  padding-left: 8px;
}
.accordion-budgets .div-summary-budget .div-size-icon-text,
.accordion-budgets .div-summary-budget .div-body-part-icon-text,
.accordion-budgets .div-summary-budget .div-color-icon-text {
  display: flex;
  margin-bottom: 10px;
}
.accordion-budgets .div-summary-budget .div-text-example-mock {
  width: 100%;
  max-width: 290px;
}

.icon-with-margin {
  margin-right: 10px;
}

.div-actions-budget {
  width: 100%;
}
.div-actions-budget .div-actions-pending {
  padding: 0px 0px 0px 16px;
}
.div-actions-budget .div-actions-pending .div-mail-phone {
  width: 100%;
  border-bottom: 1px solid;
}
.div-actions-budget .div-actions-pending .div-mail-phone p {
  margin: 0%;
  word-break: break-all;
}
.div-actions-budget .div-actions-pending .div-description-budget {
  margin-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid;
}
.div-actions-budget .div-actions-pending .div-send-budget {
  margin-top: 16px;
}
.div-actions-budget .div-actions-pending .div-send-budget .text-break {
  word-break: break-all;
}
.div-actions-budget .div-actions-pending .div-inpusts-price-budget {
  display: flex;
  justify-content: space-between;
}
.div-actions-budget .div-actions-pending .div-inpusts-price-budget .div-input-from,
.div-actions-budget .div-actions-pending .div-inpusts-price-budget .div-input-to {
  width: 50%;
}
.div-actions-budget .div-actions-pending .div-inpusts-price-budget .div-input-from .form-control,
.div-actions-budget .div-actions-pending .div-inpusts-price-budget .div-input-to .form-control {
  border: none;
}
.div-actions-budget .div-actions-pending .div-inpusts-price-budget .div-input-from input[type=number]::-webkit-inner-spin-button,
.div-actions-budget .div-actions-pending .div-inpusts-price-budget .div-input-from input[type=number]::-webkit-outer-spin-button,
.div-actions-budget .div-actions-pending .div-inpusts-price-budget .div-input-to input[type=number]::-webkit-inner-spin-button,
.div-actions-budget .div-actions-pending .div-inpusts-price-budget .div-input-to input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.div-actions-budget .div-actions-pending .div-inpusts-price-budget .div-input-to {
  margin-left: 4px;
}
.div-actions-budget .div-actions-pending .div-textarea-budget {
  margin-top: 16px;
}
.div-actions-budget .div-actions-pending .div-textarea-budget .form-control {
  border: none;
}

.btnDenegedSmall,
.btnAcceptSmall {
  width: 100%;
}

.ModaImgBudgetlHeight .divImgModalBudget .modalImgBudgetPosition {
  position: absolute;
  right: 0%;
  border: none;
  z-index: 999;
}
.ModaImgBudgetlHeight .divImgModalBudget .modalImgBudgetPosition button {
  background-color: white;
  border-radius: 50px;
  border: 3px solid black !important;
}
.ModaImgBudgetlHeight .divImgModalBudget img {
  width: 100% !important;
  object-fit: contain !important;
  max-height: 90vh;
}

.modal-zoom-images .modal-content {
  background-color: rgba(250, 239, 239, 0.0784313725) !important;
}
.modal-zoom-images .modal-content .imageCarouselBudgetModal {
  max-height: 90vh;
  object-fit: contain;
  object-position: center;
  width: 100%;
}
.modal-zoom-images .modal-content .modal-header button {
  background-color: white;
  opacity: unset !important;
  margin-right: 0px !important;
}

.carousel-control-prev,
.carousel-control-next {
  opacity: unset !important;
}
.carousel-control-prev img,
.carousel-control-next img {
  width: 32px;
}

.dropdown-toggle::after {
  display: none !important;
}

.accordion-button {
  border-radius: 6px !important;
}

.accordion-button:not(.collapsed) {
  color: #000000 !important;
  background-color: #f5f5f5 !important;
}

.accordion-button:not(.collapsed)::after {
  display: none;
}

.accordion-button::after {
  display: none;
}

.accordion-button:focus {
  background-color: #f5f5f5 !important;
  box-shadow: unset !important;
  border: 1px solid #8ccabc !important;
}

.requestAnswerd {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.height100 {
  height: 100%;
}

.accordionDataDiv {
  border-radius: 10px;
  padding: 16px;
  background-color: white;
}

.requestArtist {
  margin-bottom: 16px;
}

.requestArtistButton {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #0e0f11 !important;
  display: flex;
}

.requestArtistButton:focus {
  box-shadow: unset !important;
}

.requestArtistButton p:focus {
  color: #000000 !important;
}

.requestArtistButton:focus {
  color: #5268af !important;
  font-weight: bold;
}

.requestAmount h4 {
  padding-bottom: 8px;
}

.requestAmount .textName2BudgetMock span {
  color: #121315;
  font-weight: bold;
}

.form-control:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.2509803922) !important;
  border-color: transparent !important;
}

.purpleTransparentSmallButton:focus {
  box-shadow: unset !important;
}

.purpleSmallButton:focus {
  background-color: #5268af !important;
  box-shadow: unset !important;
}

.requestPagination {
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
  color: #292929 !important;
}

.requestPagination:focus {
  box-shadow: unset !important;
}

.page-link {
  color: black !important;
}

.requestEmpty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
}

.requestArtistButton {
  width: 80%;
}
.requestArtistButton img {
  width: 65px;
  height: 65px;
  object-fit: cover;
  border-radius: 50% !important;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #5268af !important;
  border-color: #5268af !important;
}

.card-delete-modal {
  width: 35rem;
}

.accordionDisabled {
  width: 100% !important;
  opacity: 0.12;
}

.input2 {
  box-sizing: border-box;
  color: #191919;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.input-icon {
  color: #191919;
  position: absolute;
  width: 100%;
  height: 60px;
}

.grey-budget {
  background-color: #e7e7e7 !important;
}
.grey-budget .greyText {
  color: white !important;
}

@media screen and (max-width: 413px) {
  .row-budget-container .div-title-budget {
    margin: 0% !important;
  }
  .row-budget-container .div-title-budget .budget-tittle {
    padding-top: 65px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin: 0% !important;
    text-align: center;
    padding-bottom: 8px !important;
  }
  .button-refresh,
  .button-delete,
  .custom-button-download {
    width: 35px !important;
    height: 35px !important;
    justify-content: center;
    border-radius: 50% !important;
  }
  .button-refresh .text-boton-refresh,
  .button-refresh .text-boton-download,
  .button-refresh .text-boton-delete,
  .button-delete .text-boton-refresh,
  .button-delete .text-boton-download,
  .button-delete .text-boton-delete,
  .custom-button-download .text-boton-refresh,
  .custom-button-download .text-boton-download,
  .custom-button-download .text-boton-delete {
    display: none;
  }
  .div-input-search-select {
    flex-direction: column;
  }
  .div-input-search-select .div-input-search {
    width: 100% !important;
    margin-bottom: 8px;
  }
  .div-input-search-select .div-input-select {
    width: 100% !important;
    padding-right: 0% !important;
  }
  .div-input-search-select .div-input-select select {
    margin-left: 0% !important;
  }
  .div-button-styles-budgets .div-imagen-budget {
    width: 15% !important;
  }
  .div-button-styles-budgets .div-imagen-budget .imageDataBudget {
    display: none;
  }
  .div-button-styles-budgets .div-imagen-budget .imageDataBudget .imagen-default {
    width: 48px !important;
    height: 48px !important;
  }
  .div-button-styles-budgets .div-imagen-budget .imageCarouselBudget {
    width: 48px !important;
    height: 48px !important;
  }
  .div-button-styles-budgets .div-info-budget {
    width: 85% !important;
  }
  .div-accordion-body-budgets {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .div-accordion-body-budgets .div-info-budgets .div-image-info-budget {
    width: 100% !important;
  }
  .div-accordion-body-budgets .div-info-budgets .div-image-info-budget .carousel {
    width: 100%;
  }
  .div-accordion-body-budgets .div-info-budgets .div-image-info-budget .carousel .image-info-budget {
    width: 100% !important;
  }
  .div-accordion-body-budgets .div-info-budgets .div-image-info-budget .imagen-default {
    width: 100% !important;
  }
  .div-description-service p {
    display: none;
  }
}
@media screen and (min-width: 414px) and (max-width: 575px) {
  .row-budget-container {
    width: 100%;
  }
  .row-budget-container .div-title-budget {
    margin: 0% !important;
  }
  .row-budget-container .div-title-budget .budget-tittle {
    font-size: 24px;
    padding-top: 65px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin: 0% !important;
    margin: 0% !important;
    text-align: center !important;
    padding-bottom: 8px !important;
  }
  .button-refresh,
  .button-delete,
  .custom-button-download {
    width: 35px !important;
    height: 35px !important;
    justify-content: center;
    border-radius: 50% !important;
  }
  .button-refresh .text-boton-download,
  .button-refresh .text-boton-refresh,
  .button-refresh .text-boton-delete,
  .button-delete .text-boton-download,
  .button-delete .text-boton-refresh,
  .button-delete .text-boton-delete,
  .custom-button-download .text-boton-download,
  .custom-button-download .text-boton-refresh,
  .custom-button-download .text-boton-delete {
    display: none;
  }
  .div-input-search-select {
    flex-direction: column;
  }
  .div-input-search-select .div-input-search {
    width: 100% !important;
    margin-bottom: 8px;
  }
  .div-input-search-select .div-input-select {
    width: 100% !important;
    padding-right: 0% !important;
  }
  .div-input-search-select .div-input-select select {
    margin-left: 0% !important;
  }
  .div-description-service p {
    display: none;
  }
  .div-imagen-budget {
    width: 40% !important;
  }
  .div-imagen-budget .imageDataBudget .imagen-default {
    width: 72px !important;
    height: 72px !important;
  }
  .div-imagen-budget .imageCarouselBudget {
    width: 72px !important;
    height: 72px !important;
  }
  .div-info-budget {
    width: 65% !important;
  }
  .imageCarouselBudget {
    width: 72px !important;
    height: 72px !important;
  }
  .div-accordion-body-budgets {
    flex-direction: column;
  }
  .div-accordion-body-budgets .div-info-budgets {
    display: flex;
    align-items: center;
  }
  .div-accordion-body-budgets .div-info-budgets .div-image-info-budget {
    width: 342px !important;
    height: 342px !important;
  }
  .div-accordion-body-budgets .div-info-budgets .div-image-info-budget .imagen-default,
  .div-accordion-body-budgets .div-info-budgets .div-image-info-budget .image-info-budget {
    width: 342px !important;
    height: 342px !important;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .row-budget-container .div-title-budget {
    margin: 0% !important;
  }
  .row-budget-container .div-title-budget .budget-tittle {
    padding-top: 65px !important;
    padding-bottom: 8px !important;
    margin: 0% !important;
    display: flex;
    justify-content: center;
  }
  .div-info-budget {
    width: 65% !important;
  }
}
@media screen and (max-width: 991px) {
  .div-generic-budget {
    width: 100% !important;
    max-width: 480px !important;
  }
  .div-generic-budget .row-generic-budget {
    width: 100%;
  }
  .div-generic-budget .div-button-styles-budgets {
    padding: 10px;
  }
  .div-generic-budget .div-button-styles-budgets .div-imagen-budget {
    width: 35%;
  }
  .div-generic-budget .div-button-styles-budgets .div-info-budget {
    width: 65%;
  }
  .div-generic-budget .div-button-styles-budgets .div-info-budget .div-description-service {
    flex-direction: column;
    align-items: flex-start;
  }
  .div-generic-budget .div-button-styles-budgets .div-info-budget .div-description-service .description-service {
    width: 95%;
  }
  .div-accordion-body-budgets {
    flex-direction: column !important;
  }
  .div-accordion-body-budgets .div-image-info-budget {
    width: 100% !important;
    display: flex;
    justify-content: center;
    height: 250px !important;
  }
  .div-accordion-body-budgets .div-image-info-budget .imagen-default,
  .div-accordion-body-budgets .div-image-info-budget .image-info-budget {
    width: 250px !important;
    height: 250px !important;
    border: 1px solid grey;
    border-radius: 6px;
    object-fit: cover;
    object-position: center;
  }
  .div-accordion-body-budgets .div-image-info-budget .image-info-budget {
    width: 250px !important;
    height: 250px !important;
    border: none;
  }
  .div-accordion-body-budgets .div-summary-budget {
    padding-bottom: 8px;
    border-bottom: 1px solid grey;
  }
  .div-accordion-body-budgets .disabled-information {
    display: none;
  }
  .div-accordion-body-budgets .div-actions-pending {
    margin-top: 16px;
  }
  .div-actions-pending {
    padding-left: 0% !important;
  }
}
.linkShared {
  cursor: pointer;
}

.linkShared:active {
  color: red !important;
}

.bounce {
  animation: 3s linear 0s infinite normal none running animationInkup;
}

@keyframes animationInkup {
  0% {
    transform: translateX(3px) rotate(1deg);
  }
  2.5% {
    transform: translateX(-3px) rotate(-1deg);
  }
  5% {
    transform: translateX(3px) rotate(1deg);
  }
  7.5% {
    transform: translateX(-3px) rotate(-1deg);
  }
  10% {
    transform: translateX(2px) rotate(1deg);
  }
  12.5% {
    transform: translateX(-2px) rotate(-1deg);
  }
  15% {
    transform: translateX(2px) rotate(1deg);
  }
  17.5% {
    transform: translateX(-2px) rotate(-1deg);
  }
  20% {
    transform: translateX(1px) rotate(1deg);
  }
  22.5% {
    transform: translateX(-1px) rotate(-1deg);
  }
  25% {
    transform: translateX(0px) rotate(0deg);
  }
}
.header-be-link {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 5em;
}
.header-be-link .divTextLink .colorLink {
  text-decoration: underline;
  color: #0d6efd;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  text-align: center;
}
.header-be-link button {
  margin-left: 16px;
  height: 36px;
}

.greyTextbe {
  color: #7c7c7c !important;
}

.header-be-link-mock {
  flex-direction: column !important;
  margin-right: 0% !important;
}

.header-be-link {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 5em;
}
.header-be-link .divTextLink .colorLink {
  text-decoration: underline;
  color: #0d6efd;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  text-align: center;
}
.header-be-link button {
  margin-left: 16px;
  height: 36px;
}

.greyTextbe {
  color: #7c7c7c !important;
}

.budgetEmpty {
  border: none;
}
.budgetEmpty h2 {
  margin-top: 8px;
  font-size: 16px;
}
.budgetEmpty .header-be-link {
  padding-right: 5em;
  padding: 16px 8px;
  display: flex;
}
.budgetEmpty .header-be-link button {
  margin-left: 2%;
}

@media screen and (max-width: 413px) {
  .header-be-link .divTextLink {
    width: 100%;
  }
  .header-be-link .divTextLink .colorLink {
    width: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1049px) {
  .header-be-link {
    margin-right: 5em;
  }
}
@media screen and (max-width: 769px) {
  .modal-zoom-images .modal-content .modal-body .custom-carousel a {
    display: none !important;
  }
}
@media screen and (max-width: 1049px) {
  .header-be-link {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .header-be-link .divTextLink {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
  }
}
@media screen and (min-width: 1050px) {
  .header-be-link {
    margin-right: 5em;
  }
  .header-be-link-mock {
    flex-direction: column !important;
  }
  .header-be-link-mock .divTextLink-mock {
    margin-bottom: 8px !important;
  }
}
.budget-elements-container-large {
  display: unset;
}

.budget-elements-container-small {
  display: none;
}

@media (max-width: 990px) {
  .budget-elements-container-large {
    display: none;
  }
  .budget-elements-container-small {
    display: unset;
  }
}
.accordionDataArtist {
  width: 100%;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
  overflow-wrap: break-word;
}

.widthButtonBudgetClient {
  width: auto !important;
}

.accordionDataDivClient {
  border: 2px solid #dedede;
  border-radius: 10px;
  padding: 16px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.button-budget-client {
  display: flex;
  justify-content: flex-end !important;
}

@media screen and (min-width: 0px) and (max-width: 413px) {
  .textClientCenter {
    text-align: center !important;
  }
  .priceBudgetAcceptedClient {
    display: flex;
    flex-direction: column;
  }
  .priceBudgetAcceptedClient {
    margin: 0% !important;
    margin-bottom: 0.5rem !important;
  }
  .button-budget-client {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
@media screen and (max-width: 575px) {
  .requestClientDataClient {
    width: 100%;
    overflow-wrap: break-word;
  }
  .requestStatusClient {
    width: 100%;
  }
  .requestDateClient {
    width: 100%;
    text-align: start;
  }
}
@media screen and (min-width: 576px) {
  .requestClientDataClient {
    width: 38%;
    overflow-wrap: break-word;
  }
  .requestStatusClient {
    width: 23%;
  }
  .requestDateClient {
    width: 23%;
    text-align: end;
  }
  .requestDateClient p {
    margin: 0%;
  }
}
@media screen and (min-width: 576px) and (min-width: 768px) {
  .requestClientDataClient {
    width: 30%;
    overflow-wrap: break-word;
  }
  .requestStatusClient,
  .requestDateClient {
    width: 25%;
  }
}
@media screen and (min-width: 576px) and (min-width: 992px) {
  .requestDateClient {
    width: 20%;
  }
}
@media screen and (min-width: 576px) and (min-width: 1200px) {
  .requestClientDataClient {
    width: 25%;
  }
}
@media screen and (min-width: 576px) and (min-width: 1400px) {
  .requestClientDataClient {
    width: 30%;
  }
  .requestDateClient {
    width: 13%;
  }
}
@media screen and (min-width: 576px) and (min-width: 1600px) {
  .requestDateClient {
    width: 10%;
  }
}
/* -BOTÓN MORADO CORTO */
.purpleSmallButton {
  background-color: #5268af !important;
  color: #fafafa !important;
  border-color: #5268af !important;
  width: 125px;
}

.purpleSmallMediumButton {
  background-color: #5268af !important;
  color: #fafafa !important;
  border-color: #5268af !important;
  width: 198px;
  height: 38px;
  margin-bottom: 30px;
}

.redSmallButton {
  background-color: #d13530 !important;
  color: #fafafa !important;
  border-color: #d13530 !important;
  width: 125px;
}

.purpleSmallButton a {
  text-decoration: none;
}

.purpleSmallButton :hover {
  background-color: #42538c !important;
  border-color: #42538c !important;
}

.purpleSmallButton :focus {
  background-color: #39497b !important;
  border-color: #39497b !important;
}

.purpleSmallButton :disabled {
  background-color: #8c9aca !important;
  border-color: #8c9aca !important;
}

/* BOTÓN MORADO TRANSPARENTE CORTO */
.purpleTransparentSmallButton {
  background-color: transparent !important;
  color: #5268af !important;
  border-color: #5268af !important;
  width: 125px;
}

.purpleSmallButton a {
  text-decoration: none;
}

.purpleTransparentSmallButton :hover {
  background-color: transparent !important;
  border-color: #42538c !important;
}

.purpleTransparentSmallButton :focus {
  background-color: transparent !important;
  border-color: #39497b !important;
}

.purpleTransparentSmallButton :disabled {
  background-color: transparent !important;
  border-color: #8c9aca !important;
}

/* -BOTÓN MORADO MEDIANO */
.purpleMediumButton {
  background-color: #5268af !important;
  color: #fafafa !important;
  border-color: #5268af !important;
  width: 50%;
}

.purpleMediumButton :hover {
  background-color: #42538c !important;
  border-color: #42538c !important;
}

.purpleMediumButton :focus {
  background-color: #39497b !important;
  border-color: #39497b !important;
}

.purpleMediumButton :disabled {
  background-color: #8c9aca !important;
  border-color: #8c9aca !important;
}

/* BOTÓN MORADO TRANSPARENTE MEDIANO */
.purpleTransparentMediumButton {
  background-color: transparent !important;
  color: #5268af !important;
  border-color: #5268af !important;
  width: 50%;
}

.purpleTransparentMediumButton :hover {
  background-color: transparent !important;
  border-color: #42538c !important;
}

.purpleTransparentMediumButton :focus {
  background-color: transparent !important;
  border-color: #39497b !important;
}

.purpleTransparentMediumButton :disabled {
  background-color: transparent !important;
  border-color: #8c9aca !important;
}

/* -BOTÓN MORADO LARGO */
.purpleLargeButton {
  background-color: #5268af !important;
  color: #fafafa !important;
  border-color: #5268af !important;
  width: 100%;
}

.purpleLargeButton :hover {
  background-color: #42538c !important;
  border-color: #42538c !important;
}

.purpleLargeButton :focus {
  background-color: #39497b !important;
  border-color: #39497b !important;
}

.purpleLargeButton :disabled {
  background-color: #8c9aca !important;
  border-color: #8c9aca !important;
}

/* BOTÓN MORADO TRANSPARENTE LARGO */
.purpleTransparentLargeButton {
  background-color: transparent !important;
  color: #5268af !important;
  border-color: #5268af !important;
  width: 100%;
}

.purpleTransparentLargeButton :hover {
  background-color: transparent !important;
  border-color: #42538c !important;
}

.purpleTransparentLargeButton :focus {
  background-color: transparent !important;
  border-color: #39497b !important;
}

.purpleTransparentLargeButton :disabled {
  background-color: transparent !important;
  border-color: #8c9aca !important;
}

/* -BOTÓN VERDE CORTO */
.greenSmallButton {
  background-color: #fafafa !important;
  color: #52af9a !important;
  border-color: #52af9a !important;
  width: 125px;
}

.greenSmallButton :hover {
  background-color: #4a9e8b !important;
  border-color: #4a9e8b !important;
}

.greenSmallButton :focus {
  background-color: #428c7b !important;
  border-color: #428c7b !important;
}

.greenSmallButton :disabled {
  background-color: #8ccabc !important;
  border-color: #8ccabc !important;
}

/* -BOTÓN VERDE MEDIANO */
.greenMediumButton {
  background-color: #fafafa !important;
  color: #52af9a !important;
  border-color: #52af9a !important;
  width: 155px;
}

.greenMediumButton :hover {
  background-color: #4a9e8b !important;
  border-color: #4a9e8b !important;
}

.greenMediumButton :focus {
  background-color: #428c7b !important;
  border-color: #428c7b !important;
}

.greenMediumButton :disabled {
  background-color: #8ccabc !important;
  border-color: #8ccabc !important;
}

/* -BOTÓN VERDE LARGO */
.greenLargeButton {
  background-color: #fafafa !important;
  color: #52af9a !important;
  border-color: #52af9a !important;
  width: 100%;
}

.greenLargeButton :hover {
  background-color: #4a9e8b !important;
  border-color: #4a9e8b !important;
}

.greenLargeButton :focus {
  background-color: #428c7b !important;
  border-color: #428c7b !important;
}

.greenLargeButton :disabled {
  background-color: #8ccabc !important;
  border-color: #8ccabc !important;
}

/* BOTÓN PEQUEÑO BORDE/LETRAS-AZUL BG-TRANSPARENTE */
.blueSmallButton {
  background-color: transparent !important;
  color: #5268af !important;
  border-color: #5268af !important;
  width: 125px;
}

/* -BOTÓN VERDE COPIAR URL */
.greenLargeCopyButton {
  background-color: #52af9a !important;
  color: #ffffff !important;
  border-radius: 22px !important;
  border-color: #52af9a !important;
}

.greenLargeCopyButton:focus {
  box-shadow: none !important;
}

.greenLargeCopyButtonlink {
  width: 150px;
  background-color: #52af9a !important;
  color: #ffffff !important;
  border-radius: 22px !important;
  border-color: #52af9a !important;
}

.noStyle {
  background: none;
  border: 0;
  color: inherit;
  /* cursor: default; */
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
}

.sizeDelete {
  position: absolute;
  top: -46px;
  left: 5px !important;
}
.sizeDelete img {
  width: 12px !important;
  height: 12px !important;
}

/* -BOTÓN ACEPT WHATSAPP BUDGET */
.bottonWhatsApp {
  background-color: #25d366 !important;
  color: white !important;
  margin-bottom: 16px;
  border: none !important;
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.bottonProfile {
  width: 145px;
  height: 40px;
  border-radius: 50px !important;
  border: none !important;
}

.bottonSingup {
  width: 145px;
  height: 40px;
  background-color: #3E3E42 !important;
  border-radius: 50px !important;
  border: none !important;
}

.bottonConfigAssistant {
  width: 145px;
  height: 32px;
  border-radius: 50px !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.bottonDeleteConfigAssistant {
  width: 145px;
  height: 32px;
  border-radius: 50px !important;
  border: 1px solid #adadad !important;
  color: #adadad !important;
  box-shadow: none !important;
  padding: 0 !important;
  background-color: transparent !important;
}

.bottonStripe {
  width: 244px;
  height: 40px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  border: none !important;
  border-radius: 50px !important;
  padding: 0 !important;
}
.bottonStripe .textButtonLeft {
  font-size: 14px;
  margin-bottom: 0;
}
.bottonStripe .textButtonRight {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
}

/* CAJA CENTRAL BLANCA */
.lightCentralBox {
  padding: 24px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

/* CAJA CENTRAL GRIS */
.centralBox {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.d-flex-center {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.centralBoxColunm,
.centralBoxColunmAsignedArtist {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

.widthAsignedArtist {
  width: 65px !important;
}

.f-wrap {
  display: flex;
  flex-wrap: wrap;
}

.cookies-inkup .CookieConsent {
  z-index: 99999 !important;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

@media screen and (max-width: 575px) {
  .cookies-inkup .CookieConsent {
    display: unset !important;
    z-index: 99999 !important;
    display: flex !important;
    flex-direction: column-reverse;
    height: 120px;
  }
  .cookies-inkup .CookieConsent .divButtonCookies {
    position: fixed;
    left: 0px;
    width: 100%;
    bottom: 52px;
  }
  .cookies-inkup .CookieConsent .divTextCookies {
    flex: none !important;
  }
  .modal-dialog {
    margin: 0rem !important;
  }
}
.sizeModalCookiesTemplate01 div.modal-content {
  border: none !important;
}

.modalCookiesOpacity {
  opacity: 0.7 !important;
}

.dashboardBody {
  border-radius: 12px;
}

.dashboardBodyDashboard {
  padding: 24px 0 0 0;
  border-radius: 12px;
  display: flex;
  justify-content: center;
}

.pb-88 {
  padding-bottom: 88px !important;
  display: flex;
  justify-content: center;
}

.dashboardBody2 {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.cardDashboard {
  width: 100%;
  min-height: 25vh !important;
  border-radius: 15px;
  box-shadow: -2px 11px 16px 5px rgba(0, 0, 0, 0.12);
  padding-top: 8px;
}
.cardDashboard p {
  margin-bottom: 0% !important;
}
.cardDashboard .iconRRSS,
.cardDashboard .iconBudget {
  width: 95px;
}
.cardDashboard .iconMailBox {
  width: 85px !important;
}

.dashboard-header h2 {
  padding-left: 1%;
}

.linkDashboard {
  color: unset;
  text-decoration: none;
}

.dash-tutorial {
  background-color: #5268af !important;
  padding: 23px !important;
  color: #ffffff !important;
  display: inline-flex !important;
  display: flex;
  align-items: center;
  border-radius: 5px !important;
  border-color: unset !important;
  width: 100%;
}
.dash-tutorial img {
  margin-right: 16px;
}
.dash-tutorial p {
  margin: unset;
}

.dFlexChangeLanguages {
  display: flex;
  justify-content: flex-end;
  padding-right: 1.5em !important;
}

.dFlexLanguages {
  display: inline-block;
  justify-content: flex-end !important;
}

.changeLanguageES,
.changeLanguageEN {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background-image: url("../../../assets/images/ES.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center !important;
}

.changeLanguageEN {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background-image: url("../../../assets/images/EN.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center !important;
}

.textBtnLanguage {
  background-color: transparent !important;
  border: transparent !important;
  color: black !important;
}

.textBtnLanguage:focus {
  box-shadow: none !important;
  background-size: 100% 2px;
}

.modalDashboard .modal-content {
  background-color: transparent;
  border: none;
}

.tutorial-modal .modal-dialog {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.tutorial-modal .modal-dialog .modal-content {
  height: 95%;
  width: 95%;
}
.tutorial-modal .modal-dialog .modal-content .modal-header {
  border-bottom: unset !important;
}
.tutorial-modal .modal-dialog .modal-content .modal-header button {
  background-color: #dcdcdc;
}
.tutorial-modal .modal-dialog .modal-content .carousel-container {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.tutorial-modal .modal-dialog .modal-content .carousel-container .carousel-inner {
  width: 65%;
}
.tutorial-modal .modal-dialog .modal-content .carousel-container .carousel-inner .carousel-item {
  width: 100%;
}
.tutorial-modal .modal-dialog .modal-content .carousel-container .carousel-inner .carousel-item img {
  margin: auto;
}
.tutorial-modal .modal-dialog .modal-content .carousel-container .carousel-inner .carousel-caption {
  color: #000000;
  position: unset !important;
}
.tutorial-modal .modal-dialog .modal-content .carousel-container .carousel-inner .carousel-caption h3 {
  font-size: 16px;
  font-family: "Roboto-Bold";
}
.tutorial-modal .modal-dialog .modal-content .carousel-container .carousel-inner .carousel-caption p {
  font-size: 14px;
  font-family: "Roboto-Regular";
  color: #7c7c7c;
}
.tutorial-modal .modal-dialog .modal-content .carousel-container .carousel-control-next-icon,
.tutorial-modal .modal-dialog .modal-content .carousel-container .carousel-control-prev-icon {
  height: unset !important;
}
.tutorial-modal .modal-dialog .modal-content .carousel-container .carousel-indicators button {
  background-color: #5268af;
  width: 17px;
  height: 17px;
  border-radius: 50%;
}
.tutorial-modal .modal-dialog .modal-content .input-show-tutorial {
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 2%;
}
.tutorial-modal .modal-dialog .modal-content .input-show-tutorial label {
  margin-left: 1%;
}

.video-tutorial {
  width: 100%;
  margin: auto;
}

.calendar-size {
  width: 100%;
}

.asd {
  width: auto !important;
}

.buttonPCInformation {
  margin-top: 1em;
}

.rowFixedHeaderPc {
  width: 100%;
  position: fixed;
  top: 0px !important;
  background-color: var(--bg-mode);
  padding-top: 20px;
  z-index: 9;
}
.rowFixedHeaderPc hr {
  margin: 0;
}

.rowFixedHeaderPcLink {
  width: 100%;
  position: fixed;
  top: 73px;
  background-color: var(--bg-mode);
  z-index: 9;
}

@media screen and (min-width: 0px) and (max-width: 413px) {
  .dashboardBody {
    margin-bottom: 6em;
    border-radius: 12px;
  }
}
.dashboardBody {
  border-radius: 12px;
}

.rowFixedHeaderPcLink {
  top: 109px !important;
}

@media screen and (min-width: 0px) and (max-width: 455px) {
  .dFlexChangeLanguages {
    justify-content: center;
    padding: 0% !important;
  }
  .dFlexChangeLanguages .divButtonsLanguage .changeLanguageSession {
    margin: 8px !important;
  }
}
@media screen and (max-width: 575px) {
  .cardDashboard {
    width: 100%;
    min-height: 25vh !important;
  }
  img.icon-whatsapp {
    width: 30px !important;
    height: 30px !important;
  }
  .calendar-size {
    width: 100%;
  }
  .video-tutorial {
    width: 53%;
  }
}
@media screen and (max-width: 767px) {
  .rowFixedHeaderPc {
    top: 56px !important;
  }
  .rowFixedHeaderPc .positionButton {
    bottom: 21px;
    position: fixed;
    z-index: 1040;
    width: 225px;
  }
  .rowFixedHeaderPcLink {
    top: 114px;
  }
}
@media screen and (min-width: 768px) {
  .rowFixedHeaderPc {
    top: 20px;
  }
  .rowFixedHeaderPc .header-pc {
    padding-right: 30% !important;
    padding-left: 0% !important;
  }
  .rowFixedHeaderPcLink {
    top: 73px !important;
  }
  .calendar-size {
    width: 100%;
  }
  .video-tutorial {
    width: 53%;
  }
  .cardDashboard {
    max-width: 480px !important;
  }
  .dashboardBodyDashboard {
    padding: 24px 0 0 0;
    border-radius: 12px;
  }
}
@media screen and (min-width: 1195px) {
  .video-tutorial {
    width: 62%;
  }
  .rowFixedHeaderPc .header-pc .positionButton {
    margin-right: 13%;
  }
}
@media screen and (min-width: 1400px) {
  .rowFixedHeaderPc .header-pc .positionButton {
    margin-right: 10%;
  }
}
@media screen and (min-width: 1600px) {
  .rowFixedHeaderPc .header-pc .positionButton {
    margin-right: 10%;
  }
}
.react-calendar {
  width: 100%;
  background: transparent;
  border: none;
  padding: 2%;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar__viewContainer,
.react-calendar__month-view {
  height: 80%;
}

.react-calendar__month-view {
  height: 100%;
}

.react-calendar__month-view > div:first-child {
  height: 100%;
}

.react-calendar__month-view > div > div:first-child {
  height: 100%;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 15%;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #006edc;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

@media screen and (max-width: 413px) {
  .greenMediumButton.btnStartSesion {
    width: 120px !important;
    font-size: 14px;
    margin-top: 0% !important;
  }
  .divStartSesion {
    justify-content: space-evenly;
    display: flex;
    flex-direction: row;
  }
  .instructionsText,
  .formDataPassword {
    width: 77%;
  }
}
@media screen and (min-width: 414px) and (max-width: 575px) {
  .divStartSesion {
    justify-content: space-evenly;
  }
  .greenMediumButton.btnStartSesion {
    width: 125px !important;
    font-size: 14px;
    margin-top: 0% !important;
  }
  .instructionsText,
  .formDataPassword {
    width: 77%;
  }
}
@media screen and (min-width: 576px) {
  .greenMediumButton.btnStartSesion {
    width: 125px !important;
    font-size: 14px;
    margin-top: 0% !important;
  }
  .divStartSesion {
    justify-content: space-evenly;
  }
  .instructionsText,
  .formDataPassword {
    width: 65%;
  }
}
@media screen and (min-width: 768px) {
  .greenMediumButton.btnStartSesion {
    width: 145px !important;
    font-size: 16px;
    margin-top: 0% !important;
  }
  .divStartSesion {
    justify-content: space-evenly;
  }
  .instructionsText {
    width: 60%;
  }
  .formDataPassword {
    width: 59%;
  }
}
@media screen and (min-width: 992px) {
  .instructionsText {
    width: 56%;
  }
  .formDataPassword {
    width: 52.5%;
  }
}
@media screen and (min-width: 1200px) {
  .instructionsText {
    width: 45%;
  }
  .formDataPassword {
    width: 49.4%;
  }
}
@media screen and (min-width: 1400px) {
  .formDataPassword {
    width: 47%;
  }
}
@media screen and (min-width: 1600px) {
  .formDataPassword {
    width: 45%;
  }
}
.employed-direction {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.employed-direction .hrStylePCEmployeds {
  border: 1.2px solid hsl(200, 10%, 50%);
}
.employed-direction .employed-column {
  padding-bottom: 1em;
}
.employed-direction .employed-column .options {
  margin-right: 5px;
}
.employed-direction .employed-column .buttons-delete-employed {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.employed-direction .employed-column .buttons-delete-employed button {
  margin-left: 15px;
}
.employed-direction .employed-column .divIconEmployeds {
  display: flex;
  padding-top: 1em;
  align-items: center;
}
.employed-direction .employed-column .image-employed .options {
  position: absolute;
}
.employed-direction .employed-column .image-employed .button-employed-link {
  background-color: unset !important;
  border-color: unset !important;
  padding: 0 !important;
  border-radius: 50% !important;
}
.employed-direction .employed-column .image-employed .button-employed-link img {
  height: 60px !important;
  object-fit: cover !important;
  width: 60px;
  border-radius: 50% !important;
  margin-right: 16px;
}
.employed-direction .employed-column .image-employed .btn-primary:active:focus {
  box-shadow: unset;
}
.employed-direction .employed-column .image-employed .button-employed-link:focus {
  box-shadow: none !important;
}
.employed-direction .employed-column .text-employed {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  width: 50%;
  text-overflow: " [..]";
}
.employed-direction .employed-column .text-employed p {
  margin-bottom: 0;
}
.employed-direction .employed {
  display: flex;
  align-items: center;
  padding-top: 1em;
  padding-bottom: 1em;
}
.employed-direction .employed .options {
  margin-right: 5px;
}
.employed-direction .employed .buttons-delete-employed {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.employed-direction .employed .buttons-delete-employed button {
  margin-left: 15px;
}
.employed-direction .employed .divIconEmployeds {
  display: flex;
  align-items: center;
}
.employed-direction .employed .image-employed .options {
  position: absolute;
}
.employed-direction .employed .image-employed .button-employed-link {
  background-color: unset !important;
  border-color: unset !important;
  padding: 0 !important;
  border-radius: 50% !important;
}
.employed-direction .employed .image-employed .button-employed-link img {
  height: 60px !important;
  object-fit: cover !important;
  width: 60px;
  border-radius: 50% !important;
  margin-right: 16px;
}
.employed-direction .employed .image-employed .btn-primary:active:focus {
  box-shadow: unset;
}
.employed-direction .employed .image-employed .button-employed-link:focus {
  box-shadow: none !important;
}
.employed-direction .employed .text-employed {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  text-overflow: " [..]";
}
.employed-direction .employed .text-employed p {
  margin-bottom: 0;
}
.employed-direction .employedPC {
  display: flex;
  align-items: center;
  padding-bottom: 1em;
}
.employed-direction .employedPC .options {
  margin-right: 5px;
}
.employed-direction .employedPC .buttons-delete-employed {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.employed-direction .employedPC .buttons-delete-employed button {
  margin-left: 15px;
}
.employed-direction .employedPC .divIconEmployeds {
  display: flex;
  align-items: center;
}
.employed-direction .employedPC .image-employed .options {
  position: absolute;
}
.employed-direction .employedPC .image-employed .button-employed-link {
  background-color: unset !important;
  border-color: unset !important;
  padding: 0 !important;
  border-radius: 50% !important;
}
.employed-direction .employedPC .image-employed .button-employed-link img {
  height: 60px !important;
  object-fit: cover !important;
  width: 60px;
  border-radius: 50% !important;
  margin-right: 16px;
}
.employed-direction .employedPC .image-employed .btn-primary:active:focus {
  box-shadow: unset;
}
.employed-direction .employedPC .image-employed .button-employed-link:focus {
  box-shadow: none !important;
}
.employed-direction .employedPC .text-employed {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  text-overflow: " [..]";
}
.employed-direction .employedPC .text-employed p {
  margin-bottom: 0;
}

.header-employed {
  display: flex;
  flex-direction: column;
}

.no-artist {
  display: flex;
  justify-content: flex-start;
  padding-top: 1em;
  cursor: pointer;
}
.no-artist p {
  margin-top: 0;
  margin-bottom: 1rem;
  padding-top: 0.3em;
}
.no-artist .textAddEmployed {
  text-decoration: underline;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0%;
}
.no-artist img {
  width: 30px;
  height: 30px;
  margin: 0%;
  margin-right: 1em;
}

.no-artist-button {
  text-align: end;
  width: 100%;
  padding: 75px 15px 20px 0;
}

.btn-delete-employed {
  background-color: transparent !important;
  color: #d13530 !important;
  border-color: #d13530 !important;
  width: 85px;
  height: 35px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: unset !important;
}

.btnSendInvited {
  display: flex;
  justify-content: flex-end !important;
}
.btnSendInvited .btnInvited {
  width: 60% !important;
}

@media screen and (min-width: 0px) and (max-width: 342px) {
  .no-artist {
    width: 250px !important;
  }
  .no-artist img {
    width: 30px;
    height: 30px;
    margin: 0%;
    margin-right: 0.5em;
    cursor: pointer;
  }
}
@media screen and (min-width: 0px) and (max-width: 574px) {
  .employed-direction .employed .divIconEmployeds {
    width: 80%;
  }
}
.image-card {
  display: inline-block !important;
  position: relative;
}
.image-card input[type=checkbox][id^=cb] {
  display: none;
}
.image-card label {
  border: 4px solid #ffffff;
  padding: 3px;
  display: block !important;
  position: relative;
  margin: 10px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.image-card label img {
  width: 114px;
  height: 125px;
  transition-duration: 0.2s;
  object-fit: cover;
  transform-origin: 50% 50%;
}
.image-card .options {
  position: absolute;
  top: 8px;
  right: 12px;
  z-index: 1;
  bottom: auto;
  margin-right: 0;
}
.image-card .btn-portfolio {
  top: 15px !important;
  right: 15px !important;
}

.ant-image {
  float: left !important;
  cursor: pointer !important;
  margin: 3px !important;
}

.upload-container {
  background-color: #5268af !important;
  color: #fafafa !important;
  border-color: #5268af !important;
  width: 125px;
  cursor: pointer;
  border-radius: 50rem !important;
  font-size: 16px;
  font-family: "Roboto-Bold";
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  padding: 0.375rem 0.75rem;
}

.image-card {
  display: inline-block !important;
  position: relative;
}
.image-card input[type=checkbox][id^=cb] {
  display: none;
}
.image-card label {
  border: 4px solid #ffffff;
  padding: 3px;
  display: block !important;
  position: relative;
  margin: 10px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.image-card label img {
  width: 114px;
  height: 125px;
  transition-duration: 0.2s;
  object-fit: cover;
  transform-origin: 50% 50%;
}
.image-card .options {
  position: absolute;
  top: 8px;
  right: 12px;
  z-index: 1;
  bottom: auto;
  margin-right: 0;
}
.image-card .btn-portfolio {
  top: 15px !important;
  right: 15px !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.media-library {
  height: 230px;
  overflow: auto;
  display: flex;
  margin: 20px;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.upload-image-preview {
  margin: 20px;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.d-portolio-end {
  display: flex;
  justify-content: flex-end !important;
}

.buttons-actions-invitations {
  padding-top: 2em;
}
.buttons-actions-invitations .blueSmallButton {
  margin-right: 15px;
}

@media screen and (max-width: 414px) {
  .buttons-actions-invitations {
    display: flex;
  }
}
.header-workschedule {
  margin-bottom: 15px;
}

.workschedule-check {
  padding: 0px 0px 16px 0px;
}
.workschedule-check .divWorkSchedule {
  display: flex !important;
}

.workschedule-check .form-check {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
  padding: 0;
  flex-direction: row-reverse;
  font-size: 16px;
  font-family: "Roboto-Bold";
}

.workschedule-check input {
  height: 1.5em;
  width: 4em !important;
}

.workschedule-check .form-check-input:checked {
  background-color: #a9d7cd !important;
  border-color: #a9d7cd !important;
}

.workschedule-check .form-check-input:focus {
  border-color: #a9d7cd;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #a9d7cd;
  background-color: #a9d7cd;
}

.workschedule-check .form-switch .form-check-input:focus {
  background-image: url("../../../assets/images/Frame97.png") !important;
  background-repeat: no-repeat;
  background-size: contain;
}

.workSchedule-checks2 {
  display: flex;
  margin-bottom: 16px;
}

div.workSchedule-time {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  justify-content: center;
  flex-direction: column;
}

.workSchedule-time button {
  box-shadow: none;
}

.workSchedule-time .workSchedule-btn {
  color: #ffffff;
  background-color: #5268af;
  border-radius: 50%;
  border-color: unset;
  width: 30px;
  height: 30px;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  margin-right: 16px;
}

.workSchedule-time .workSchedule-btn-disabled {
  color: #ffffff;
  background-color: #a9b4d7;
  border-radius: 50%;
  border-color: unset;
  width: 30px;
  height: 30px;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  margin-right: 16px;
}

.workSchedule-time .workSchedule-btn-disabled:focus,
.workSchedule-time .workSchedule-btn:focus {
  color: #ffffff;
  background-color: #5268af !important;
  border-color: unset !important;
  box-shadow: none !important;
}

.workSchedule-inputs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.workSchedule-input {
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
}

input[type=time]::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}

.form-time-day {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.form-time-day .workSchedule-time {
  margin-right: 16px;
}

@media screen and (min-width: 0px) and (max-width: 575px) {
  .workSchedule-time {
    align-items: center !important;
    margin-right: 7%;
  }
  .workSchedule-time .workSchedule-btn {
    margin-right: 5%;
  }
  .workSchedule-time .workSchedule-btn-disabled {
    margin-right: 5%;
  }
  .workSchedule-time input {
    padding-left: 4px !important;
    padding-right: 4px !important;
    text-align: -webkit-center;
  }
}
@media screen and (min-width: 0px) and (max-width: 575px) {
  .workschedule-check .divWorkSchedule {
    justify-content: center;
  }
  .workschedule-check .divWorkSchedule .buttonMargin {
    margin-left: 0em !important;
    margin-right: 0.4em !important;
  }
}
.loginTattoo {
  background-image: url("../../../assets/images/img16.svg");
  background-size: cover;
  background-position: center;
}

.blockLogin {
  width: 50%;
  margin-bottom: 2%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.blockLogin input {
  width: 99%;
}

.forgotPassword {
  text-decoration: none;
  align-items: flex-end;
}

.logo-icon-login {
  width: 140px;
  display: flex;
}
.logo-icon-login .logo-text {
  width: 100%;
  height: auto;
}

.divButtonsLanguage {
  display: flex;
  justify-content: center;
}
.divButtonsLanguage .changeLanguageSession {
  width: 130px;
  height: 42px;
  border-radius: 50px;
  margin: 24px;
  margin-top: 0px;
  margin-bottom: 16px;
  border: 1px solid #3a3a3c;
  background-color: transparent !important;
}
.divButtonsLanguage .changeLanguageSession .divButton {
  display: flex;
  justify-content: space-evenly;
}
.divButtonsLanguage .changeLanguageSession .divButton img {
  width: 21.5px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}
.divButtonsLanguage .changeLanguageSession .divButton p {
  margin-bottom: unset;
  color: black;
  font-size: 14px;
}

.changeLanguageSession:focus {
  display: none;
}

.login-input {
  display: flex;
  width: 100% !important;
}
.login-input .eye-login {
  position: relative;
  top: 1px;
  right: 30px;
}
.login-input .eye-login:hover {
  cursor: pointer;
}

.pass-login,
.input-lg {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.pass-login::placeholder {
  width: 80% !important;
}

.btnStartSesion {
  margin-top: 1em;
}

@media screen and (max-width: 413px) {
  .topContentLogin {
    flex-direction: row !important;
    padding-left: 0.5em;
    padding-right: 0.5em;
    margin-bottom: 6em !important;
  }
  .topContentLogin .logo-icon-login img {
    width: 80%;
    padding-left: 0.4em;
  }
  .topContentLogin a.btnSingup {
    padding-right: 0.35em;
  }
  .topContentLogin a.btnSingup .greenSmallButton {
    font-size: 14px !important;
    width: 100px !important;
    margin-top: 0em !important;
  }
  .pass-login {
    width: 99% !important;
    padding-left: 7% !important;
  }
  .blockLogin {
    width: 85%;
    padding-top: 2%;
    margin-bottom: 4%;
  }
  .blockLogin input {
    width: 100%;
  }
  .blockLogin .bodySubcontentBold {
    padding-right: 1%;
  }
  .headingH2 {
    font-size: 20px !important;
  }
}
@media screen and (min-width: 414px) and (max-width: 575px) {
  .topContentLogin {
    margin-bottom: 7em !important;
  }
  .topContentLogin .logo-icon-login img {
    padding-left: 0.15em;
  }
  .topContentLogin a.btnSingup {
    padding-right: 0em;
  }
  .topContentLogin a.btnSingup .greenSmallButton {
    width: 110px !important;
  }
  .pass-login {
    width: 92% !important;
    padding-left: 6.2% !important;
  }
  .blockLogin {
    width: 80%;
    padding-top: 2%;
  }
  .blockLogin .bodySubcontentBold {
    padding-right: 2% !important;
  }
  .headingH2 {
    font-size: 24px;
  }
}
@media screen and (min-width: 576px) {
  .topContentLogin {
    margin-bottom: 7em !important;
    padding-top: 4em;
  }
  .topContentLogin a.btnSingup {
    padding-left: 4.5em;
  }
  .blockLogin {
    width: 80%;
    padding-top: 2%;
  }
  .blockLogin .bodySubcontentBold {
    padding-right: 1.5%;
  }
  .pass-login {
    width: 92% !important;
    padding-left: 6% !important;
  }
}
@media screen and (min-width: 768px) {
  .logo-icon-login img {
    padding-left: 1.1em !important;
  }
  .blockLogin {
    width: 85% !important;
  }
  .blockLogin .bodySubcontentBold {
    padding-right: 1%;
    font-size: 16px !important;
  }
  a.btnSingup {
    padding-right: 1em !important;
    padding-left: 3em !important;
  }
  a.btnSingup .greenSmallButton {
    width: 125px !important;
  }
  .pass-login {
    width: 94.5% !important;
    padding-left: 4.8% !important;
  }
}
@media screen and (min-width: 992px) {
  .logo-icon-login img {
    padding-left: 0.9em !important;
  }
  .blockLogin {
    width: 86.5% !important;
  }
  .blockLogin .bodySubcontentBold {
    padding-right: 1%;
  }
  .pass-login {
    width: 94.6% !important;
    padding-left: 4.1% !important;
  }
  a.btnSingup {
    padding-right: 1em !important;
    padding-left: 8em !important;
  }
}
@media screen and (min-width: 1200px) {
  .bodySubcontentBold {
    padding-right: 0.5%;
  }
  .pass-login {
    width: 94.5% !important;
    padding-left: 4% !important;
  }
  a.btnSingup {
    padding-left: 12em !important;
  }
}
@media screen and (min-width: 1400px) {
  .bodySubcontentBold {
    padding-right: 1.2% !important;
  }
  .pass-login {
    width: 94.6% !important;
    padding-left: 4.1% !important;
  }
  a.btnSingup {
    padding-left: 16.7em !important;
  }
}
@media screen and (min-width: 1600px) {
  .pass-login {
    width: 94.7% !important;
    padding-left: 4.2% !important;
  }
  a.btnSingup {
    padding-left: 20.7em !important;
  }
}
.bg-navbar {
  background-color: #f4f4f4;
}
.bg-navbar .navbarButtons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-light .navbar-toggler {
  border: none !important;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.navBarUser {
  display: flex;
  justify-content: space-between;
}

.divAccountProfile {
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .navbarPosition {
    position: fixed;
    top: 0px;
    z-index: 10;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .containerNavbarStudio,
  .containerNavbarArtist {
    max-width: none !important;
    padding-left: 4px !important;
  }
}
.pagination-container {
  display: flex;
  list-style-type: none;
}
.pagination-container .pagination-item {
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;
}
.pagination-container .pagination-item.dots:hover {
  background-color: transparent;
  cursor: default;
}
.pagination-container .pagination-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}
.pagination-container .pagination-item.selected {
  background-color: rgba(0, 0, 0, 0.08);
}
.pagination-container .pagination-item .arrow::before {
  position: relative;
  /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
  content: "";
  /* By using an em scale, the arrows will size with the font */
  display: inline-block !important;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgba(0, 0, 0, 0.87);
  border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}
.pagination-container .pagination-item .arrow.left {
  transform: rotate(-135deg) translate(-50%);
}
.pagination-container .pagination-item .arrow.right {
  transform: rotate(45deg);
}
.pagination-container .pagination-item.disabled {
  pointer-events: none;
}
.pagination-container .pagination-item.disabled .arrow::before {
  border-right: 0.12em solid rgba(0, 0, 0, 0.43);
  border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}
.pagination-container .pagination-item.disabled:hover {
  background-color: transparent;
  cursor: default;
}

.inputProfile {
  height: 44px;
  padding-left: 40px !important;
  border-radius: 6px !important;
  width: 100%;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.greyTextFormPhone {
  border-radius: 6px !important;
}
.greyTextFormPhone input {
  font-size: 16px !important;
  font-family: "Roboto-Regular" !important;
  background-color: transparent !important;
  color: #7c7c7c !important;
  border: none !important;
  border-radius: 6px !important;
  width: 100% !important;
  height: 44px !important;
}
.greyTextFormPhone input:focus {
  box-shadow: none !important;
}
.greyTextFormPhone .flag-dropdown {
  background-color: transparent !important;
  border: none !important;
}
.greyTextFormPhone .flag-dropdown .selected-flag:hover, .greyTextFormPhone .flag-dropdown .selected-flag:focus {
  background-color: transparent !important;
}

.divMyAccount .inputProfile::-webkit-inner-spin-button,
.divMyAccount .inputProfile::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.containerMyAccount {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-bottom: 24px !important;
}
.containerMyAccount .divTextMyAccount {
  padding-top: 20px;
}
.containerMyAccount .divTextMyAccount h2 {
  text-align: start;
  font-size: 24px;
  margin-bottom: 0;
}
.containerMyAccount hr {
  margin: 0;
  margin-top: 16px;
}
.containerMyAccount .colPersonalInfo {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.containerMyAccount .colPersonalInfo .divPersonalInfo {
  width: 100%;
  max-width: 480px;
  padding-top: 24px;
}
.containerMyAccount .colPersonalInfo .divPersonalInfo h4 {
  font-size: 20px;
}
.containerMyAccount .colCentralBoxProfile {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.containerMyAccount .colCentralBoxProfile .centralBoxProfile {
  width: 100%;
  max-width: 480px;
  padding: 24px 12px 24px 12px;
  border-radius: 6px;
}
.containerMyAccount .colCentralBoxProfile .centralBoxProfile h4 {
  font-size: 20px;
}
.containerMyAccount .colCentralBoxProfile .centralBoxProfile .divMyAccount {
  display: flex;
  width: 100%;
  max-width: 480px;
  border-radius: 6px !important;
}
.containerMyAccount .colCentralBoxProfile .centralBoxProfile .divMyAccount img {
  width: 25px;
  margin-left: 8px;
  position: absolute;
  transform: translateY(38%);
}
.containerMyAccount .colCentralBoxProfile .centralBoxProfile .divMyAccount .inputProfile {
  height: 44px;
  padding-left: 40px !important;
  border: none !important;
  border-radius: 6px !important;
  width: 100%;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}
.containerMyAccount .colCentralBoxProfile .centralBoxProfile .divMyAccount .div-images .icon-error {
  transform: translateY(41%) translateX(-270%);
}
.containerMyAccount .colCentralBoxProfile .centralBoxProfile .divMyAccount .div-images .icon-eye {
  transform: translateY(41%) translateX(-156%);
}
.containerMyAccount .divBottonProfile {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 767px) {
  .divTextMyAccount {
    margin-top: 3em;
  }
  .divTextMyAccount h2 {
    text-align: center !important;
  }
}
.normal {
  width: 60px;
  height: 50px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  transition-duration: 0.3s;
}
.normal div {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.normal a :hover {
  color: #5268af;
}
.normal [aria-current] {
  position: relative;
  width: 60px;
  height: 50px;
  color: #5268af;
  background-color: #f1f2f7;
  display: flex;
  align-items: center;
  transition-duration: 0.3s;
}
.normal [aria-current] path {
  stroke: #5268af;
}

.linkOpen {
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  gap: 15px;
  transition-duration: 0.3s;
  text-decoration: none;
}
.linkOpen p {
  color: #7c7c7c;
  font-weight: bold;
  font-size: 14px;
  display: contents;
}
.linkOpen div {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.linkOpen [aria-current] {
  position: relative;
  height: 50px;
  padding-left: 25px;
  display: flex;
  align-items: center;
  gap: 15px;
  transition-duration: 0.3s;
  background-color: #f1f2f7;
}
.linkOpen [aria-current] path {
  stroke: #5268af;
}
.linkOpen [aria-current] div {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.linkOpen [aria-current] p {
  color: #5268af;
  font-weight: bold;
  font-size: 14px;
  padding-bottom: 2px;
}

.budget-info {
  background: #52af9a !important;
  color: #ffffff !important;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 12px;
}

.sidebar {
  position: sticky;
  top: 0;
  width: 80px;
  height: 100vh;
  background-color: #fcfcfc;
  transition-duration: 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.sidebar .iconSidebar {
  padding-left: 10px !important;
}
.sidebar h5 {
  display: none;
}
.sidebar .logoTypographic {
  display: none;
}
.sidebar .sidebarArrow {
  display: none;
}

.sidebarOpen {
  position: sticky;
  top: 0px;
  width: 220px;
  height: 100vh;
  background-color: #fcfcfc;
  transition-duration: 0.3s;
  font-family: "Roboto-Regular";
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sidebarOpen .iconSidebar {
  padding-left: 16px !important;
}
.sidebarOpen .userButton {
  background-color: #f4f4f4 !important;
  border-color: #f4f4f4 !important;
}
.sidebarOpen .sidebarTop {
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
}
.sidebarOpen .sidebarTop .sidebarArrow {
  margin-left: 2em !important;
  cursor: pointer;
}

.NavbarMain {
  background-color: #5268af;
}

.offCanvasMain {
  width: 100% !important;
  height: 486px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.userButton.btn-primary {
  color: #ffffff;
  background-color: transparent !important;
  border-color: transparent !important;
}

.userButtonNavbar {
  background-color: transparent !important;
  border: none !important;
}

.logoIcon {
  margin-top: 20px;
  text-align: center;
}

.sidebarArrowRight {
  margin-left: 1em;
  cursor: pointer;
}

.linksTop {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.linksTop a {
  text-decoration: none;
}

.linksTop2 {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
}
.linksTop2 a {
  text-decoration: none;
}

.linksDown a {
  text-decoration: none;
}

button.userButton.userButtonNavbar {
  background-color: transparent !important;
  border-color: transparent !important;
}

.userButton:focus {
  box-shadow: unset !important;
}

.userImage {
  width: 45px;
  height: 45px;
  object-fit: cover;
}

.userOpen {
  display: flex;
  padding-top: 5px;
}

.userData {
  display: flex;
  align-items: center;
}
.userData .linkNotStyle {
  text-decoration: none;
}

.LinkUser {
  color: #0e0f11 !important;
  text-decoration: none;
}
.LinkUser h5 {
  font-size: 16px;
}

.linksTopAzul {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  background-color: #f1f2f7;
}
.linksTopAzul a {
  text-decoration: none;
}
.linksTopAzul a p {
  color: #5268af;
}
.linksTopAzul a img {
  filter: brightness(0) saturate(100%) invert(38%) sepia(5%) saturate(5356%) hue-rotate(188deg) brightness(102%) contrast(87%);
}

.linksTopAzul1 {
  display: flex;
  flex-direction: column;
  margin-top: 0;
  background-color: #f1f2f7;
}
.linksTopAzul1 a {
  text-decoration: none;
}
.linksTopAzul1 a p {
  color: #5268af;
}
.linksTopAzul1 a img {
  filter: brightness(0) saturate(100%) invert(38%) sepia(5%) saturate(5356%) hue-rotate(188deg) brightness(102%) contrast(87%);
}

.linksDownAzul {
  background-color: #f1f2f7;
}
.linksDownAzul a {
  text-decoration: none;
}
.linksDownAzul a p {
  color: #5268af;
}
.linksDownAzul a img {
  filter: invert(36%) sepia(63%) saturate(504%) hue-rotate(188deg) brightness(97%) contrast(84%);
}

.linksprofileAzul {
  background-color: #f1f2f7;
}
.linksprofileAzul a {
  text-decoration: none;
}
.linksprofileAzul a p {
  color: #5268af;
}

.linksProfileAzul1 a h5 {
  color: #5268af !important;
}

.iconSidebarAzul {
  filter: brightness(0) saturate(100%) invert(38%) sepia(5%) saturate(5356%) hue-rotate(188deg) brightness(102%) contrast(87%) !important;
}

.divSidebarArrow {
  margin-bottom: 16px;
}

.registerTattoo {
  background-image: url("../../../assets/images/img17.svg");
  background-size: cover;
  background-position: center;
}

.topContentSignUp {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-family: "Roboto-Regular";
  margin: 16px 32px;
}
.topContentSignUp .linkBtnStartSesion {
  display: flex;
  align-items: center;
  text-decoration: none !important;
  color: #5268af !important;
}

.div-form-singup {
  box-shadow: 0px 5.42277px 21.6911px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  width: 100%;
  max-width: 480px;
  padding: 24px 12px;
  margin-left: auto !important;
  margin-right: auto !important;
}
.div-form-singup .selectionContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 32px;
}
.div-form-singup .selectionContent .artistButton,
.div-form-singup .selectionContent .studioButton {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  color: #5268af !important;
  border: 1px solid #5268af !important;
  width: 225px;
  height: 85px;
  font-size: 14px !important;
}
.div-form-singup .selectionContent .artistButton img,
.div-form-singup .selectionContent .studioButton img {
  filter: invert(38%) sepia(77%) saturate(356%) hue-rotate(188deg) brightness(93%) contrast(97%);
}
.div-form-singup .selectionContent button.focusColorButton {
  color: white !important;
  background-color: #5268af !important;
  border: 1px solid #5268af !important;
}
.div-form-singup .selectionContent button.focusColorButton img {
  filter: brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(3151%) hue-rotate(162deg) brightness(119%) contrast(100%) !important;
}
.div-form-singup .selectionContent .artistButton {
  margin-left: 8px;
}
.div-form-singup .selectionContent button.artistButton:focus,
.div-form-singup .selectionContent button.studioButton:focus {
  color: white !important;
  border-color: #5268af !important;
}
.div-form-singup .selectionContent button.artistButton:focus img,
.div-form-singup .selectionContent button.studioButton:focus img {
  filter: invert(38%) sepia(77%) saturate(356%) hue-rotate(188deg) brightness(93%) contrast(97%);
}
.div-form-singup .font-size-form {
  font-family: "Roboto-Regular" !important;
  font-size: 16px;
  font-weight: bold;
}
.div-form-singup .font-size-form .text-grey-color {
  color: #808080;
}
.div-form-singup .font-size-form .style-input-inquiries {
  background-color: #e4e4e4 !important;
  color: #808080 !important;
  border: none !important;
  border-radius: 5px;
}
.div-form-singup .font-size-form .style-input-generic-singup,
.div-form-singup .font-size-form .style-input-singup,
.div-form-singup .font-size-form .style-input-password-singup {
  background-color: #e4e4e4 !important;
  color: #808080 !important;
  border: none !important;
  border-radius: 5px;
  padding-left: 40px !important;
}
.div-form-singup .font-size-form .singupLabel {
  background-color: #e4e4e4 !important;
  color: #808080 !important;
  border: none !important;
  border-radius: 5px;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='gray' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 11px center;
  background-size: 25px;
}
.div-form-singup .font-size-form .style-input-singup {
  padding-left: 100px !important;
}
.div-form-singup .font-size-form .style-input-password-singup {
  padding-left: 8px !important;
}
.div-form-singup .font-size-form .div-img-text-singup {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.div-form-singup .font-size-form .div-img-text-singup img {
  width: 25px;
  position: absolute;
  transform: translateY(25%) translateX(25%);
}
.div-form-singup .font-size-form .div-phone-number .businessPhonePrefix .input-prefix-signup {
  font-size: 16px !important;
  background-color: #e4e4e4 !important;
  color: #808080 !important;
  width: 100% !important;
  height: 36px !important;
  border: none;
}
.div-form-singup .font-size-form .div-phone-number .businessPhonePrefix .flag-dropdown {
  background-color: #e4e4e4 !important;
  border: none;
}
.div-form-singup .font-size-form .div-phone-number .businessPhonePrefix .country-list {
  width: 230px !important;
}
.div-form-singup .font-size-form .div-phone-number .text-error-phone {
  margin: 0%;
  margin-top: 4px;
  color: #dc3545;
  font-size: 14px;
}
.div-form-singup .font-size-form .register-input {
  display: flex;
  width: 103%;
}
.div-form-singup .font-size-form .password-input-container {
  position: relative;
}
.div-form-singup .font-size-form .icon-container {
  position: absolute;
  display: flex;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  right: 10px; /* Ajusta este valor según tu diseño */
}
.div-form-singup .font-size-form .error-icon,
.div-form-singup .font-size-form .eye-register {
  cursor: pointer;
  margin-left: 5px; /* Ajusta este valor según tu diseño */
}
.div-form-singup .font-size-form .eye-register {
  margin-left: 10px; /* Ajusta este valor según tu diseño */
}
.div-form-singup .div-checkbox-terms-singup .form-check-input {
  margin-top: 0% !important;
  width: 20px;
  height: 20px;
}
.div-form-singup .div-checkbox-terms-singup label {
  padding-left: 8px;
  padding-top: 3px;
  font-family: "Roboto-Light" !important;
  font-size: 14px !important;
}
.div-form-singup .characterPassword {
  font-size: 14px;
  margin-top: 4px;
  color: #808080;
}
.div-form-singup .characterPassword2 {
  font-size: 14px;
  margin-top: 4px;
  color: #dc3545;
}
.div-form-singup .termsLink {
  color: #5268af;
  font-size: 14px;
  padding-top: 3px;
  font-family: "Roboto-Light" !important;
}

@media screen and (max-width: 413px) {
  .div-phone-number .unsetPrefix {
    overflow: unset !important;
  }
  .topContentSignUp {
    margin-left: 16px;
    margin-right: 16px;
  }
  .selectionContent {
    margin-bottom: 16px !important;
  }
  .logo-icon-loginRegister img {
    width: 85% !important;
  }
  .div-form-singup .businessPhonePrefix {
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: unset !important;
  }
  .div-form-singup a.termsLink,
  .div-form-singup .aceptTerms,
  .div-form-singup .professionalInstagram,
  .div-form-singup .businessDomain,
  .div-form-singup .businessArtistName,
  .div-form-singup .businessLastName,
  .div-form-singup .businessEmail,
  .div-form-singup .businessPassword {
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
  }
  .div-form-singup .inputEye {
    width: 100% !important;
  }
}
@media screen and (min-width: 414px) and (max-width: 575px) {
  .selectionContent {
    margin-bottom: 28px !important;
  }
}
@media screen and (max-width: 576px) {
  .imgTattooMachine {
    display: none;
  }
}
@media screen and (min-width: 576px) {
  .imgTattooMachine {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .imgTattooMachine {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .titleSuggestion {
    margin-top: 2.5em;
  }
}
@media screen and (min-width: 992px) {
  .imgTattooMachine {
    display: unset;
  }
}
.sizeModalBudgetPending div.modal-content {
  border: None !important;
  border-radius: 12px !important;
}

.information {
  text-align: start;
  margin-right: 8%;
  width: 100%;
}
.information .nav-tabs {
  border-bottom: none !important;
}
.information .nav-tabs .nav-link {
  font-size: 16px;
  font-family: "Roboto-Medium";
  color: #000000 !important;
}
.information .nav-tabs .active {
  border: none !important;
  color: #5268af !important;
  background-color: unset !important;
  border-color: none !important;
  font-size: 16px;
  font-family: "Roboto-Medium";
}

.image-employed {
  width: 50px;
}

.initial-employed {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border: 1px solid;
  border-radius: 6px;
  font-size: 30px !important;
  font-weight: 600;
}

.logoInitialEmployed {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.employed-left {
  display: flex;
  width: 70%;
}
.employed-left .employed-l {
  display: flex;
  align-items: center;
}
.employed-left .employed-l .p-add-employed {
  width: 90%;
  overflow-wrap: break-word;
  font-size: 16px;
  font-weight: 600;
}

.employed-right {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  cursor: pointer;
}
.employed-right input {
  display: none;
}

.object-artist {
  height: 56px;
  object-fit: cover;
  object-position: center;
  min-width: 56px;
  width: 56px;
  border-radius: 6px;
}

.p-customPC {
  padding: 0 10% 30% 10% !important;
}

.containerPageConfiguration {
  font-family: "Roboto-Regular";
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.containerPageConfiguration .rowAccordionPageConfiguration h4 {
  font-size: 20px;
}
.containerPageConfiguration .rowAccordionPageConfiguration .pcContainer .pcInfo .checkCopyConfigureAssistant {
  display: flex;
  align-items: flex-start;
  padding-bottom: 35px;
}


.rowAccordion {
  padding-top: 165px;
}

.header-pc {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
}
.header-pc button {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 256px;
}
.header-pc button img {
  margin-left: 15px;
}

.information-corporation-header {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
}

div .card {
  border: none;
  width: 100%;
}

.p10 {
  padding: 0 10% !important;
}

.pcContainer {
  margin-bottom: 24px !important;
  border-radius: 6px !important;
}
.pcContainer .label-aboutMe {
  display: none;
}
.pcContainer .countTextAreaPageConfiguration {
  text-align: end;
  font-size: 12px;
  margin-bottom: 12px !important;
}
.pcContainer .accordion-button {
  border-radius: 12px !important;
}
.pcContainer .pcInfo {
  padding: 16px 12px;
  border-radius: 6px;
}
.pcContainer .accordion-button:not(.collapsed) {
  background-color: #5268af !important;
}
.pcContainer .accordion-button:not(.collapsed) .pcTittle {
  color: #fcfcfc !important;
}
.pcContainer .pcTittle {
  color: #f4f4f4;
}
.pcContainer .accordion-header button {
  padding: 14px 0 14px 14px !important;
}
.pcContainer .accordion-button:not(.collapsed) .col-1 img {
  filter: brightness(0) invert(1) !important;
  transform: rotate(-180deg);
}
.pcContainer .templateTextBold {
  font-weight: 600;
}

.Perfil-URL {
  width: 80vw;
  text-align: center;
}

button .btn-Grey {
  border: 1px solid #808080;
  background-color: #808080;
  border-radius: 50%;
}

button .btn-Red {
  border: 1px solid #ff0000;
  background-color: #ff0000;
  border-radius: 50%;
}

button .btn-Grey:hover {
  background-color: #ff0000;
  border: 1px solid #ff0000;
}

button .btn-Red:hover {
  background-color: #808080;
  border: 1px solid #808080;
}

.btnCopyUrl {
  display: grid;
}

span.share {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  padding-right: 20px;
}

.btnCopyGreen {
  background-color: #52af9a !important;
  border: #52af9a !important;
  border-radius: 8px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 0.8px !important;
  padding-bottom: 1px !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.infoStudioName {
  text-align: center;
  width: 100%;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

img .card-img-top {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

div .imgUser {
  padding: 50px;
}

div .imagesUser {
  border: 1px solid #808080;
}

.textSizeAprox {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

/* --------- EMPLEADOS ------- */
.img-Button img {
  width: 100%;
}

button .img-Button {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #000000;
}

button .img-ButtonRed {
  background-image: url("https://images.unsplash.com/photo-1475695752828-6d2b0a83cf8a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1137&q=80");
  background-size: cover;
  background-position: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid rgb(190, 55, 55);
}

button .img-Button.btn:hover {
  border: 3px solid rgb(190, 55, 55);
}

/* TEMPLATE */
.information-center {
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  justify-content: center;
}
.information-center .template-tittle {
  text-align: left;
  padding-left: 15px;
}
.information-center .button-template {
  width: 100%;
}
.information-center .button-template button img {
  width: 100%;
}

.jodit-toolbar__box {
  display: none !important;
}

.jodit-status-bar {
  display: none !important;
}

.textModalDenied {
  color: #5268af;
}

.textLabelModalDenied {
  margin-bottom: 2rem !important;
}

.textLabelModalAccepted {
  margin-bottom: 2rem !important;
  font-weight: bold;
}

.template-button {
  padding-bottom: 20px;
  text-align: right;
}

.btnSendEnd {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-right: 20px;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:focus-visible {
  border: unset !important;
  outline: unset !important;
}

.buttonImageFocus img {
  border: 8px solid #52af9a !important;
}

.information-corporation {
  padding: 1rem 1rem;
}

.information-corporation-padding {
  padding: 0rem 0rem 0 0rem;
}
.information-corporation-padding .edit-button {
  color: #89898a !important;
  background-color: #dcdcdc !important;
  border: none !important;
}

.information-mh {
  min-height: 98% !important;
}

.information-corporation-img {
  border-radius: 50%;
  width: 75px;
  height: 75px;
  text-align: center;
  object-fit: cover;
}

.list-information {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.list-information img {
  margin-right: 16px;
}

.paddingFacebook {
  padding-left: 0.58em;
}

.modal-update-info .modal-dialog {
  width: 362px;
}
.modal-update-info .btn-close {
  width: 10px;
  height: 10px;
  background-color: #dcdcdc;
  padding: 15px !important;
}
.modal-update-info .modal-header {
  border: none;
}

.logo-update-container label {
  font-size: 16px;
  font-family: "Roboto-Bold";
}
.logo-update-container .file-upload {
  width: 100px;
  height: 100px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border: 4px solid #909090;
}

.putInput {
  position: absolute;
  height: 100% !important;
  width: 100% !important;
  left: 0px !important;
  top: 0px !important;
}

.logo-update-container img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: center;
}

div.d-flex-center.borderIconPlus {
  border: 2px solid #7c7c7c;
  height: 300px;
  width: 100%;
  left: 0px;
  top: 0px;
  border-radius: 6px;
}
div.d-flex-center.borderIconPlus .imageLogoUpload {
  display: flex !important;
  flex-direction: column;
  align-items: center;
}
div.d-flex-center.borderIconPlus .imageLogoUpload .imgPlusUpload {
  width: 50% !important;
}
div.d-flex-center.borderIconPlus .imageLogoUpload .imgPlusUploadFrontPage {
  width: 24% !important;
}
div.d-flex-center.borderIconPlus .imageLogoUpload p {
  -webkit-text-fill-color: #7c7c7c;
}

div.divConfigureAssistantLogo {
  padding: 24px 12px 24px 12px;
}
div.divConfigureAssistantLogo .initial-name {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  width: 100%;
  height: 100%;
}
div.divConfigureAssistantLogo .initial-name p {
  font-size: 32px;
}
div.divConfigureAssistantLogo .div-logo-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
div.divConfigureAssistantLogo .div-logo-buttons .image-logo-upload {
  border: 1px solid;
  border-radius: 50%;
  width: 72px !important;
  height: 72px !important;
}
div.divConfigureAssistantLogo .div-logo-buttons input {
  position: relative;
  height: 0px;
  width: 0px;
  left: 310px;
  top: -104px;
  background: transparent !important;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
div.divConfigureAssistantLogo .div-logo-buttons .logo-image {
  width: 72px !important;
  height: 72px !important;
  object-fit: cover;
  border-radius: 50px !important;
}
div.divConfigureAssistantLogo .div-buttons-logo {
  display: flex;
  flex-direction: column;
}
div.divConfigureAssistantLogo p {
  font-size: 14px;
}
div.divConfigureAssistantLogo .text-textarea {
  padding: 8px;
  height: 87px;
  border: none !important;
}
div.divConfigureAssistantLogo .text-textarea::-webkit-scrollbar {
  display: none !important;
}
div.divConfigureAssistantLogo .div-button-save-phones {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 16px;
}

.saveImgConfiguration {
  width: 145px !important;
  height: 40px;
  border: none !important;
  box-shadow: none !important;
}
.saveImgConfiguration:active {
  background-color: #52af9a !important;
  border: none;
}

.no-data-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #7c7c7c;
  height: 300px;
  width: 100%;
  left: 0px;
  top: 0px;
  border-radius: 6px;
}
.no-data-container .noDataImg {
  width: 16% !important;
}

.no-data-container-template {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: 2px dashed #909090;
  padding: 1rem 0rem 1rem 0rem;
}
.no-data-container-template .image-upload-template {
  width: 25%;
}
.no-data-container-template .imageUploadTemplate {
  width: 25%;
}

.modal-update-info input {
  width: 100%;
  margin-bottom: 8px;
}
.modal-update-info label {
  padding: 0;
  font-size: 14px;
  font-family: "Roboto-Bold";
}

.file-upload,
.file-upload-coverpage {
  overflow: hidden;
  position: relative;
}
.file-upload p,
.file-upload-coverpage p {
  text-align: center;
}
.file-upload input,
.file-upload-coverpage input {
  position: absolute;
  height: 400px;
  width: 400px;
  left: -200px;
  top: -200px;
  background: transparent;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  cursor: pointer;
}
.file-upload .input-plus,
.file-upload-coverpage .input-plus {
  height: 30%;
  width: 30%;
  margin: auto;
}

.logoUploadUser {
  height: 300px;
  width: 100%;
}
.logoUploadUser .logoImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 6px;
}

.no-data-information {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.no-data-information .file-upload-coverpage {
  height: unset;
  width: 100%;
  border-radius: unset;
  display: flex;
  justify-content: center;
}
.no-data-information .file-upload-coverpage input {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: transparent;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  cursor: pointer;
}
.no-data-information .file-upload-coverpage .upload__image-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: transparent;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  cursor: pointer;
}

.divButtonPButtons {
  display: flex;
  justify-content: flex-end;
}
.divButtonPButtons .upload-container {
  margin-bottom: 16px !important;
  margin-right: 1px !important;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block !important;
  padding: 6px 12px;
  cursor: pointer;
  text-align: center;
}

@media screen and (min-width: 0px) and (max-width: 575px) {
  .noDataInformationTemplates,
  .no-data-information {
    width: 100% !important;
  }
  .no-data-container-template {
    width: 100% !important;
    height: 200px !important;
  }
  .no-data-container-template .image-upload-template {
    width: 15% !important;
  }
  .divPButtonImg {
    width: 100%;
  }
  .divPButtonImg .noDataInformationTemplates,
  .divPButtonImg .no-data-information {
    width: 100% !important;
  }
  .divPButtonImg .noDataInformationTemplates .no-data-container-template,
  .divPButtonImg .no-data-information .no-data-container-template {
    width: 100% !important;
  }
  .divPButtonImg .noDataInformationTemplates .no-data-container-template .image-upload-template,
  .divPButtonImg .no-data-information .no-data-container-template .image-upload-template {
    width: 15% !important;
  }
  .divPButtonImg .noDataInformationTemplates .no-data-container-template .imageUploadTemplate,
  .divPButtonImg .no-data-information .no-data-container-template .imageUploadTemplate {
    width: 20%;
  }
}
.no-data-container-template {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: 2px dashed #909090;
  padding: 1rem 0rem 1rem 0rem;
}
.no-data-container-template .image-upload-template {
  width: 25%;
}
.no-data-container-template .imageUploadTemplate {
  width: 25%;
}

.pcPortofolio .imageLogoUpload {
  border: 1px solid #7c7c7c;
  border-radius: 5px;
  padding: 19px 16px 8px 16px;
  text-align: center;
  width: 114px;
  height: 125px;
  margin-left: 1em;
  cursor: pointer;
}
.pcPortofolio .imageLogoUpload .imgPlusUpload {
  width: 45%;
}
.pcPortofolio .imageLogoUpload p {
  font-size: 15px;
  color: #7c7c7c;
}
.pcPortofolio .upload-container {
  margin-top: 16px;
}
.pcPortofolio .image-portfolio-container {
  width: 100%;
  align-items: flex-start;
  display: flex;
  justify-content: flex-end !important;
}
.pcPortofolio .image-portfolio-container .image-portfolio {
  width: 100%;
  height: 213px;
  object-fit: cover;
}
.pcPortofolio .image-portfolio-input-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.pcPortofolio .image-portfolio-input-container #file-upload {
  display: none;
}
.pcPortofolio .image-portfolio-container .options {
  position: absolute;
  z-index: 999;
  margin-right: 0;
}

.pcInvitations .header-employed p {
  color: #7c7c7c;
}

.btn-delete {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  border-color: #000000;
  padding: 10px;
  background-color: #000000;
  margin: 5px 5px;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
}

.btn-not-delete {
  border-radius: 50%;
  border: none;
  width: 16px;
  height: 16px;
  padding: 10px;
  margin: 5px 5px;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
}

.mapboxgl-ctrl-logo,
.mapboxgl-ctrl-attrib-button {
  display: none !important;
}

.divPAdre > div:nth-child(2n) {
  height: 1px !important;
  visibility: hidden !important;
}

.divPAdre .mapboxgl-ctrl-geocoder {
  width: 100%;
  box-shadow: unset;
}
.divPAdre .mapboxgl-ctrl-geocoder--input {
  width: 100% !important;
  border: 1px solid #808080;
  display: block !important;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.divPAdre .mapboxgl-ctrl-geocoder--icon-search {
  display: none;
}

.pCtemplates .templateSelect .carousel__inner-slide {
  background-color: #a4eb82;
  border: 4px solid #a4eb82;
  border-radius: 16px;
}
.pCtemplates .templateSelect input[type=radio] {
  appearance: none;
  border-radius: 50%;
  border: 4px solid white;
  width: 30px;
  height: 30px;
  background-color: transparent;
  box-shadow: inset 0 0 0 0.2em #5268af, inset 0 0 0 1em #5268af;
}
.pCtemplates .templateNotSelect .carousel__inner-slide {
  background-color: #5268af;
  border: 4px solid #5268af;
  border-radius: 16px;
}
.pCtemplates .templateNotSelect img {
  width: 10%;
}
.pCtemplates .templateNotSelect input[type=radio] {
  appearance: none;
  border-radius: 50%;
  border: 4px solid white;
  width: 30px;
  height: 30px;
  background-color: transparent;
  box-shadow: inset 0 0 0 0.2em #5268af, inset 0 0 0 1em #5268af;
}
.pCtemplates .templateNotSelect input[type=radio] {
  cursor: pointer;
}
.pCtemplates .templateNotSelect input[type=radio] {
  -webkit-transition: all 0.3s ease;
  transition: all 0.5s ease;
  box-shadow: inset 0 0 0 0.2em #5268af, inset 0 0 0 1em #5268af;
}
.pCtemplates .carousel__container {
  max-width: 550px;
  margin: auto;
}
.pCtemplates .carousel__slider {
  padding-right: 15%;
}
.pCtemplates .carousel__inner-slide {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  left: 10px;
  top: 10px;
}
.pCtemplates .containerPCImage {
  height: 100%;
}
.pCtemplates .containerPCImage .PCImage {
  height: 92%;
}
.pCtemplates .containerPCImage .PCImage button {
  height: 100%;
  width: 100%;
  padding: 0 0 6px 0;
}
.pCtemplates .containerPCImage .PCImage button .imagePCTemplate {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 16px 16px 0 0;
}
.pCtemplates .containerPCImage .selectPCImage {
  height: 8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
}
.pCtemplates .containerPCImage .selectPCImage span {
  color: white;
  font-size: 24px;
}
.pCtemplates .pCbuttonsTemplates {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.pCtemplates .pCbuttonsTemplates .pCText {
  font-weight: bold;
  margin: 0;
}
.pCtemplates .pCbuttonsTemplates .carousel__dot-group {
  display: none;
}
.pCtemplates .pCbuttonsTemplates .pCBack,
.pCtemplates .pCbuttonsTemplates .pCNext {
  border: none;
  background: transparent;
}
.pCtemplates .pCButton {
  text-align: end;
}

.border-div-availabilityCheck {
  border-bottom: 1px solid;
  width: 100%;
}

.availabilityCheck {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.availabilityCheck .text-server-list {
  font-size: 14px !important;
}
.availabilityCheck p {
  font-size: 16px !important;
}
.availabilityCheck .div-options-check {
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;
}
.availabilityCheck .div-options-form {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.availabilityCheck .div-options-form .switch-schedule input {
  width: 48px;
  height: 32px;
  box-shadow: none;
}
.availabilityCheck span {
  padding-top: 0.2em;
  padding-left: 0.5em;
  font-size: 16px;
  color: #0e0f11;
}
.availabilityCheck .size-text-label {
  font-size: 14px;
}

.div-text-schedule {
  margin-bottom: 24px;
}

.cityAndStudio {
  width: 236px;
  height: 16px;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #525252;
}

.buttonCityAdd {
  border: none;
  background-color: transparent !important;
  padding: 8px;
}
.buttonCityAdd img {
  width: 100%;
  max-width: 25px;
  min-width: 25px;
  height: auto;
}
.buttonCityAdd .no-filter-img {
  filter: invert(1);
}
.buttonCityAdd .filter-img {
  filter: brightness(0%);
}

.btn-delete-city {
  background: transparent !important;
  border: none;
  border-radius: 50%;
  -moz-border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
}
.btn-delete-city .filter-img {
  filter: invert(1);
}

.todo-list .p-add-city {
  margin-top: 16px;
}
.todo-list .p-add-artist {
  margin-top: 16px;
  margin-bottom: 0;
}
.todo-list .div-input-add-city {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.todo-list .text-city-study {
  margin-top: 16px;
  margin-bottom: 0 !important;
}
.todo-list .inputCity {
  padding: 0px 8px;
  width: 90%;
  height: 40px;
  border: none;
  border-radius: 6px;
}
.todo-list .cityElementList {
  display: flex;
  justify-self: center;
  align-items: center;
  border-bottom: 1px solid;
}
.todo-list .cityElementList p {
  margin: 0 0 0 5px;
}
.todo-list .cityElementList .p-add-image {
  margin-bottom: 4px !important;
  text-decoration: underline !important;
}
.todo-list .cityElementList .div-add-city {
  margin-top: 8px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}
.todo-list .cityElementList .div-add-city .size-bold-text {
  font-size: 16px;
  font-weight: 600;
}
.todo-list .div-margin-padding {
  margin-top: 8px;
  padding-bottom: 8px;
}

.p-customPC .pcContainer .todo-list .cityList .cityElementList:last-child {
  border-bottom: none !important;
}

.modalShare {
  z-index: 99999;
}
.modalShare .modal-content {
  background-color: transparent;
  border: none;
}

.sharedLinks {
  border-radius: 16px !important;
}
.sharedLinks .sharedBody {
  padding-top: 16px;
}
.sharedLinks .sharedBody .sharedBodyImg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sharedLinks .sharedBody .sharedBodyImg img {
  width: 100%;
  margin-bottom: 2em;
}
.sharedLinks .sharedBodycopy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #dedede;
  padding: 8px;
  border-radius: 6px;
}
.sharedLinks .sharedBodycopy .bodyBold {
  width: 75%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  color: #0e0f11;
}
.sharedLinks .sharedBodycopy .ButonRRSS,
.sharedLinks .sharedBodycopy .ButonRRSSGreen {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #5268af;
  background-color: transparent;
}
.sharedLinks .sharedBodycopy .ButonRRSSGreen {
  background-color: #25d366;
  color: white !important;
  border: none;
  display: flex;
  box-shadow: none;
}
.sharedLinks .sharedBodycopy .ButonRRSSGreen .iconCheckButtonRRSS {
  margin-right: 2px;
}
.sharedLinks .sharedBodycopy p {
  margin: 0;
  margin-top: 2px;
}
.sharedLinks .buttonsShared {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.sharedLinks .buttonsShared .links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #dedede;
  cursor: pointer;
}
.sharedLinks .buttonsShared .links button {
  padding: 16px 8px;
  background-color: transparent;
  color: black;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.sharedLinks .buttonsShared .links button img {
  margin-right: 6px;
}
.sharedLinks .iconBack {
  background-color: transparent !important;
  border: none;
  padding-left: 0;
}
.sharedLinks .header-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sharedLinks .header-link .closedAlert {
  position: unset;
}
.sharedLinks .links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
.sharedLinks .links button {
  padding: 16px 8px;
  background-color: transparent;
  color: black;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.sharedLinks .links button img {
  margin-right: 6px;
}

@media screen and (min-width: 0px) and (max-width: 414px) {
  div.divConfigureAssistantLogo .text-textarea {
    height: 160px;
  }
  .logoInitialEmployed {
    flex-direction: column;
  }
  .div-button-save-phones {
    padding-bottom: 24px !important;
  }
}
@media screen and (min-width: 0px) and (max-width: 575px) {
  div.divConfigureAssistantLogo .text-textarea {
    height: 115px;
  }
  div.divConfigureAssistantLogo .div-logo-buttons input {
    left: 98px;
    top: -104px;
  }
}
/* //RESPONSIVE PORTFOLIO EN PAGE CONFIGURATION // */
@media screen and (min-width: 0px) and (max-width: 413px) {
  .divPageConfigurationPortfolio .divPageConfigurationImgCard {
    width: 100% !important;
  }
  .divPageConfigurationPortfolio .divPageConfigurationImgCard .labelPortfolio {
    padding: 0;
    margin: 0;
    margin-bottom: 0.5em;
  }
  .divPageConfigurationPortfolio .divPageConfigurationImgCard .labelPortfolio .imgPortfolio {
    width: 100%;
    height: 225px;
  }
  .divPageConfigurationPortfolio .divPageConfigurationImgCard .iconClosedImgPortfolio {
    top: 5px !important;
    right: 5px !important;
  }
  .pcPortofolio .imageLogoUpload {
    border: 1px solid #7c7c7c;
    border-radius: 5px;
    padding: 12px 16px 7px 16px;
    width: 98%;
    height: 185px;
    margin-left: 0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .pcPortofolio .imageLogoUpload .imgPlusUpload {
    width: 25%;
  }
  .sharedLinks .sharedBody .sharedBodyImg img {
    width: 87%;
    margin-bottom: 2em;
  }
}
.rowFixedHeaderPcLink .budgetEmpty h2 {
  display: none;
}
.rowFixedHeaderPcLink .budgetEmpty p {
  display: none;
}

@media screen and (min-width: 414px) and (max-width: 767px) {
  .divPageConfigurationPortfolio .divPageConfigurationImgCard {
    width: 50% !important;
  }
  .divPageConfigurationPortfolio .divPageConfigurationImgCard .labelPortfolio {
    padding: 0;
    margin: 0;
    margin-bottom: 0.5em;
  }
  .divPageConfigurationPortfolio .divPageConfigurationImgCard .labelPortfolio .imgPortfolio {
    width: 100%;
    height: 185px;
  }
  .divPageConfigurationPortfolio .divPageConfigurationImgCard .iconClosedImgPortfolio {
    top: 5px !important;
    right: 5px !important;
  }
  .pcPortofolio .imageLogoUpload {
    border: 1px solid #7c7c7c;
    border-radius: 5px;
    padding: 12px 16px 7px 16px;
    width: 48.5%;
    height: 185px;
    margin-left: 0.3em;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .pcPortofolio .imageLogoUpload img {
    width: 25%;
  }
}
/* //RESPONSIVE MODAL CAMBIO DE TEMPLATE EN PERFIL DE NEGOCIO // */
@media screen and (max-width: 380px) {
  .sizeDiv-P-Responsive {
    width: 50%;
  }
}
@media screen and (min-width: 629px) {
  .divButtonsChangeTemplates {
    display: flex;
    justify-content: flex-end !important;
  }
}
/* ///////////////////////////////////////////////////////// */
.buttonCPImage {
  background-color: unset;
  border: none;
}
.buttonCPImage p {
  color: #7c7c7c;
}

.template-button {
  padding-bottom: 20px;
  padding-top: 20px;
}

@media screen and (min-width: 0px) and (max-width: 392px) {
  .header-pc {
    justify-content: center !important;
  }
  #buttonPreviewPage {
    z-index: 1000;
    position: fixed !important;
    bottom: 15px;
    border: 0 !important;
  }
  .information-center-Responsive {
    flex-direction: column;
  }
  .information-center-Responsive .button-template-Responsive {
    width: 230px;
  }
  .information-center {
    display: grid;
    grid-template-columns: repeat(1, 2fr);
    justify-items: center;
  }
  .template-button {
    text-align: center;
  }
}
@media screen and (min-width: 393px) and (max-width: 575px) {
  .header-pc {
    justify-content: center !important;
  }
  #buttonPreviewPage {
    z-index: 1000;
    position: fixed !important;
    bottom: 15px;
    border: 0 !important;
  }
  .information-center-Responsive {
    flex-direction: column;
  }
  .information-center-Responsive .button-template-Responsive {
    width: 100%;
  }
  .information-center {
    display: grid;
    grid-template-columns: repeat(1, 2fr);
    justify-items: center;
  }
}
.btn-portfolio {
  position: absolute;
  z-index: 1;
  bottom: auto;
  margin-right: 0;
}

@media screen and (min-width: 0px) and (max-width: 413px) {
  .colPadding {
    margin-right: 16px;
  }
  .p-customPC {
    padding: 0 !important;
    margin: 0 16px;
  }
  .rowAccordionPageConfiguration {
    padding-top: 275px;
  }
}
@media screen and (min-width: 414px) and (max-width: 575px) {
  .p-customPC {
    padding: 8px 8px 60px 8px !important;
  }
  .p-customPC .colPadding {
    margin-right: 16px;
  }
  .p-customPC .pcTittle {
    padding-right: 9px;
  }
  .rowFixedHeaderPcLink {
    top: 114px !important;
  }
  .rowAccordionPageConfiguration {
    padding-top: 275px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .rowAccordionPageConfiguration {
    padding-top: 280px;
    justify-content: center;
  }
  .rowAccordionPageConfiguration .p-customPC {
    padding: 0 0% 10% 0% !important;
    max-width: 480px !important;
  }
  .rowFixedHeaderPcLink {
    top: 114px !important;
  }
  .header-pc {
    justify-content: center !important;
  }
  #buttonPreviewPage {
    z-index: 1000;
    position: fixed !important;
    bottom: 15px;
    border: 0 !important;
  }
  .information-center {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    justify-items: center;
  }
  .information-center-Responsive .button-template-Responsive {
    margin: 0.5em;
  }
  .template-button {
    text-align: end;
  }
}
.information-center {
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  justify-items: center;
}

.information-center-Responsive .button-template-Responsive {
  margin: 0.5em;
}

.template-button {
  padding-bottom: 20px;
  padding-top: 20px;
  text-align: end;
}

@media screen and (min-width: 768px) {
  .rowAccordionPageConfiguration {
    padding-top: 250px;
    justify-content: center;
  }
  .rowAccordionPageConfiguration .p-customPC {
    padding: 0 0% 10% 0% !important;
    max-width: 480px !important;
  }
  .header-pc {
    padding: 0px 24px 8px 24px !important;
  }
}
@media screen and (min-width: 992px) {
  .information-center {
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    justify-items: center;
  }
  .information-center-Responsive .button-template-Responsive {
    margin: 0.5em;
  }
  .template-button {
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: end;
  }
  .pCtemplates {
    width: 60%;
    margin: auto;
  }
  .rowFixedHeaderPc .header-pc {
    padding-right: 22% !important;
  }
}
.iconChevronDowrReverse:not(.collapsed)::after {
  transform: none;
}

.iconChevronDowrReverse::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

@media screen and (min-width: 1050px) {
  .rowFixedHeaderPc .header-pc {
    padding-right: 6% !important;
  }
  .rowAccordionPageConfiguration {
    padding-top: 200px;
  }
}
@media screen and (min-width: 1600px) {
  .rowFixedHeaderPc .header-pc {
    padding-right: 2% !important;
  }
}
.availabilityCheckDarkMode {
  display: flex;
  align-items: center;
}
.availabilityCheckDarkMode .form-check-input {
  width: 48px !important;
  height: 32px !important;
  box-shadow: none !important;
}
.availabilityCheckDarkMode span {
  margin-left: 8px;
}

div .titleStripe {
  display: flex;
  justify-content: center;
  font-size: 16px;
  margin-bottom: 24px;
}

div .titleInstagram {
  display: flex;
  font-size: 20px;
  margin-bottom: 24px;
}

div .divButtonStripe {
  display: flex;
  justify-content: center;
}
div .divButtonStripe .linkStripe {
  text-decoration: none;
}
div .divButtonStripe .linkStripe .divStripeLink {
  width: 244px;
  height: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
div .divButtonStripe .linkStripe .divStripeLink .filter-img {
  filter: invert(1) !important;
}

.containerStripe .title-stripe-plans {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 8px;
  margin-bottom: 0;
  border-radius: 4px;
  min-height: 36px;
  font-size: 14px;
}
.containerStripe .promotionalPrice {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 25px;
  background-color: #FF385C;
  border-radius: 50px;
  font-size: 14px;
}
.containerStripe .border-recommended {
  border: 1px solid #FF385C;
}
.containerStripe .border-recommended .recommendedPrice {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 105px;
  height: 26px;
  background-color: #787878;
  border-radius: 50px;
}
.containerStripe .border-recommended .recommendedPrice p {
  font-size: 14px;
}
.containerStripe .card-stripe {
  display: flex;
}
.containerStripe .card-stripe h5 {
  font-weight: bold;
  margin-bottom: 16px;
}
.containerStripe .card-stripe h6 {
  font-size: 14px;
}
.containerStripe .price-stripe {
  font-size: 24px;
  font-weight: bold;
}
.containerStripe .price-plan {
  position: relative;
}
.containerStripe .price-plan::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #808080;
}
.containerStripe .color-price-plan {
  padding-left: 4px;
  color: #FF385C;
}
.containerStripe .span-text {
  font-size: 14px;
  font-weight: bold;
}
.containerStripe .icon-check-text {
  display: flex;
}
.containerStripe .icon-check-text p {
  margin: 0;
  font-size: 14px;
  padding-left: 12px;
}
.containerStripe .div-button-stripe-plan {
  display: flex;
  justify-content: center;
  margin-top: 36px;
}
.containerStripe .div-button-stripe-plan .bottonStripePlan {
  width: 100%;
  max-width: 244px;
  height: 38px;
  border-radius: 50px;
  border: none;
}
.containerStripe .div-button-stripe-plan .bottonStripePlan a {
  text-decoration: none;
}

.dialog-modal-download-excel {
  max-width: 510px !important;
  margin: 0, 5rem !important;
}
.dialog-modal-download-excel .modal-download-excel-header .modal-download-excel-title {
  font-size: 16px !important;
  color: #5268af;
}
.dialog-modal-download-excel .modal-download-excel-header .icon-closed-modal-download-excel {
  filter: invert(45%) sepia(11%) saturate(2607%) hue-rotate(189deg) brightness(86%) contrast(85%);
}
.dialog-modal-download-excel .div-buttons-modal-budgets {
  display: flex;
  justify-content: flex-end;
  margin: 0px 16px 16px 0px;
}
.dialog-modal-download-excel .div-buttons-modal-budgets .button-modal-download-excel-cancel,
.dialog-modal-download-excel .div-buttons-modal-budgets .button-modal-download-excel {
  width: 125px;
  height: 45px;
  border-radius: 50px;
  border: 1px solid #5268af;
  background-color: transparent;
  color: #5268af;
  font-weight: bold;
  box-shadow: none;
  padding: 6px;
}
.dialog-modal-download-excel .div-buttons-modal-budgets .button-modal-download-excel {
  margin-left: 16px;
  color: white;
  background-color: #5268af;
}

@media screen and (max-width: 576px) {
  .dialog-modal-download-excel {
    margin: auto !important;
  }
}
@media screen and (min-width: 768px) {
  .dialog-modal-download-excel .modal-content-download-excel {
    transform: translate(50px, 50px);
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  display: flex;
}

/* Toggle Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4caf50;
}

input:checked + .slider:before {
  transform: translateX(14px);
}


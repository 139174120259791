.dialog-modal-download-excel {
  max-width: 510px !important;
  margin: 0, 5rem !important;
  .modal-download-excel-header {
    .modal-download-excel-title {
      font-size: 16px !important;
      color: #5268af;
    }
    .icon-closed-modal-download-excel {
      filter: invert(45%) sepia(11%) saturate(2607%) hue-rotate(189deg)
        brightness(86%) contrast(85%);
    }
  }
  .div-buttons-modal-budgets {
    display: flex;
    justify-content: flex-end;
    margin: 0px 16px 16px 0px;
    .button-modal-download-excel-cancel,
    .button-modal-download-excel {
      width: 125px;
      height: 45px;
      border-radius: 50px;
      border: 1px solid #5268af;
      background-color: transparent;
      color: #5268af;
      font-weight: bold;
      box-shadow: none;
      padding: 6px;
    }
    .button-modal-download-excel {
      margin-left: 16px;
      color: white;
      background-color: #5268af;
    }
  }
}

@media screen and (max-width: 576px) {
  .dialog-modal-download-excel {
    margin: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .dialog-modal-download-excel {
    .modal-content-download-excel {
      transform: translate(50px, 50px);
    }
  }
}

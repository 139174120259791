.inputProfile {
  height: 44px;
  padding-left: 40px !important;
  // background-color: #e4e4e4 !important;
  border-radius: 6px !important;
  width: 100%;
  @include ellipsis;
}
.greyTextFormPhone {
  border-radius: 6px !important;
  input {
    font-size: 16px !important;
    font-family: $robotoRegular !important;
    background-color: transparent !important;
    color: #7c7c7c !important;
    border: none !important;
    border-radius: 6px !important;
    width: 100% !important;
    height: 44px !important;
    &:focus {
      box-shadow: none !important;
    }
  }
  .flag-dropdown {
    background-color: transparent !important;
    border: none !important;
    .selected-flag {
      &:hover,
      &:focus {
        background-color: transparent !important;
      }
    }
  }
}
.divMyAccount .inputProfile::-webkit-inner-spin-button,
.divMyAccount .inputProfile::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.containerMyAccount {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-bottom: 24px !important;
  .divTextMyAccount {
    padding-top: 20px;
    h2 {
      text-align: start;
      font-size: 24px;
      margin-bottom: 0;
    }
  }
  hr {
    margin: 0;
    margin-top: 16px;
  }

  .colPersonalInfo {
    @include dFlexCenter;
    .divPersonalInfo {
      width: 100%;
      max-width: 480px;
      padding-top: 24px;
      h4 {
        font-size: 20px;
      }
    }
  }

  .colCentralBoxProfile {
    @include dFlexCenter;
    .centralBoxProfile {
      width: 100%;
      max-width: 480px;
      padding: 24px 12px 24px 12px;
      border-radius: 6px;
      h4 {
        font-size: 20px;
      }
      .divMyAccount {
        display: flex;
        width: 100%;
        max-width: 480px;
        border-radius: 6px !important;
        img {
          width: 25px;
          margin-left: 8px;
          position: absolute;
          transform: translateY(38%);
        }
        .inputProfile {
          height: 44px;
          padding-left: 40px !important;
          border: none !important;
          border-radius: 6px !important;
          width: 100%;
          @include ellipsis;
        }
        .div-images {
          .icon-error {
            transform: translateY(41%) translateX(-270%);
          }
          .icon-eye {
            transform: translateY(41%) translateX(-156%);
          }
        }
      }
    }
  }

  .divBottonProfile {
    display: flex;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 767px) {
  .divTextMyAccount {
    margin-top: 3em;
    h2 {
      text-align: center !important;
    }
  }
}

// @media screen and (max-width: 575px) {
//   .divPersonalInfo,
//   .centralBoxProfile {
//     width: 100%;
//     max-width: 480px !important;
//   }

//   .inputPhoneProfile {
//     .form-control {
//       width: 100% !important;
//     }
//   }

//   .inputMyAccount,
//   .inputBussinesProfile {
//     width: 100% !important;
//   }
// }

// @media screen and (max-width: 413px) {
//   .inputPhoneProfile {
//     .form-control {
//       width: 100%!important;
//     }
//   }

//   .inputMyAccount,
//   .inputBussinesProfile {
//     width: 100% !important;
//   }
// }

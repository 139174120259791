.bg-navbar {
  background-color: #f4f4f4;
  .navbarButtons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.navbar-light .navbar-toggler {
  border: none !important;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}
.navBarUser {
  display: flex;
  justify-content: space-between;
}

.divAccountProfile {
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .navbarPosition {
    position: fixed;
    top: 0px;
    z-index: 10;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .containerNavbarStudio,
  .containerNavbarArtist {
    max-width: none !important;
    padding-left: 4px !important;
  }
}

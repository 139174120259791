.information {
  text-align: $alignStart;
  margin-right: 8%;
  width: $width100;

  .nav-tabs {
    border-bottom: $globalNone !important;
    .nav-link {
      font-size: 16px;
      font-family: $robotoMedium;
      color: $colorBasicBlack !important;
    }
    .active {
      border: $globalNone !important;
      color: $colorSecondaryBase !important;
      background-color: $globalUnset !important;
      border-color: $globalNone !important;
      font-size: 16px;
      font-family: $robotoMedium;
    }
  }
}

.image-employed {
  width: 50px;
}
.initial-employed {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border: 1px solid;
  border-radius: 6px;
  font-size: 30px !important;
  font-weight: 600;
}
.logoInitialEmployed {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.employed-left {
  display: flex;
  width: 70%;
  .employed-l {
    display: flex;
    align-items: center;
    .p-add-employed {
      width: 90%;
      overflow-wrap: break-word;
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.employed-right {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  cursor: pointer;
  input {
    display: none;
  }
}
.object-artist {
  height: 56px;
  object-fit: cover;
  object-position: center;
  min-width: 56px;
  width: 56px;
  border-radius: 6px;
}
.p-customPC {
  padding: 0 10% 30% 10% !important;
}

.containerPageConfiguration {
  font-family: $robotoRegular;
  padding-left: 0px !important;
  padding-right: 0px !important;
  .rowAccordionPageConfiguration {
    h4 {
      font-size: 20px;
    }
    .pcContainer {
      .pcInfo {
        .checkCopyConfigureAssistant {
          display: flex;
          align-items: flex-start;
          padding-bottom: 35px;
        }
      }
    }
  }
}

.rowAccordion {
  padding-top: 165px;
}

.header-pc {
  @include dFlexBetweenCenter;

  button {
    @include dFlexCenter;
    height: 44px;
    width: 256px;

    img {
      margin-left: 15px;
    }
  }
}

.information-corporation-header {
  @include dFlexBetweenCenter;
}

div {
  .card {
    border: $globalNone;
    width: $width100;
  }
}

.p10 {
  padding: 0 10% !important;
}

.pcContainer {
  margin-bottom: 24px !important;
  border-radius: 6px !important;
  .label-aboutMe {
    display: none;
  }

  .countTextAreaPageConfiguration {
    text-align: end;
    font-size: 12px;
    margin-bottom: 12px !important;
  }

  .accordion-button {
    border-radius: 12px !important;
  }
  .pcInfo {
    padding: 16px 12px;
    border-radius: 6px;
  }

  .accordion-button:not(.collapsed) {
    background-color: $colorSecondaryBase !important;
  }
  .accordion-button:not(.collapsed) .pcTittle {
    color: $colorPrimaryLightModeBase-1 !important;
  }
  .pcTittle {
    color: #f4f4f4;
  }
  .accordion-header {
    button {
      padding: 14px 0 14px 14px !important;
    }
  }
  .accordion-button:not(.collapsed) .col-1 img {
    filter: brightness(0) invert(1) !important;
    transform: rotate(-180deg);
  }

  .templateTextBold {
    font-weight: 600;
  }
}

.Perfil-URL {
  width: 80vw;
  text-align: $alignCenter;
}

button {
  .btn-Grey {
    border: 1px solid $colorBasicGrey;
    background-color: $colorBasicGrey;
    border-radius: $borderRadius50;
  }
}

button {
  .btn-Red {
    border: 1px solid $colorBasicRed;
    background-color: $colorBasicRed;
    border-radius: $borderRadius50;
  }
}

button {
  .btn-Grey:hover {
    background-color: $colorBasicRed;
    border: 1px solid $colorBasicRed;
  }
}

button {
  .btn-Red:hover {
    background-color: $colorBasicGrey;
    border: 1px solid $colorBasicGrey;
  }
}

.btnCopyUrl {
  display: grid;
}

span.share {
  @include ellipsis;
  padding-right: 20px;
}

.btnCopyGreen {
  background-color: $colorAcentoBase !important;
  border: $colorAcentoBase !important;
  border-radius: 8px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 0.8px !important;
  padding-bottom: 1px !important;
  @include ellipsis;
}

.infoStudioName {
  text-align: $alignCenter;
  width: $width100;
  @include ellipsis;
}

img {
  .card-img-top {
    width: $width100px;
    height: $height100px;
    border-radius: $borderRadius50;
    object-fit: cover;
  }
}

div {
  .imgUser {
    padding: 50px;
  }
}

div {
  .imagesUser {
    border: 1px solid $colorBasicGrey;
  }
}

.textSizeAprox {
  @include ellipsis;
}

/* --------- EMPLEADOS ------- */

.img-Button {
  img {
    width: $width100;
  }
}

button {
  .img-Button {
    width: $width100px;
    height: $height100px;
    border-radius: $borderRadius50;
    border: 1px solid $colorBasicBlack;
  }
}

button {
  .img-ButtonRed {
    background-image: url("https://images.unsplash.com/photo-1475695752828-6d2b0a83cf8a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1137&q=80");
    background-size: cover;
    background-position: center;
    width: $width100px;
    height: $height100px;
    border-radius: $borderRadius50;
    border: 3px solid rgb(190, 55, 55);
  }
}

button {
  .img-Button.btn:hover {
    border: 3px solid rgb(190, 55, 55);
  }
}

/* TEMPLATE */

.information-center {
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  justify-content: center;
  .template-tittle {
    text-align: left;
    padding-left: 15px;
  }
  .button-template {
    width: 100%;
    button {
      img {
        width: $width100;
      }
    }
  }
}

/////////MAQUETACION COMPONENTE JODIT EDITOR///////////
.jodit-toolbar__box {
  display: none !important;
}

.jodit-status-bar {
  display: none !important;
}

.textModalDenied {
  color: $colorSecondaryBase;
}

.textLabelModalDenied {
  margin-bottom: 2rem !important;
}

.textLabelModalAccepted {
  margin-bottom: 2rem !important;
  font-weight: bold;
}
////////////////////////////////////////////////////////

.template-button {
  padding-bottom: 20px;
  text-align: right;
}

.btnSendEnd {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-right: 20px;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:focus-visible {
  border: $globalUnset !important;
  outline: $globalUnset !important;
}

.buttonImageFocus {
  img {
    border: 8px solid $colorAcentoBase !important;
  }
}

.information-corporation {
  padding: 1rem 1rem;
}

.information-corporation-padding {
  padding: 0rem 0rem 0 0rem;
  .edit-button {
    color: $colorPrimaryDarkModeBase-4 !important;
    background-color: $colorPrimaryLightModeBase1 !important;
    border: $globalNone !important;
  }
}

.information-mh {
  min-height: 98% !important;
}

.information-corporation-img {
  border-radius: $borderRadius50;
  width: 75px;
  height: 75px;
  text-align: $alignCenter;
  object-fit: cover;
}

.list-information {
  @include dFlexAlingnCenter;
  margin-bottom: 16px;
  img {
    margin-right: 16px;
  }
}

.paddingFacebook {
  padding-left: 0.58em;
}

.modal-update-info {
  .modal-dialog {
    width: 362px;
  }
  .btn-close {
    width: 10px;
    height: 10px;
    background-color: $colorPrimaryLightModeBase1;
    padding: 15px !important;
  }
  .modal-header {
    border: $globalNone;
  }
}

.logo-update-container {
  label {
    font-size: $bodyBold;
    font-family: $robotoBold;
  }
  .file-upload {
    width: 100px;
    height: 100px;
    @include dFlexCenter;
    border: 4px solid #909090;
  }
}

.putInput {
  position: absolute;
  height: 100% !important;
  width: 100% !important;
  left: 0px !important;
  top: 0px !important;
}

.logo-update-container img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: center;
}

div.d-flex-center.borderIconPlus {
  border: 2px solid $colorNeutroGreyBase6;
  height: 300px;
  width: 100%;
  left: 0px;
  top: 0px;
  border-radius: 6px;
  .imageLogoUpload {
    @include dFlexColumnAlingnCenter;
    .imgPlusUpload {
      width: 50% !important;
    }
    .imgPlusUploadFrontPage {
      width: 24% !important;
    }
    p {
      -webkit-text-fill-color: #7c7c7c;
    }
  }
}

/////nuevo perfil en configura tu asistente
div.divConfigureAssistantLogo {
  // p {
  //   font-size: 16px !important;
  // }
  padding: 24px 12px 24px 12px;
  .initial-name {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Inter";
    width: 100%;
    height: 100%;
    p {
      font-size: 32px;
    }
  }

  .div-logo-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .image-logo-upload {
      border: 1px solid;
      border-radius: 50%;
      width: 72px !important;
      height: 72px !important;
    }
    input {
      position: relative;
      height: 0px;
      width: 0px;
      left: 310px;
      top: -104px;
      background: transparent !important;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
    }
    .logo-image {
      width: 72px !important;
      height: 72px !important;
      object-fit: cover;
      border-radius: 50px !important;
    }
  }

  .div-buttons-logo {
    display: flex;
    flex-direction: column;
  }

  p {
    font-size: 14px;
  }

  .text-textarea {
    padding: 8px;
    height: 87px;
    border: none !important;
  }

  .text-textarea::-webkit-scrollbar {
    display: none !important;
  }

  .div-button-save-phones {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 16px;
  }
}

///////////////////////////////////////////

.saveImgConfiguration {
  width: 145px !important;
  height: 40px;
  border: none !important;
  box-shadow: none !important;
  &:active {
    background-color: #52af9a !important;
    border: none;
  }
}

.no-data-container {
  @include dFlexColumnCenter;
  border: 2px solid #7c7c7c;
  height: 300px;
  width: 100%;
  left: 0px;
  top: 0px;
  border-radius: 6px;
  .noDataImg {
    width: 16% !important;
  }
}

.no-data-container-template {
  @include dFlexColumnCenter;
  height: $height100;
  border: 2px dashed #909090;
  padding: 1rem 0rem 1rem 0rem;
  .image-upload-template {
    width: 25%;
  }
  .imageUploadTemplate {
    width: 25%;
  }
}

.modal-update-info {
  input {
    width: $width100;
    margin-bottom: 8px;
  }
  label {
    padding: 0;
    font-size: 14px;
    font-family: $robotoBold;
  }
}

.file-upload,
.file-upload-coverpage {
  overflow: hidden;
  position: $positionRelative;
  p {
    text-align: center;
  }
  input {
    position: $positionAbsolute;
    height: 400px;
    width: 400px;
    left: -200px;
    top: -200px;
    background: $globalTransparent;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    cursor: pointer;
  }
  .input-plus {
    height: 30%;
    width: 30%;
    margin: auto;
  }
}

.logoUploadUser {
  height: 300px;
  width: $width100;
  .logoImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 6px;
  }
}

.no-data-information {
  @include dFlexCenter;
  .file-upload-coverpage {
    height: $globalUnset;
    width: $width100;
    border-radius: $globalUnset;
    @include dFlexJustiFyCenter;
    input {
      position: $positionAbsolute;
      height: $height100;
      width: $width100;
      left: 0;
      top: 0;
      background: $globalTransparent;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      cursor: pointer;
    }
    .upload__image-wrapper {
      position: $positionAbsolute;
      height: $height100;
      width: $width100;
      left: 0;
      top: 0;
      background: $globalTransparent;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      cursor: pointer;
    }
  }
}

.divButtonPButtons {
  display: flex;
  justify-content: flex-end;
  .upload-container {
    margin-bottom: 16px !important;
    margin-right: 1px !important;
  }
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block !important;
  padding: 6px 12px;
  cursor: pointer;
  text-align: center;
}

@media screen and (min-width: 0px) and (max-width: 575px) {
  .noDataInformationTemplates,
  .no-data-information {
    width: 100% !important;
  }

  .no-data-container-template {
    width: 100% !important;
    height: 200px !important;
    .image-upload-template {
      width: 15% !important;
    }
  }

  .divPButtonImg {
    width: 100%;
    .noDataInformationTemplates,
    .no-data-information {
      width: 100% !important;
      .no-data-container-template {
        width: 100% !important;
        .image-upload-template {
          width: 15% !important;
        }
        .imageUploadTemplate {
          width: 20%;
        }
      }
    }
  }
}

.no-data-container-template {
  @include dFlexColumnCenter;
  height: $height100;
  border: 2px dashed #909090;
  padding: 1rem 0rem 1rem 0rem;
  .image-upload-template {
    width: 25%;
  }

  .imageUploadTemplate {
    width: 25%;
  }
}

//PORTFOLIO
.pcPortofolio {
  .imageLogoUpload {
    border: 1px solid #7c7c7c;
    border-radius: 5px;
    padding: 19px 16px 8px 16px;
    text-align: center;
    width: 114px;
    height: 125px;
    margin-left: 1em;
    cursor: pointer;
    .imgPlusUpload {
      width: 45%;
    }
    p {
      font-size: 15px;
      color: #7c7c7c;
    }
  }

  .upload-container {
    margin-top: 16px;
  }

  .image-portfolio-container {
    width: $width100;
    align-items: flex-start;
    @include dFlexJustiFyEnd;
    .image-portfolio {
      width: $width100;
      height: 213px;
      object-fit: cover;
    }
  }

  .image-portfolio-input-container {
    @include dFlexEndCenter;
    #file-upload {
      display: $globalNone;
    }
  }

  .image-portfolio-container {
    .options {
      position: $positionAbsolute;
      z-index: 999;
      margin-right: 0;
    }
  }
}

//INVITATIONS
.pcInvitations {
  .header-employed {
    p {
      color: #7c7c7c;
    }
  }
}

.btn-delete {
  border-radius: $borderRadius50;
  width: 16px;
  height: 16px;
  border-color: $colorBasicBlack;
  padding: 10px;
  background-color: $colorBasicBlack;
  margin: 5px 5px;
  @include dFlexCenterWebKit;
}

.btn-not-delete {
  border-radius: $borderRadius50;
  border: $globalNone;
  width: 16px;
  height: 16px;
  padding: 10px;
  margin: 5px 5px;
  @include dFlexCenterWebKit;
}

.mapboxgl-ctrl-logo,
.mapboxgl-ctrl-attrib-button {
  display: $globalNone !important;
}

.divPAdre > div:nth-child(2n) {
  height: 1px !important;
  visibility: hidden !important;
}

.divPAdre {
  .mapboxgl-ctrl-geocoder {
    width: $width100;
    box-shadow: $globalUnset;
  }

  .mapboxgl-ctrl-geocoder--input {
    width: $width100 !important;
    border: 1px solid $colorBasicGrey;
    display: block !important;
    width: $width100;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    appearance: $globalNone;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .mapboxgl-ctrl-geocoder--icon-search {
    display: $globalNone;
  }
}

// PCTEMPLATES
.pCtemplates {
  .templateSelect {
    .carousel__inner-slide {
      background-color: $colorSemanticGreenBase-1;
      border: 4px solid $colorSemanticGreenBase-1;
      border-radius: 16px;
    }
    input[type="radio"] {
      appearance: none;
      border-radius: 50%;
      border: 4px solid white;
      width: 30px;
      height: 30px;
      background-color: transparent;
      box-shadow: inset 0 0 0 0.2em #5268af, inset 0 0 0 1em #5268af;
    }
  }

  .templateNotSelect {
    .carousel__inner-slide {
      background-color: $colorSecondaryBase;
      border: 4px solid $colorSecondaryBase;
      border-radius: 16px;
    }
    img {
      width: 10%;
    }
    input[type="radio"] {
      appearance: none;
      border-radius: 50%;
      border: 4px solid white;
      width: 30px;
      height: 30px;
      background-color: transparent;
      box-shadow: inset 0 0 0 0.2em #5268af, inset 0 0 0 1em #5268af;
    }
    input[type="radio"] {
      cursor: pointer;
    }
    input[type="radio"] {
      -webkit-transition: all 0.3s ease;
      transition: all 0.5s ease;
      box-shadow: inset 0 0 0 0.2em #5268af, inset 0 0 0 1em #5268af;
    }
  }

  .carousel__container {
    max-width: 550px;
    margin: auto;
  }

  .carousel__slider {
    padding-right: 15%;
  }

  .carousel__inner-slide {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    left: 10px;
    top: 10px;
  }

  .containerPCImage {
    height: 100%;
    .PCImage {
      height: 92%;
      button {
        height: 100%;
        width: 100%;
        padding: 0 0 6px 0;
        .imagePCTemplate {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 16px 16px 0 0;
        }
      }
    }

    .selectPCImage {
      height: 8%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 8px;
      span {
        color: white;
        font-size: 24px;
      }
    }
  }

  .pCbuttonsTemplates {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    .pCText {
      font-weight: bold;
      margin: 0;
    }

    .carousel__dot-group {
      display: none;
    }

    .pCBack,
    .pCNext {
      border: none;
      background: transparent;
    }
  }

  .pCButton {
    text-align: end;
  }
}

//availability
.border-div-availabilityCheck {
  border-bottom: 1px solid;
  width: 100%;
}
.availabilityCheck {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .text-server-list {
    font-size: 14px !important;
  }
  p {
    font-size: 16px !important;
  }
  .div-options-check {
    display: flex;
    align-items: flex-end;
    margin-bottom: 24px;
  }
  .div-options-form {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .switch-schedule {
      input {
        width: 48px;
        height: 32px;
        box-shadow: none;
      }
    }
  }

  span {
    // padding-top: 0.4em;
    padding-top: 0.2em;
    padding-left: 0.5em;
    font-size: 16px;
    color: $colorPrimaryDarkModeBase1;
  }
  .size-text-label {
    font-size: 14px;
  }
}

.div-text-schedule {
  margin-bottom: 24px;
}

.cityAndStudio {
  width: 236px;
  height: 16px;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #525252;
}

.buttonCityAdd {
  border: none;
  background-color: transparent !important;
  padding: 8px;

  img {
    width: 100%;
    max-width: 25px;
    min-width: 25px;
    height: auto;
  }
  .no-filter-img {
    filter: invert(1);
  }
  .filter-img {
    filter: brightness(0%);
  }
}

.btn-delete-city {
  background: transparent !important;
  border: none;
  border-radius: 50%;
  -moz-border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
  .filter-img {
    filter: invert(1);
  }
}

.todo-list {
  .p-add-city {
    margin-top: 16px;
  }
  .p-add-artist {
    margin-top: 16px;
    margin-bottom: 0;
  }

  .div-input-add-city {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
  .text-city-study {
    margin-top: 16px;
    margin-bottom: 0 !important;
  }

  .inputCity {
    padding: 0px 8px;
    width: 90%;
    height: 40px;
    border: none;
    border-radius: 6px;
  }

  .cityElementList {
    display: flex;
    justify-self: center;
    align-items: center;
    border-bottom: 1px solid;
    p {
      margin: 0 0 0 5px;
    }
    .p-add-image {
      margin-bottom: 4px !important;
      text-decoration: underline !important;
    }
    .div-add-city {
      margin-top: 8px;
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      .size-bold-text {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }

  .div-margin-padding {
    margin-top: 8px;
    padding-bottom: 8px;
  }
}

.p-customPC .pcContainer .todo-list .cityList .cityElementList:last-child {
  border-bottom: none !important;
}

//SHARED
.modalShare {
  z-index: 99999;
  .modal-content {
    background-color: transparent;
    border: none;
  }
}

.sharedLinks {
  border-radius: 16px !important;
  .sharedBody {
    padding-top: 16px;
    .sharedBodyImg {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        margin-bottom: 2em;
      }
    }
  }

  .sharedBodycopy {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #dedede;
    padding: 8px;
    border-radius: 6px;
    .bodyBold {
      width: 75%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0;
      font-style: normal;

      font-size: 14px;
      line-height: 21px;
      color: #0e0f11;
    }

    .ButonRRSS,
    .ButonRRSSGreen {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #5268af;
      background-color: transparent;
    }

    .ButonRRSSGreen {
      background-color: #25d366;
      color: white !important;
      border: none;
      display: flex;
      box-shadow: none;
      .iconCheckButtonRRSS {
        margin-right: 2px;
      }
    }
    p {
      margin: 0;
      margin-top: 2px;
    }
  }

  .buttonsShared {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    .links {
      display: flex;
      justify-content: space-between;
      align-items: center;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid #dedede;
      cursor: pointer;
      button {
        padding: 16px 8px;
        background-color: transparent;
        color: black;
        border: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        img {
          margin-right: 6px;
        }
      }
    }
  }

  .iconBack {
    background-color: transparent !important;
    border: none;
    padding-left: 0;
  }

  .header-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .closedAlert {
      position: unset;
    }
  }

  .links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
    button {
      padding: 16px 8px;
      background-color: transparent;
      color: black;
      border: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      img {
        margin-right: 6px;
      }
    }
  }
}

//////////////////////RESPONSIVE///////////////////
/////nuevo perfil en configura tu asistente
@media screen and (min-width: 0px) and (max-width: 414px) {
  div.divConfigureAssistantLogo {
    .text-textarea {
      height: 160px;
    }
  }

  .logoInitialEmployed {
    flex-direction: column;
  }

  .div-button-save-phones {
    padding-bottom: 24px !important;
  }
}

@media screen and (min-width: 0px) and (max-width: 575px) {
  div.divConfigureAssistantLogo {
    .text-textarea {
      height: 115px;
    }
    .div-logo-buttons {
      input {
        left: 98px;
        top: -104px;
      }
    }
  }
}
/////////////////////////////////////////////////

/* //RESPONSIVE PORTFOLIO EN PAGE CONFIGURATION // */
@media screen and (min-width: 0px) and (max-width: 413px) {
  .divPageConfigurationPortfolio {
    .divPageConfigurationImgCard {
      width: 100% !important;
      .labelPortfolio {
        padding: 0;
        margin: 0;
        margin-bottom: 0.5em;
        .imgPortfolio {
          width: 100%;
          height: 225px;
        }
      }

      .iconClosedImgPortfolio {
        top: 5px !important;
        right: 5px !important;
      }
    }
  }

  .pcPortofolio {
    .imageLogoUpload {
      border: 1px solid #7c7c7c;
      border-radius: 5px;
      padding: 12px 16px 7px 16px;
      width: 98%;
      height: 185px;
      margin-left: 0;
      cursor: pointer;
      @include dFlexColumnCenter;
      .imgPlusUpload {
        width: 25%;
      }
    }
  }

  .sharedLinks {
    .sharedBody {
      .sharedBodyImg {
        img {
          width: 87%;
          margin-bottom: 2em;
        }
      }
    }
  }
}

.rowFixedHeaderPcLink {
  .budgetEmpty {
    h2 {
      display: none;
    }
    p {
      display: none;
    }
  }
}

@media screen and (min-width: 414px) and (max-width: 767px) {
  .divPageConfigurationPortfolio {
    .divPageConfigurationImgCard {
      width: 50% !important;
      .labelPortfolio {
        padding: 0;
        margin: 0;
        margin-bottom: 0.5em;
        .imgPortfolio {
          width: 100%;
          height: 185px;
        }
      }

      .iconClosedImgPortfolio {
        top: 5px !important;
        right: 5px !important;
      }
    }
  }

  .pcPortofolio {
    .imageLogoUpload {
      border: 1px solid #7c7c7c;
      border-radius: 5px;
      padding: 12px 16px 7px 16px;
      width: 48.5%;
      height: 185px;
      margin-left: 0.3em;
      cursor: pointer;
      @include dFlexColumnCenter;
      img {
        width: 25%;
      }
    }
  }
}

/* //RESPONSIVE MODAL CAMBIO DE TEMPLATE EN PERFIL DE NEGOCIO // */
@media screen and (max-width: 380px) {
  .sizeDiv-P-Responsive {
    width: $width50;
  }
}
@media screen and (min-width: 629px) {
  .divButtonsChangeTemplates {
    @include dFlexJustiFyEnd;
  }
}
/* ///////////////////////////////////////////////////////// */

.buttonCPImage {
  background-color: unset;
  border: none;
  p {
    color: #7c7c7c;
  }
}

.template-button {
  padding-bottom: 20px;
  padding-top: 20px;
}

@media screen and (min-width: 0px) and (max-width: 392px) {
  .header-pc {
    justify-content: center !important;
  }

  #buttonPreviewPage {
    z-index: 1000;
    position: $positionFixed !important;
    bottom: 15px;
    border: 0 !important;
  }

  .information-center-Responsive {
    flex-direction: column;
    .button-template-Responsive {
      width: 230px;
    }
  }

  .information-center {
    display: grid;
    grid-template-columns: repeat(1, 2fr);
    justify-items: center;
  }

  .template-button {
    text-align: center;
  }
}

@media screen and (min-width: 393px) and (max-width: 575px) {
  .header-pc {
    justify-content: center !important;
  }

  #buttonPreviewPage {
    z-index: 1000;
    position: $positionFixed !important;
    bottom: 15px;
    border: 0 !important;
  }

  .information-center-Responsive {
    flex-direction: column;
    .button-template-Responsive {
      width: 100%;
    }
  }

  .information-center {
    display: grid;
    grid-template-columns: repeat(1, 2fr);
    justify-items: center;
  }
}

.btn-portfolio {
  position: absolute;
  z-index: 1;
  bottom: auto;
  margin-right: 0;
}

@media screen and (min-width: 0px) and (max-width: 413px) {
  .colPadding {
    margin-right: 16px;
  }

  .p-customPC {
    padding: 0 !important;
    margin: 0 16px;
  }
  .rowAccordionPageConfiguration {
    padding-top: 275px;
  }
}

@media screen and (min-width: 414px) and (max-width: 575px) {
  .p-customPC {
    padding: 8px 8px 60px 8px !important;

    .colPadding {
      margin-right: 16px;
    }

    .pcTittle {
      padding-right: 9px;
    }
  }

  .rowFixedHeaderPcLink {
    top: 114px !important;
  }

  .rowAccordionPageConfiguration {
    padding-top: 275px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .rowAccordionPageConfiguration {
    padding-top: 280px;
    justify-content: center;
    .p-customPC {
      padding: 0 0% 10% 0% !important;
      max-width: 480px !important;
    }
  }

  .rowFixedHeaderPcLink {
    top: 114px !important;
  }

  .header-pc {
    justify-content: center !important;
  }

  #buttonPreviewPage {
    z-index: 1000;
    position: $positionFixed !important;
    bottom: 15px;
    border: 0 !important;
  }

  .information-center {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    justify-items: center;
  }

  .information-center-Responsive .button-template-Responsive {
    margin: 0.5em;
  }

  .template-button {
    text-align: end;
  }
}

.information-center {
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  justify-items: center;
}

.information-center-Responsive .button-template-Responsive {
  margin: 0.5em;
}

.template-button {
  padding-bottom: 20px;
  padding-top: 20px;
  text-align: end;
}

@media screen and (min-width: 768px) {
  .rowAccordionPageConfiguration {
    padding-top: 250px;
    justify-content: center;
    .p-customPC {
      padding: 0 0% 10% 0% !important;
      max-width: 480px !important;
    }
  }

  .header-pc {
    padding: 0px 24px 8px 24px !important;
  }
}

@media screen and (min-width: 992px) {
  .information-center {
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    justify-items: center;
  }

  .information-center-Responsive .button-template-Responsive {
    margin: 0.5em;
  }

  .template-button {
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: end;
  }

  .pCtemplates {
    width: 60%;
    margin: auto;
  }

  .rowFixedHeaderPc .header-pc {
    padding-right: 22% !important;
  }
}

// ACCORDION PAGE CONFIGURATION

.iconChevronDowrReverse:not(.collapsed)::after {
  transform: none;
}

.iconChevronDowrReverse::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

@media screen and (min-width: 1050px) {
  .rowFixedHeaderPc .header-pc {
    padding-right: 6% !important;
  }
  .rowAccordionPageConfiguration {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1600px) {
  .rowFixedHeaderPc .header-pc {
    padding-right: 2% !important;
  }
}

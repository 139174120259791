.pb2 {
  padding-bottom: 2em;
}

.linkColorDifferent {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.textBoldRRSS {
  font-weight: 700;
}

//Filtros Icons-
.whiteFilter {
  filter: brightness(0) saturate(100%) invert(99%) sepia(99%) saturate(0%)
    hue-rotate(312deg) brightness(106%) contrast(98%);
}

.greenFilterSucces {
  filter: brightness(0) saturate(100%) invert(63%) sepia(89%) saturate(451%)
    hue-rotate(59deg) brightness(86%) contrast(84%);
}

.blackFilter {
  filter: brightness(0) saturate(100%) invert(0%) sepia(100%) saturate(27%)
    hue-rotate(264deg) brightness(107%) contrast(102%);
}

.blueFilter {
  filter: brightness(0) saturate(100%) invert(42%) sepia(6%) saturate(4966%)
    hue-rotate(188deg) brightness(92%) contrast(85%);
}
.greyFilter {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7280%)
    hue-rotate(209deg) brightness(111%) contrast(0%);
}


//Botones

//reverse.

.reverseImg {
  display: flex;
  flex-direction: row-reverse;
}

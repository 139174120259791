.registerTattoo {
  background-image: url("../../../assets/images/img17.svg");
  background-size: cover;
  background-position: center;
}

.topContentSignUp {
  @include dFlexBetweenEnd;
  font-family: $robotoRegular;
  margin: 16px 32px;
  .linkBtnStartSesion {
    @include dFlexAlingnCenter;
    text-decoration: none !important;
    color: $colorSecondaryBase !important;
  }
}

.div-form-singup {
  box-shadow: 0px 5.42277px 21.6911px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  width: 100%;
  max-width: 480px;
  padding: 24px 12px;
  margin-left: auto !important;
  margin-right: auto !important;

  .selectionContent {
    @include dFlexBetween;
    width: $width100;
    margin-bottom: 32px;
    .artistButton,
    .studioButton {
      @include dFlexColumnAlingnCenter;
      color: $colorSecondaryBase !important;
      border: 1px solid $colorSecondaryBase !important;
      width: 225px;
      height: 85px;
      font-size: 14px !important;
      img {
        filter: invert(38%) sepia(77%) saturate(356%) hue-rotate(188deg)
          brightness(93%) contrast(97%);
      }
    }
    button.focusColorButton {
      color: white !important;
      background-color: $colorSecondaryBase !important;
      border: 1px solid $colorSecondaryBase !important;
      img {
        filter: brightness(0) saturate(100%) invert(99%) sepia(0%)
          saturate(3151%) hue-rotate(162deg) brightness(119%) contrast(100%) !important;
      }
    }

    .artistButton {
      margin-left: 8px;
    }

    button.artistButton:focus,
    button.studioButton:focus {
      color: white !important;
      border-color: $colorSecondaryBase !important;
      img {
        filter: invert(38%) sepia(77%) saturate(356%) hue-rotate(188deg)
          brightness(93%) contrast(97%);
      }
    }
  }
  .font-size-form {
    font-family: $robotoRegular !important;
    font-size: $bodyRegular;
    font-weight: bold;

    .text-grey-color {
      color: #808080;
    }

    .style-input-inquiries {
      background-color: #e4e4e4 !important;
      color: #808080 !important;
      border: none !important;
      border-radius: 5px;
    }

    .style-input-generic-singup,
    .style-input-singup,
    .style-input-password-singup {
      background-color: #e4e4e4 !important;
      color: #808080 !important;
      border: none !important;
      border-radius: 5px;
      padding-left: 40px !important;
    }

    .singupLabel {
      background-color: #e4e4e4 !important;
      color: #808080 !important;
      border: none !important;
      border-radius: 5px;
      appearance: none;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='gray' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right 11px center;
      background-size: 25px;
    }

    .style-input-singup {
      padding-left: 100px !important;
    }

    .style-input-password-singup {
      padding-left: 8px !important;
    }

    .div-img-text-singup {
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      img {
        width: 25px;
        position: absolute;
        transform: translateY(25%) translateX(25%);
      }
    }

    .div-phone-number {
      .businessPhonePrefix {
        .input-prefix-signup {
          font-size: 16px !important;
          background-color: #e4e4e4 !important;
          color: #808080 !important;
          width: 100% !important;
          height: 36px !important;
          border: none;
        }

        .flag-dropdown {
          background-color: #e4e4e4 !important;
          border: none;
        }

        .country-list {
          width: 230px !important;
        }
      }

      .text-error-phone {
        margin: 0%;
        margin-top: 4px;
        color: #dc3545;
        font-size: 14px;
      }
    }

    .register-input {
      display: flex;
      width: 103%;
    }

    .password-input-container {
      position: relative;
    }

    .icon-container {
      position: absolute;
      display: flex;
      align-items: center;
      top: 50%;
      transform: translateY(-50%);
      right: 10px; /* Ajusta este valor según tu diseño */
    }

    .error-icon,
    .eye-register {
      cursor: pointer;
      margin-left: 5px; /* Ajusta este valor según tu diseño */
    }

    .eye-register {
      margin-left: 10px; /* Ajusta este valor según tu diseño */
    }
  }

  .div-checkbox-terms-singup {
    .form-check-input {
      margin-top: 0% !important;
      width: 20px;
      height: 20px;
    }
    label {
      padding-left: 8px;
      padding-top: 3px;
      font-family: $robotoLight !important;
      font-size: 14px !important;
    }
  }

  .characterPassword {
    font-size: 14px;
    margin-top: 4px;
    color: #808080;
  }
  .characterPassword2 {
    font-size: 14px;
    margin-top: 4px;
    color: #dc3545;
  }

  .termsLink {
    color: #5268af;
    font-size: 14px;
    padding-top: 3px;
    font-family: $robotoLight !important;
  }
}

@media screen and (max-width: 413px) {
  .div-phone-number .unsetPrefix {
    overflow: unset !important;
  }
  .topContentSignUp {
    margin-left: 16px;
    margin-right: 16px;
  }

  .selectionContent {
    margin-bottom: 16px !important;
  }

  .logo-icon-loginRegister img {
    width: 85% !important;
  }

  .div-form-singup {
    .businessPhonePrefix {
      white-space: nowrap !important;
      text-overflow: ellipsis !important;
      overflow: unset !important;
    }

    a.termsLink,
    .aceptTerms,
    .professionalInstagram,
    .businessDomain,
    .businessArtistName,
    .businessLastName,
    .businessEmail,
    .businessPassword {
      @include ellipsis;
    }

    .inputEye {
      width: $width100 !important;
    }
  }
}

@media screen and (min-width: 414px) and (max-width: 575px) {
  .selectionContent {
    margin-bottom: 28px !important;
  }
  
}

.buttons-actions-invitations {
  padding-top: 2em;
  .blueSmallButton {
    margin-right: 15px;
  }
}

@media screen and (max-width: 414px) {
  .buttons-actions-invitations {
    display: flex;
  }
}

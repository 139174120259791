// MAQUETACION EN PROFILE
div {
  .titleStripe {
    @include dFlexJustiFyCenter;
    font-size: 16px;
    margin-bottom: 24px;
  }
  .divButtonStripe {
    @include dFlexJustiFyCenter;
    .linkStripe {
      text-decoration: none;
      .divStripeLink {
        width: 244px;
        height: 40px;
        @include dflexAroundAlingCenter;
        .filter-img {
          filter: invert(1) !important;
        }
      }
    }
  }
}

// MAQUETACION STRIPELINKS
.containerStripe {
  .title-stripe-plans {
    @include dFlexEndCenter;
    padding: 0 8px;
    margin-bottom: 0;
    border-radius: 4px;
    min-height: 36px;
 font-size: 14px;
  }
  .promotionalPrice {
   @include dFlexCenter;
    width: 45px;
    height: 25px;
    background-color: #FF385C;
    border-radius: 50px;
    font-size: 14px;
  }
 
  .border-recommended {
    border: 1px solid #FF385C ;
    .recommendedPrice {
      @include dFlexCenter;
      width: 105px;
      height: 26px;
      background-color: #787878;
      border-radius: 50px;
      p {
        font-size: 14px;
      }
    }
  }
 
  .card-stripe {
    display: flex;
    h5 {
      font-weight: bold;
      margin-bottom: 16px;
    }
    h6 {
      font-size: 14px;
    }
  }
  .price-stripe {
    font-size: 24px;
    font-weight: bold;
  }
  .price-plan {
   position: relative;
  }
  .price-plan::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 3px;
    background-color:#808080;
  }
  .color-price-plan {
    padding-left: 4px;
    color: #FF385C;
  }
  .span-text {
    font-size: 14px;
    font-weight: bold;
  }
  .icon-check-text {
    display: flex;
    p {
      margin: 0;
      font-size: 14px;
      padding-left: 12px;
    }
  }
  .div-button-stripe-plan {
    @include dFlexJustiFyCenter;
    margin-top: 36px;
    .bottonStripePlan {
      width: 100%;
      max-width: 244px;
      height: 38px;
      border-radius: 50px;
      border: none;
     a {
      text-decoration: none;
     }
    }
  }
}

.employed-direction {
  @include dFlexColumnBetween;
  .hrStylePCEmployeds {
    border: 1.2px solid hsla(200, 10%, 50%, 100);
  }

  .employed-column {
    padding-bottom: 1em;
    .options {
      margin-right: 5px;
    }
    .buttons-delete-employed {
      @include dFlexEndCenter;
    }
    .buttons-delete-employed button {
      margin-left: 15px;
    }
    .divIconEmployeds {
      display: flex;
      padding-top: 1em;
      align-items: center;
    }
    .image-employed {
      .options {
        position: $positionAbsolute;
      }
      .button-employed-link {
        background-color: $globalUnset !important;
        border-color: $globalUnset !important;
        padding: 0 !important;
        border-radius: $borderRadius50 !important;
        img {
          height: $height60px !important;
          object-fit: cover !important;
          width: $width60px;
          border-radius: $borderRadius50 !important;
          margin-right: 16px;
        }
      }
      .btn-primary:active:focus {
        box-shadow: $globalUnset;
      }
      .button-employed-link:focus {
        box-shadow: none !important;
      }
    }

    .text-employed {
      @include ellipsis;
      width: $width50;
      text-overflow: " [..]";
      p {
        margin-bottom: 0;
      }
    }
  }

  .employed {
    @include dFlexAlingnCenter;
    padding-top: 1em;
    padding-bottom: 1em;
    .options {
      margin-right: 5px;
    }
    .buttons-delete-employed {
      @include dFlexEndCenter;
    }
    .buttons-delete-employed button {
      margin-left: 15px;
    }
    .divIconEmployeds {
      display: flex;
      align-items: center;
    }

    .image-employed {
      .options {
        position: $positionAbsolute;
      }
      .button-employed-link {
        background-color: $globalUnset !important;
        border-color: $globalUnset !important;
        padding: 0 !important;
        border-radius: $borderRadius50 !important;
        img {
          height: $height60px !important;
          object-fit: cover !important;
          width: $width60px;
          border-radius: $borderRadius50 !important;
          margin-right: 16px;
        }
      }
      .btn-primary:active:focus {
        box-shadow: $globalUnset;
      }
      .button-employed-link:focus {
        box-shadow: none !important;
      }
    }

    .text-employed {
      @include ellipsis;
      text-overflow: " [..]";
      p {
        margin-bottom: 0;
      }
    }
  }

  .employedPC {
    @include dFlexAlingnCenter;
    padding-bottom: 1em;
    .options {
      margin-right: 5px;
    }
    .buttons-delete-employed {
      @include dFlexEndCenter;
    }
    .buttons-delete-employed button {
      margin-left: 15px;
    }
    .divIconEmployeds {
      display: flex;
      align-items: center;
    }

    .image-employed {
      .options {
        position: $positionAbsolute;
      }
      .button-employed-link {
        background-color: $globalUnset !important;
        border-color: $globalUnset !important;
        padding: 0 !important;
        border-radius: $borderRadius50 !important;
        img {
          height: $height60px !important;
          object-fit: cover !important;
          width: $width60px;
          border-radius: $borderRadius50 !important;
          margin-right: 16px;
        }
      }
      .btn-primary:active:focus {
        box-shadow: $globalUnset;
      }
      .button-employed-link:focus {
        box-shadow: none !important;
      }
    }
    .text-employed {
      @include ellipsis;
      // width: $width50;
      text-overflow: " [..]";
      p {
        margin-bottom: 0;
      }
    }
  }
}

.header-employed {
  @include dFlexColumn;
}

.no-artist {
  display: flex;
  justify-content: flex-start;
  padding-top: 1em;
  cursor: pointer;
  p {
    margin-top: 0;
    margin-bottom: 1rem;
    padding-top: 0.3em;
  }
  .textAddEmployed {
    text-decoration: underline;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0%;
  }
  img {
    width: 30px;
    height: 30px;
    margin: 0%;
    margin-right: 1em;
  }
}

.no-artist-button {
  text-align: $alignEnd;
  width: $width100;
  padding: 75px 15px 20px 0;
}

.btn-delete-employed {
  background-color: $globalTransparent !important;
  color: $colorSemanticRedBase !important;
  border-color: $colorSemanticRedBase !important;
  width: 85px;
  height: 35px;
  @include dFlexCenter;
  margin-left: 5px;
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: $globalUnset !important;
}

.btnSendInvited {
  @include dFlexJustiFyEnd;
  .btnInvited {
    width: $width60 !important;
  }
}

@media screen and (min-width: 0px) and (max-width: 342px) {
  .no-artist {
    width: 250px !important;
    img {
      width: 30px;
      height: 30px;
      margin: 0%;
      margin-right: 0.5em;
      cursor: pointer;
    }
  }
}

@media screen and (min-width: 0px) and (max-width: 574px) {
  .employed-direction {
    .employed {
      .divIconEmployeds {
        width: 80%;
      }
    }
  }
}

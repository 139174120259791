.accordionDataArtist {
  width: $width100;
  @include dFlexBetweenCenter;
  overflow-wrap: break-word;
}

.widthButtonBudgetClient {
  width: auto !important;
}

.accordionDataDivClient {
  border: 2px solid $colorNeutroGreyBase2;
  border-radius: 10px;
  padding: 16px;
  height: auto;
  @include dFlexColumnBetween;
}

.button-budget-client {
  @include dFlexJustiFyEnd;
}

@media screen and (min-width: 0px) and (max-width: 413px) {
  // .d-flex {
  //   flex-direction: column;
  //   align-items: center;
  // }

  .textClientCenter {
    text-align: $alignCenter !important;
  }

  .priceBudgetAcceptedClient {
    display: flex;
    flex-direction: column;
  }

  .priceBudgetAcceptedClient {
    margin: 0% !important;
    margin-bottom: 0.5rem !important;
  }

  .button-budget-client {
    @include dFlexJustiFyCenter;
    margin-top: 20px;
  }
}

@media screen and (max-width: 575px) {
  .requestClientDataClient {
    width: $width100;
    overflow-wrap: break-word;
  }

  .requestStatusClient {
    width: $width100;
  }

  .requestDateClient {
    width: $width100;
    text-align: $alignStart;
  }
}

@media screen and (min-width: 576px) {
  .requestClientDataClient {
    width: 38%;
    overflow-wrap: break-word;
  }

  .requestStatusClient {
    width: 23%;
  }

  .requestDateClient {
    width: 23%;
    text-align: $alignEnd;
    p {
      margin: 0%;
    }
  }

  @media screen and (min-width: 768px) {
    .requestClientDataClient {
      width: 30%;
      overflow-wrap: break-word;
    }

    .requestStatusClient,
    .requestDateClient {
      width: 25%;
    }
  }

  @media screen and (min-width: 992px) {
    .requestDateClient {
      width: 20%;
    }
  }

  @media screen and (min-width: 1200px) {
    .requestClientDataClient {
      width: 25%;
    }
  }

  @media screen and (min-width: 1400px) {
    .requestClientDataClient {
      width: 30%;
    }
    .requestDateClient {
      width: 13%;
    }
  }

  @media screen and (min-width: 1600px) {
    .requestDateClient {
      width: 10%;
    }
  }
}

@import "./variables/_variables";
@import "./mixin/_mixin";
@import "./_app";

//components
@import "./scss_files/alerts/_alerts";
@import "./scss_files/budget/_budget";
@import "./scss_files/budgetClient/_budgetClient";
@import "./scss_files/buttons/_buttons";
@import "./scss_files/centralBox/_centralBox";
@import "./scss_files/cookies/_cookies";
@import "./scss_files/cookies/_modalCookies";
@import "./scss_files/dashboard/_dashboard";
@import "./scss_files/dashboard/minicalendar/_minicalendar";
@import "./scss_files/forgotPassword/_forgotPassword";
// @import "./scss_files/information/_information";
@import "./scss_files/information/profileEmployed/_employed";
@import "./scss_files/information/profileImage/profolioInput/card/_index";
@import "./scss_files/information/profileImage/profolioInput/image/_index";
@import "./scss_files/information/profileImage/profolioInput/upload/_index";
@import "./scss_files/information/profileImage/profolioInput/card/_index";
@import "./scss_files/information/profileImage/profolioInput/_index";
@import "./scss_files/information/profileInvitation/_profileInvitation";
@import "./scss_files/information/profileSchedul/_workSchedul";
@import "./scss_files/login/_login";
@import "./scss_files/navbar/_navbar";
@import "./scss_files/pagination/_pagination";
@import "./scss_files/profile/_profile";
@import "./scss_files/sidebar/item/_itemStyle";
@import "./scss_files/sidebar/_sidebarStyle";
@import "./scss_files/signUp/_signUp";
@import "./scss_files/suggestion/_suggestion";
@import "./scss_files/modalBudgetPending/_modalBudgetPending";
@import "./scss_files/routesPublic/routesPublic";
@import "./scss_files/pageConfiguration/information";
@import "./scss_files/darkMode/darkMode";
@import "./scss_files/stripe/stripe";
@import "./scss_files/budget/modalDownloadExcel";

//index
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  display: flex;
}
.image-card {
  display: $inlineBlock;
  position: $positionRelative;
  input[type="checkbox"][id^="cb"] {
    display: $globalNone;
  }
  label {
    border: 4px solid $colorBasicWhite;
    padding: 3px;
    display: block !important;
    position: $positionRelative;
    margin: 10px;
    cursor: pointer;
    -webkit-touch-callout: $globalNone;
    -webkit-user-select: $globalNone;
    -khtml-user-select: $globalNone;
    -moz-user-select: $globalNone;
    -ms-user-select: $globalNone;
    user-select: $globalNone;
    img {
      width: 114px;
      height: 125px;
      transition-duration: 0.2s;
      object-fit: cover;
      transform-origin: 50% 50%;
    }
  }
  .options {
    position: $positionAbsolute;
    top: 8px;
    right: 12px;
    z-index: 1;
    bottom: auto;
    margin-right: 0;
  }
  .btn-portfolio {
    top: 15px !important;
    right: 15px !important;
  }
}

// El siguiente código quedará comentado por el momento.

// .image-card label::before {
//   background-color: $colorBasicWhite;
//   color: $colorBasicWhite;
//   content: " ";
//   display: block;
//   border: 1px solid #0073aa;
//   position: $positionAbsolute;
//   top: -5px;
//   left: -5px;
//   width: 25px;
//   height: 25px;
//   text-align: $alignCenter;
//   line-height: 28px;
//   transition-duration: 0.4s;
//   transform: scale(0);
// }

// .image-card :checked + label {
//   border-color: #0073aa;
// }
// .image-card :checked + label::before {
//   content: "✓";
//   background-color: #0073aa;
//   transform: scale(1);
//   z-index: 9;
// }

// .image-card :checked + label img {
//   transform: scale(0.9);
//   box-shadow: 0 0 5px rgb(51, 51, 51);
//   z-index: -1;
// }

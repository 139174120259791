// Display Flex

@mixin dFlexAlingnCenter {
  display: flex;
  align-items: center;
}

@mixin dFlexAlingnStart {
  display: flex;
  align-items: flex-start;
}

@mixin dFlexJustiFyCenter {
  display: flex;
  justify-content: center;
}

@mixin dFlexJustiFyStart {
  display: flex;
  justify-content: flex-start;
}

@mixin dFlexJustiFyEnd {
  display: flex;
  justify-content: flex-end !important;
}

@mixin dFlexBetween {
  display: flex;
  justify-content: space-between;
}

@mixin dFlexColumn {
  display: flex;
  flex-direction: column;
}

@mixin dFlexCenter {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

@mixin dFlexCenterWebKit {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
}

@mixin dFlexEvenlyCenter {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

@mixin dFlexRowEvenly {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

@mixin dFlexColumnEvenlyCenter {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

@mixin dFlexBetweenCenter {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
}

@mixin dFlexBetweenEnd {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

@mixin dFlexColumnBetweenCenter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

@mixin dFlexEndCenter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin dFlexEndCenter {
  display: flex;
  justify-content:flex-start;
  align-items: center;
}

@mixin dFlexColumn {
  display: flex;
  flex-direction: column;
}

@mixin dFlexColumnCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin dFlexColumnBetween {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@mixin dFlexColumnJustifyCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin dFlexColumnAlingnCenter {
  display: flex !important;
  flex-direction: column;
  align-items: center;
}

@mixin dFlexRowCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
@mixin dFlexRowBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@mixin dFlexBaselineCenter {
  display: flex;
  justify-content: center;
  align-items: baseline;
}
@mixin dFlexBaselineEvenly {
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
}
@mixin dFlexRowEvenlyBaseline {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: baseline;
}

@mixin dFlexColumnCenterStart {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

@mixin dFlexColumnAlingStart {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@mixin dflexAroundAlingCenter {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

//Buttons Budget
@mixin buttonsBudget(
  $padding: 8px,
  $backgroundColor: $colorSemanticGreenBase1,
  $color: $colorBasicWhite
) {
  padding: $padding;
  background-color: $backgroundColor;
  color: $color;
}

//Ellipsis
@mixin ellipsis {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

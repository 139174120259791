

@media screen and (max-width: 576px) {
  .imgTattooMachine {
    display: $globalNone;
  }
}

@media screen and (min-width: 576px) {
  .imgTattooMachine {
    display: $globalNone;
  }
}

@media screen and (min-width: 768px) {
  .imgTattooMachine {
    display: $globalNone;
  }
}

@media screen and (max-width: 768px) {
  .titleSuggestion {
   margin-top: 2.5em;
  }
}

@media screen and (min-width: 992px) {
  .imgTattooMachine {
    display: $globalUnset;
  }
}

.header-workschedule {
  margin-bottom: 15px;
}

.workschedule-check {
  padding: 0px 0px 16px 0px;
  .divWorkSchedule {
    display: flex !important;
  }
}

.workschedule-check .form-check {
  @include dFlexBetweenCenter;
  padding: 0;
  flex-direction: row-reverse;
  font-size: 16px;
  font-family: $robotoBold;
}

.workschedule-check input {
  height: 1.5em;
  width: 4em !important;
}

.workschedule-check .form-check-input:checked {
  background-color: $colorAcentoBase-3 !important;
  border-color: $colorAcentoBase-3 !important;
}

.workschedule-check .form-check-input:focus {
  border-color: $colorAcentoBase-3;
  outline: 0;
  box-shadow: 0 0 0 0.25rem $colorAcentoBase-3;
  background-color: $colorAcentoBase-3;
}

.workschedule-check .form-switch .form-check-input:focus {
  background-image: url("../../../assets/images/Frame97.png") !important;
  background-repeat: no-repeat;
  background-size: contain;
}

.workSchedule-checks2 {
  display: flex;
  margin-bottom: 16px;
}

div.workSchedule-time {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  justify-content: center;
  flex-direction: column;
}

.workSchedule-time button {
  box-shadow: $globalNone;
}

.workSchedule-time .workSchedule-btn {
  color: $colorBasicWhite;
  background-color: $colorSecondaryBase;
  border-radius: $borderRadius50;
  border-color: $globalUnset;
  width: 30px;
  height: 30px;
  @include dFlexCenterWebKit;
  margin-right: 16px;
}

.workSchedule-time .workSchedule-btn-disabled {
  color: $colorBasicWhite;
  background-color: $colorSecondaryBase-3;
  border-radius: $borderRadius50;
  border-color: $globalUnset;
  width: 30px;
  height: 30px;
  @include dFlexCenterWebKit;
  margin-right: 16px;
}

.workSchedule-time .workSchedule-btn-disabled:focus,
.workSchedule-time .workSchedule-btn:focus {
  color: $colorBasicWhite;
  background-color: $colorSecondaryBase !important;
  border-color: $globalUnset !important;
  box-shadow: $globalNone !important;
}

.workSchedule-inputs {
  @include dFlexColumnCenter;
  border-radius: 6px;
}

.workSchedule-input {
  @include dFlexCenter;
  margin-bottom: 4px;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: $globalNone;
  display: $globalNone;
}

.form-time-day {
  @include dFlexCenter;
  flex-wrap: wrap;
  .workSchedule-time {
    margin-right: 16px;
  }
}

@media screen and (min-width: 0px) and (max-width: 575px) {
  .workSchedule-time {
    align-items: center !important;
    margin-right: 7%;
    .workSchedule-btn {
      margin-right: 5%;
    }
    .workSchedule-btn-disabled {
      margin-right: 5%;
    }
    input {
      padding-left: 4px !important;
      padding-right: 4px !important;
      text-align: -webkit-center;
    }
  }
}

@media screen and (min-width: 0px) and (max-width: 575px) {
  .workschedule-check {
    .divWorkSchedule {
      justify-content: center;
      .buttonMargin {
        margin-left: 0em !important;
        margin-right: 0.4em !important;
      }
    }
  }
}

.loginTattoo {
  background-image: url("../../../assets/images/img16.svg");
  background-size: cover;
  background-position: center;
}

.blockLogin {
  width: 50%;
  margin-bottom: 2%;
  @include dFlexColumnCenterStart;
  input {
    width: 99%;
  }
}

.forgotPassword {
  text-decoration: $globalNone;
  align-items: $alignItemEnd;
}

.logo-icon-login {
  width: 140px;
  display: flex;
  .logo-text {
    width: $width100;
    height: auto;
  }
}

.divButtonsLanguage {
  display: flex;
  justify-content: center;
  .changeLanguageSession {
    width: 130px;
    height: 42px;
    border-radius: 50px;
    margin: 24px;
    margin-top: 0px;
    margin-bottom: 16px;
    border: 1px solid $colorPrimaryDarkModeBase-2;
    background-color: transparent !important;
    .divButton {
      display: flex;
      justify-content: space-evenly;
      img {
        width: 21.5px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }
      p {
        margin-bottom: unset;
        color: black;
        font-size: 14px;
      }
    }
  }
}

.changeLanguageSession:focus {
  display: none;
}

.login-input {
  display: flex;
  width: $width100 !important;
  .eye-login {
    position: $positionRelative;
    top: 1px;
    right: 30px;
  }
  .eye-login:hover {
    cursor: pointer;
  }
}

.pass-login,
.input-lg {
  @include ellipsis;
}

.pass-login::placeholder {
  width: 80% !important;
}

.btnStartSesion {
  margin-top: 1em;
}

@media screen and (max-width: 413px) {
  .topContentLogin {
    flex-direction: row !important;
    padding-left: 0.5em;
    padding-right: 0.5em;
    margin-bottom: 6em !important;

    .logo-icon-login img {
      width: 80%;
      padding-left: 0.4em;
    }
    a.btnSingup {
      padding-right: 0.35em;
      .greenSmallButton {
        font-size: 14px !important;
        width: 100px !important;
        margin-top: 0em !important;
      }
    }
  }

  .pass-login {
    width: 99% !important;
    padding-left: 7% !important;
  }

  .blockLogin {
    width: 85%;
    padding-top: 2%;
    margin-bottom: 4%;
    input {
      width: 100%;
    }
    .bodySubcontentBold {
      padding-right: 1%;
    }
  }

  .headingH2 {
    font-size: 20px !important;
  }
}

@media screen and (min-width: 414px) and (max-width: 575px) {
  .topContentLogin {
    margin-bottom: 7em !important;
    .logo-icon-login img {
      padding-left: 0.15em;
    }
    a.btnSingup {
      padding-right: 0em;
      .greenSmallButton {
        width: 110px !important;
      }
    }
  }

  .pass-login {
    width: 92% !important;
    padding-left: 6.2% !important;
  }

  .blockLogin {
    width: 80%;
    padding-top: 2%;
    .bodySubcontentBold {
      padding-right: 2% !important;
    }
  }

  .headingH2 {
    font-size: 24px;
  }
}

@media screen and (min-width: 576px) {
  .topContentLogin {
    margin-bottom: 7em !important;
    padding-top: 4em;
    a.btnSingup {
      padding-left: 4.5em;
    }
  }

  .blockLogin {
    width: 80%;
    padding-top: 2%;
    .bodySubcontentBold {
      padding-right: 1.5%;
    }
  }

  .pass-login {
    width: 92% !important;
    padding-left: 6% !important;
  }
}

@media screen and (min-width: 768px) {
  .logo-icon-login img {
    padding-left: 1.1em !important;
  }

  .blockLogin {
    width: 85% !important;
    .bodySubcontentBold {
      padding-right: 1%;
      font-size: 16px !important;
    }
  }

  a.btnSingup {
    padding-right: 1em !important;
    padding-left: 3em !important;
    .greenSmallButton {
      width: 125px !important;
    }
  }

  .pass-login {
    width: 94.5% !important;
    padding-left: 4.8% !important;
  }
}

@media screen and (min-width: 992px) {
  .logo-icon-login img {
    padding-left: 0.9em !important;
  }

  .blockLogin {
    width: 86.5% !important;
    .bodySubcontentBold {
      padding-right: 1%;
    }
  }

  .pass-login {
    width: 94.6% !important;
    padding-left: 4.1% !important;
  }

  a.btnSingup {
    padding-right: 1em !important;
    padding-left: 8em !important;
  }
}

@media screen and (min-width: 1200px) {
  .bodySubcontentBold {
    padding-right: 0.5%;
  }
  .pass-login {
    width: 94.5% !important;
    padding-left: 4% !important;
  }
  a.btnSingup {
    padding-left: 12em !important;
  }
}

@media screen and (min-width: 1400px) {
  .bodySubcontentBold {
    padding-right: 1.2% !important;
  }

  .pass-login {
    width: 94.6% !important;
    padding-left: 4.1% !important;
  }

  a.btnSingup {
    padding-left: 16.7em !important;
  }
}

@media screen and (min-width: 1600px) {
  .pass-login {
    width: 94.7% !important;
    padding-left: 4.2% !important;
  }

  a.btnSingup {
    padding-left: 20.7em !important;
  }
}
